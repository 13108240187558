import { REPORT_SCHOOL_RESPONSE, REPORT_CASELOAD_RESPONSE, GET_REPORT_SCHOOL_ALL_RESPONSE, GET_PATIENT_NAME, GET_REPORT_DETAIL } from './actionType'


let initialValues = {
    reportList: [],
    reportCaseload: {},
    reportAllDetail: {},
    currentPatient: '',
    reportDetailById: {},
}
export const schoolReportReducer = (state = initialValues, action: any) => {

    switch (action.type) {
        case REPORT_SCHOOL_RESPONSE:
            state = { ...state, reportList: action?.payload.data };
            break
        case REPORT_CASELOAD_RESPONSE:
            state = { ...state, reportCaseload: action?.payload.data };
            break
        case GET_REPORT_SCHOOL_ALL_RESPONSE:
            state = { ...state, reportAllDetail: action?.payload.data };
            break
        case GET_PATIENT_NAME:
            state = { ...state, currentPatient: action?.payload };
            break
        case GET_REPORT_DETAIL:
            state = { ...state, reportDetailById: action?.payload };
            break

        default:
            state = { ...state };
            break;
    }
    return state;
}
