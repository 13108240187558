// export const BASE_URL = 'http://192.168.1.42:5001/' //kushagra
//  export const BASE_URL = 'http://3.8.97.180:8001/' //staging
  // export const BASE_URL = 'http://192.168.1.72:5001/' //pankaj sir
 export const BASE_URL = process.env.REACT_APP_API_URL //stg

export const HEADER_TOKEN = {
    'Authorization': 'Basic YXNkY2xpZW50OmFzZHNlY3JldA==',
    "Content-Type": "application/x-www-form-urlencoded"
}
export  const allScope = true
export const postcodeRegex = /[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}/i;
export const nhsRegex = new RegExp('^(?:\\d{3}\\s\\d{3}\\s\\d{4}|\\d{10}|\\d{3}-\\d{3}-\\d{4})$');
export const mobileRegex = /^\s*(?:\+44\s?7\d{3}|\(?07\d{3}\)?|\+44\s?\d{4}|\(?0\d{4}\)?|\+44\s?\d{3}|\(?0\d{3}\)?|\d{5,6})\s?\d{3}\s?\d{3,4}\s*\d{3,4}\s*$/;

export const limitOptions = [10, 20, 50,100,500];
export const VERSION = '1.0.0'