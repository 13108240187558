import { useEffect, useState } from "react";
import { Typography, Grid, Card } from "@mui/material";
import OTPInput from "react-otp-input";
import "./style.scss";
import Logo from "../../../assets/Images/logo.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendOtp } from "../../../redux/action";
import optImg from "../../../assets/Images/otp.webp";

const Index = () => {
  const [otp, setOTP] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userData: any = localStorage.getItem("userData");
  let user = JSON.parse(userData);
  const handleSubmit = () => {
    let payload = {
      email: user?.email,
      otp: otp,
    };
    dispatch(sendOtp(payload, navigate));
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp]);

  return (
    <Grid container spacing={3} className="forBgOtp">
      <Grid item lg={7}>
        <img src={optImg} alt="" className="img-fluid" />
      </Grid>
      <Grid item lg={5}>
        <Card className="formCard">
          <Grid
            textAlign={"center"}
            //  padding={'1rem 0rem'}
          >
            <img src={Logo} width="200px" height={"100px"} alt="logo" />
          </Grid>
          <Grid className="Otpwrapper">
            <Typography variant="h4" gutterBottom textAlign={"center"}>
              OTP Verification
            </Typography>
            <Typography variant="body1" gutterBottom textAlign={"center"}>
              Enter the otp you recieve in your email
            </Typography>
            <Grid marginTop={"25px"} container justifyContent={"center"}>
              <OTPInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  fontSize: "2rem",
                  margin: "0 0.5rem",
                  borderRadius: "4px",
                  background: "rgb(106 35 130 / 24%)",
                  border: "transparent",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </Grid>
            <Grid textAlign={"center"} marginTop={"20px"} marginBottom={"40px"}>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Index;
