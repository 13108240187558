import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Report } = Dictionary

const report = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + Report.add(), data)
  },
  view: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Report.view(), { params: paramsObj })
  },
  viewSchoolReport: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Report.add(), { params: paramsObj })
  },
  updateSchoolReport: async (id: string, data: {}) => {
    return axios.put(BASE_URL + Report.reportById(id), data)
  },
  viewAllDetail: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Report.viewByID(), { params: paramsObj })
  },
  getReportById: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Report.userById(), { params: paramsObj })
  },
}
export default report
