import { VIEW_AUDIT_TRAIL } from './actionType'

export const viewAuditTrail = (id: string, params: any) => {
    return {
        type: VIEW_AUDIT_TRAIL,
        params,
        id

    }
}

