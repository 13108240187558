export const styles = {
accordianHeader : {
    cursor: 'pointer',
     background: 'rgba(106, 35, 130, 0.1019607843)',
      padding: '0',
      marginBottom:'0.5rem',
      display:'flex',
      alignItems:'center',
    justifyContent:'space-between'
},
title : {
    margin:'0',
    padding:'0.7rem',
    fontSize:'0.9rem',
    color:'#6A2382',
    pl:4
},
contentBody : {
    padding: '10px',
    border: '1px solid rgba(106, 35, 130, 0.1)',
    marginBottom:'1rem'
},
iconStyle : {
    color:'#6A2382',
    marginRight:'1rem'
}

}