import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Caseload } = Dictionary

const Caseload_Management = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + Caseload.view(), data)
  },
  view: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Caseload.view(), { params: paramsObj })
  },
  getParentReport: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Caseload.getParentReport(), {
      params: paramsObj,
    })
  },
  getSchoolReport: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Caseload.getSchoolReport(), {
      params: paramsObj,
    })
  },
  overveiw: async (id: string) => {
    return axios.get(BASE_URL + Caseload.overview(id))
  },
  update: async (id: string, data: {}) => {
    return axios.put(BASE_URL + Caseload.getById(id), data)
  },
  addSystemDate: async (id: string, data: {}) => {
    return axios.put(BASE_URL + Caseload.addSystemDate(id), data)
  },
  getDetailByID: async (id: string) => {
    return axios.get(BASE_URL + Caseload.getById(id))
  },
  getRedFlag: async (paramsObj: any) => {
    return axios.get(BASE_URL + Caseload.getRedFlag(), { params: paramsObj })
  },
  postClinicalReview: async (id: string, data: {}) => {
    return axios.post(BASE_URL + Caseload.postClinicalReview(id), data)
  },
  deleteAdditionalParent: async (id: string,userId:string) => {
    return axios.get(BASE_URL + Caseload.deleteAdditionalParent(id,userId))
  },
  redReferralsystem: async (id:string, data: {}) => {
    return axios.put(BASE_URL + Caseload.redReferralsystem(id), data)
  },
}
export default Caseload_Management
