
import { memo, useEffect, useState } from 'react'
import { Box, Grid, IconButton, MenuItem, Menu, Typography, } from '@mui/material';
import {
    MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Table from '../../../components/atoms/Table'
import { changeTab, viewReferral } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import CONSTANT from '../../../constants/constant';
import Search from "../../../components/atoms/Search"
import moment from 'moment';
import { calculateAge, formatNHSNumber } from "../../../constants/utils"
import constant from '../../../constants/constant';
import { useClearSearch } from '../../../components/atoms/Hooks/useClearSearch';

const Index = () => {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1)
    const { setSearch, search, clearSearch } = useClearSearch();
    const [sortType, setSortType] = useState('')
    const [limit, setLimit] = useState(10)
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [columns, setColumns] = useState([
        {
            name: '#',
            cell: (row: any, index: number) => (((page - 1) * limit) + index + 1).toString().padStart(2, '0'),
            width: '50px',
            tblName: ''

        },
        {
            name: 'Referral ID',
            cell: (item: any, ind: number) => <span>{item.referral_id}</span>,
            sortable: false,
            width: "15%",
            tblName: 'referral_id'
        },
        {
            name: 'Referral Date/Time',
            cell: (item: any, ind: number) => <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>,
            sortable: true,
            width: "15%",
            tblName: 'createdAt'
        },
        {
            name: 'Patient Name',
            cell: (item: any, ind: number) => <span>{item.patient_name}</span>,
            selector: (item: any, ind: number) => item.patient_name,
            sortable: true,
            width: "20%",
            tblName: 'patient_name'
        },
        {
            name: 'Patient Age',
            cell: (item: any, ind: number) => <span>{item.age}</span>,
            sortable: false,
            width: "10%",
            tblName: 'age'
        },
        {
            name: 'NHS Number',
            width: "15%",
            cell: (item: any, ind: number) => <span>{formatNHSNumber(item?.patient_nhs_number)}</span>,
            sortable: false,
            tblName: 'patient_nhs_number'
        },
        {
            name: 'Source',
            width: "10%",
            cell: (item: any, ind: number) => <span>{item.status}</span>,
            sortable: false,
            tblName: 'status'
        },
        {
            name: 'Action',
            width: '80px',
            cell: (item: any, ind: number) => <span>{item.action}</span>,
            sortable: false,
            tblName: 'action'
        }
    ]);


    const referralReducer = (state: any) => state?.referralReducer
    const referralData = createSelector(
        referralReducer,
        (state) => ({
            referralList: state.referralList,

        })
    );
    const { referralList } = useSelector(referralData);


    const handleClick = (event: any, id: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(id)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch(changeTab('Draft Referrals'))
        dispatch(viewReferral({
            page,
            search,
            limit,
            sortBy:sortType,
            status:constant.REFERRAL_STATUS.DRAFT
        }))

    }, [page, search, limit,sortType,dispatch])

    const handleNavigate = (type: string) => {
        if (type === 'edit') {
            Navigate(`/referral/edit/${selectedUserId}`)
        } else if (type === 'create-caseload') {
            Navigate(`/referral/create-caseload/${selectedUserId}`)
        }
    }

    const handleSort = (column: { tblName: string }, sortDirection: 'asc' | 'desc') => {
        const {tblName} = column;
        if(tblName === 'createdAt'){
          setSortType(sortDirection)
        }
      };


    return (
        <>
            <Box className="innerheader">
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} className="searchInputbx">
                        <Search searchCallBack={(e: string) => setSearch(e)} searchValue={search}/>
                    </Grid>

                </Grid>
            </Box>
            <Grid className='tableWrapper'>
                <Table columns={columns}
                handleSort={handleSort}
                    setLimitPerPage={(e: any) => setLimit(e)}
                    limit={limit}
                    data={referralList?.referrals && referralList?.referrals?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            status: item.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
                                <Typography className="activeStatus">Education</Typography>
                            ) : item.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
                                <Typography className="deactiveStatus">Admin</Typography>
                            ) : '',
                            age: calculateAge(item.patient_dob),
                            action:
                                <div key={index}>

                                    <IconButton
                                        aria-controls={`item-menu-${item?.id}`}
                                        aria-haspopup="true"
                                        className="iconStyleAction"
                                        onClick={(e) => handleClick(e, item?.id)}
                                        color='primary'
                                        sx={{ border: `1px solid #6A2382` }} >

                                        <MoreHorizIcon fontSize="medium" />
                                    </IconButton>


                                    <Menu
                                        id={`item-menu-${item?.id}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem onClick={() => handleNavigate("edit")} >
                                    
                                            Continue
                                        </MenuItem>

                               
                                    </Menu>
                                </div>
                        })

                    }
                    )}
                    paginationTotalRows={referralList.totalPages}
                    pagination={true}
                    setCurrentPage={(e: any) => setPage(e)} />
            </Grid>
        </>
    )
}

export default memo(Index)


