import { RESPONSE_AUDIT_TRAIL } from './actionType'


let initialValues = {
    auditResponse: [],
  
}
export const auditTrailReducer = (state = initialValues, action: any) => {

    switch (action.type) {
        case RESPONSE_AUDIT_TRAIL:
            state = { ...state, auditResponse: action?.payload };
            break
        default:
            state = { ...state };
            break;
    }
    return state;
}
