import { useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.scss";
import CommonField from "./CommonField";
function Sensory({
  questions,
  setQuestionData,
  patientName,
  isError,
  setIsError,
  setIsDisableContinue,
}: any) {
  let QuestionNumber =
    questions.personalDetails.length +
    questions.familyHistory.length +
    questions.communication.length +
    questions.socialInteraction.length +
    questions.playImagination.length;

  const handleAnswerChange = (index: number, value: any, key: string) => {
    const validAnswers = ["Parent", "Carer", "Guardian"];
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.sensory];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace(
      "[forename]",
      patientName
    );
    tempPersonDetail[index].answer = value;
    setQuestionData({ ...tempQuestions, sensory: tempPersonDetail });
  };

  useEffect(() => {
    setIsError(false);
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.Referral}
      >
        {questions?.sensory &&
          questions?.sensory?.map((ques: any, index: number) => (
            <>
              {
                <CommonField
                  type={ques.type}
                  index={index}
                  question={ques.question.replace("[forename]", patientName)}
                  answer={ques.answer}
                  options={ques.option}
                  key={"sensory"}
                  required={ques.isRequired}
                  question_number={QuestionNumber}
                  questions={questions?.sensory}
                  isError={isError}
                  handleAnswerChange={handleAnswerChange}
                  ques={ques}
                  setIsDisableContinue={setIsDisableContinue}
                  totalQuestion={questions?.sensory?.length}
                />
              }
            </>
          ))}
      </Grid>
    </>
  );
}

export default Sensory;
