const Referral = {
    add: () => 'referral',
    edit: (id:string) => `referral/${id}`,
    addOpenReferral: () => 'referral/submit-open',
    addReport: () => 'parent-report',
    updateReport: (id:string) => `parent-report/${id}`,
    viewReport: () => 'parent-report',
    view: () => 'referral/list',
    viewByID: () => 'referral/form',
    allSchool: () => 'school',
    userById: () => `referral/create-caseload`,
    gpDetaillist: () => `gp-surgeons`,
    rejectReferral: (id:string) => `referral/${id}/change-status`,
    redReferralsystem: (id:string) => `referral/${id}/change-systemOne-date`

}
export default Referral
