import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAllGPList, getOpenUserList, getSchoolList } from '../../../../redux/action';
import { useLocation } from 'react-router-dom';

export const useSearch = () => {
    const [query, setQuery] = useState<any>('');
    const hostname = window?.location?.hostname;
    const currentURL = window.location.href;
    const parts = currentURL?.split('//')?.[1]?.split('.mycarebridge')?.[0];
    const typeStg = parts?.endsWith('.stg') ? parts?.split('.stg') : [parts];
    const shortName = typeStg?.[0]
    const [userAccess, setUserAccess] = useState<any>('');
    const location = useLocation()
    
    const pathName = location?.pathname
    const dispatch = useDispatch();

    const fetchGPList = useCallback((searchQuery: string) => {
        dispatch(getAllGPList({ limit: 10000000, search: searchQuery }));
    }, [dispatch]);
    
    const fetchSchoolList = useCallback((searchQuery: string) => {
        dispatch(getSchoolList({ limit: 10000000, search: searchQuery }));
    }, [dispatch]);
    
    const fetchOpenUserList = useCallback((searchQuery: string) => {
        if (hostname === 'localhost') {
            dispatch(getOpenUserList({ domain: 'voltas', search: searchQuery }));
        } else {
            dispatch(getOpenUserList({ domain: shortName, search: searchQuery }));
        }
    }, [dispatch, hostname, shortName]);

   
    
    useEffect(() => {
        const handler = setTimeout(() => {
            if (query.length > 2) {
                if (pathName !== "/referral-form") {
                    if (userAccess === "ques30") {
                        fetchGPList(query);
                    } else if (userAccess === "ques22") {
                        fetchSchoolList(query);
                    }
                } else {
                    fetchOpenUserList(query);
                }
            }
        }, 300);
    
        return () => {
            clearTimeout(handler);
        };
    }, [query, fetchGPList, fetchSchoolList, fetchOpenUserList]);


    return {setQuery,query,setUserAccess };
};