import { useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.scss";
import CommonField from "./CommonField";
const Mental = ({
  questions,
  patientName,
  setQuestionData,
  isError,
  setIsError,
  setIsDisableContinue,
}: any) => {
  let QuestionNumber =
    questions.personalDetails.length +
    questions.familyHistory.length +
    questions.communication.length +
    questions.socialInteraction.length +
    questions.playImagination.length +
    questions.sensory.length +
    questions.motorMannerisms.length +
    questions.attentionActivity.length +
    questions.birthDetails.length +
    questions.education.length;
  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.mental];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace(
      "[forename]",
      patientName
    );
    tempPersonDetail[index].answer = value;
    setQuestionData({ ...tempQuestions, mental: tempPersonDetail });
  };

  useEffect(() => {
    setIsError(false);
  }, []);
  return (
    <Grid container spacing={3} className={classes.Referral}>
      {questions?.mental &&
        questions?.mental?.map((ques: any, index: number) => (
          <>
            {
              <CommonField
                type={ques.type}
                index={index}
                question={ques.question.replace("[forename]", patientName)}
                answer={ques.answer}
                options={ques.option}
                key={"mental"}
                required={ques.isRequired}
                question_number={QuestionNumber}
                questions={questions?.mental}
                isError={isError}
                handleAnswerChange={handleAnswerChange}
                ques={ques}
                setIsDisableContinue={setIsDisableContinue}
                totalQuestion={questions?.mental?.length}
              />
            }
          </>
        ))}
    </Grid>
  );
};

export default Mental;
