import {  put, takeLatest } from 'redux-saga/effects';
import { RESPONSE_AUDIT_TRAIL, VIEW_AUDIT_TRAIL } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* getAuditTrail({ id, params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.AuditTrail.view(id, params);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: RESPONSE_AUDIT_TRAIL, payload: response?.data })
        } 
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* referralSaga() {
    yield takeLatest(VIEW_AUDIT_TRAIL, getAuditTrail);
}

export default referralSaga;
