import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.scss";
import CommonField from "./CommonField";

const CreativityImagination = ({
  patientName,
  questions,
  setQuestionData,
  isError,
  setIsError,
  setIsDisableContinue,
}: any) => {
  let QuestionNumber =
    questions.personalDetail.length +
    questions.communication.length +
    questions.socialInteraction.length;

  const handleAnswerChange = (
    index: number,
    value: any,
    key: string,
    updatedQuestion: string,
    updatedAdditionalInfo: string,
    updatedAdditionalQuestion: string,
    updatedIsHeading: string
  ) => {
    let tempQuestions = { ...questions };
    let tempCreativityImagination = [...tempQuestions.creativityImagination];
    tempCreativityImagination[index].answer = value;
    if (tempCreativityImagination[index]) {
      if (tempCreativityImagination[index].question) {
        tempCreativityImagination[index].question = updatedQuestion;
      }
      if (tempCreativityImagination[index].additional_info) {
        tempCreativityImagination[index].additional_info =
          updatedAdditionalInfo;
      }
      if (
        tempCreativityImagination[index].isAdditionalStructure &&
        tempCreativityImagination[index].isAdditionalStructure.question
      ) {
        tempCreativityImagination[index].isAdditionalStructure.question =
          updatedAdditionalQuestion;
      }
    }
    setQuestionData({
      ...tempQuestions,
      creativityImagination: tempCreativityImagination,
    });
  };

  const handleAdditionalChange = (index: number, value: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.creativityImagination];
    tempOtherInformation[index].isAdditionalStructure.answer = value;
    setQuestionData({
      ...tempQuestions,
      creativityImagination: tempOtherInformation,
    });
  };

  useEffect(() => {
    setIsError(false);
  }, []);

  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.Report}>
        {questions?.creativityImagination &&
          questions?.creativityImagination?.map((ques: any, index: number) => (
            <>
              {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "gp_detail", ques.isRequired)} */}
              {
                <CommonField
                  type={ques.type}
                  index={index}
                  question={ques.question}
                  answer={ques.answer}
                  options={ques.option}
                  fileType={"creativityImagination"}
                  required={ques.isRequired}
                  question_number={QuestionNumber}
                  questions={questions?.creativityImagination}
                  isError={isError}
                  handleAnswerChange={handleAnswerChange}
                  ques={ques}
                  setIsDisableContinue={setIsDisableContinue}
                  totalQuestion={questions?.creativityImagination?.length}
                  isAdditional={ques?.isAdditional}
                  isAdditionalQuestion={ques?.isAdditionalStructure}
                  handleAdditionalChange={handleAdditionalChange}
                  patientName={patientName}
                />
              }
            </>
          ))}
      </Grid>
    </>
  );
};

export default CreativityImagination;
