import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { dashboard } = Dictionary

const Dashboard = {
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + dashboard.dashboard(), { params: paramsObj })
  },
}
export default Dashboard
