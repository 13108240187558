import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';
import {componentType} from './interface'
import './style.scss'
const DateRangePicker = ({handleStartDate,handleEndDate,startDateValue,endDateValue,startMinDate,endMaxDate,endMinDate,startMaxDate}:componentType) => {

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleStartDate(event.target.value)
        handleEndDate('');
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleEndDate(event.target.value);
    };

    return (
        <Box display="flex" flexDirection="row" gap={2}>
       
            <TextField
                id="start-date"
                label="Start Date"
                type="date"
                size='small'
                value={startDateValue}
                onChange={handleStartDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: startMinDate, 
                    max: startMaxDate,
                }}
                sx={{'& label':{
                    fontFamily:'poppins'
                },'input':{
                    fontSize:'0.9rem'
                }}}
            />
            <TextField
                id="end-date"
                label="End Date"
                type="date"
                size='small'
                value={endDateValue}
                onChange={handleEndDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: endMinDate, 
                    max: endMaxDate,
                }}
                sx={{'& label':{
                    fontFamily:'poppins'
                },'input':{
                    fontSize:'0.9rem'
                }}}
            />
        </Box>
    );
}

export default DateRangePicker;
