import React, { memo, useCallback, useEffect, useState } from 'react'
 import {useLocation, useParams } from 'react-router-dom';
import './Wrapper.css'
import { useResponsive } from '../../components/atoms/useResponsive';

const Wrapper = ({ children }: any) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {
        isExSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExLargeScreen,
    deviceHeight,
    loginDetail
    } = useResponsive()

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    const { id } = useParams(); 
    const location = useLocation();
    const dynamicStyles = useCallback(() => {
        const backgroundColor = 
            location.pathname === '/referral/add' ||
            location.pathname === '/dashboard' ||
            location.pathname === '/manage-caseload/create' ||
            location.pathname === '/profile' ||
            location.pathname === '/referral/create-caseload' ||
            location.pathname === '/resources/add-content' ||
            (location.pathname === '/manage-caseload/details' || id)
                ? '#fafafa'
                : 'initial';
        
        return { backgroundColor };
    }, [location, id]);

    const WrapperWidth = () => {
        const sidebarWidth = !isMediumScreen ? 0 : 240
        const adjustedWidth = windowWidth - sidebarWidth; 
        return `calc(${adjustedWidth}px)`;
      };

    return (
        <>
        <div className="wrapper-layout " style={dynamicStyles()}>
            <main className="wrapper-main" style={{maxWidth:WrapperWidth(),width:'100%',marginTop:'64px',marginLeft:!isMediumScreen ? '0px' : '240px'}}>
                <div className="wrapper-card" >
                    {children} 
                </div>
            </main>
        </div>
        </>
    )
}

export default memo(Wrapper)