import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbar, GridPagination } from '@mui/x-data-grid';
import CustomPagination from '../Pagination'; // Import your custom pagination component
import classes from './index.module.scss'
const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'firstName', headerName: 'First name', sortable: false, flex: 1 },
  { field: 'lastName', headerName: 'Last name', sortable: false, flex: 1 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    sortable: false,
    flex: 1
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    type: 'number',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1
  }, {
    field: 'fullName',
    headerName: 'Full name',
    type: 'number',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    type: 'number',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1
  },

];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function DataTable() {
  const handleCellClick = (params: any, event: any) => {
    event.preventDefault(); // Prevent default behavior of cell click
    event.stopPropagation(); // Stop event propagation to prevent other click handlers
  };
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu={true}
        style={{ border: 'none' }}
        sx={{
          '& .MuiDataGrid-cell': {
            // border: 'none' // Hide the borders of the cells
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderWrapper, & .MuiDataGrid-columnSeparator': {
            border: 'none' // Hide the borders of the column headers and separators
          },
          '& .MuiDataGrid-row': {
            borderBottom: 'none' // Hide the borders between rows
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          }, '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            // If you need to hide the border of the headers as well
            border: 'none',
          },'& .MuiDataGrid-virtualScroller': {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none',  // For Internet Explorer and Edge
          },
        }}
        hideFooter={false}
        onCellClick={handleCellClick}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}

        components={{
          // Toolbar: GridToolbar,
          Pagination: () => (
            <CustomPagination
              totalPages={10}
              onCurrentPage={(e: any) => console.log(e)}
            />
          ),
        }}
        pageSizeOptions={[5, 10]}
        // autoPageSize
        disableRowSelectionOnClick={true}
        disableColumnSelector={true}
      // disableExtendRowFullWidth={false} 
      // checkboxSelection
      />
    </div>
  );
}