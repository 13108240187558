import  { useEffect, useRef, useState } from "react";
import Button from "../../../components/atoms/Button";
import Grid from "@mui/material/Grid";
import SideStepper from "./sideStepper";
import Personal_Details from "./Personal_Details";
import Social_Interaction from "./Social_Interaction";
import Sensory from "./Sensory";
import Communication from "./Communication";
import FamilyHIstory from "./FamilyHIstory";
import PlayImagination from "./PlayImagination";
import BirthDetails from "./BirthDetails";
import AttentionActivity from "./AttentionActivity";
import MotorMannerisms from "./MotorMannerisms";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { question } from "./question";
import {  addReport, changeTab, getCaseloadById, getParentReport, setCaseloadCurentTab, updateReport } from "../../../redux/action"
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import CONSTANT from '../../../constants/constant';
import { getUserList } from '../../../redux/action';
import classes from './index.module.scss'
import Mental from "./Mental";
import Previous_assessments from "./Previous_assessments";
import Education from "./Education";
import moment from "moment";
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";


const Index = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const pathParts = location.pathname.split('/').filter(Boolean);
  const param = pathParts[1]
  const id = pathParts[2]
  const parentReportId = pathParts[3]
  const [isVisible, setIsVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search)
  const patientName: any = queryParams.get('name')
  const [activeStep, setActiveStep] = useState(0);
  const [enableStep, setEnableStep] = useState(0);
  const [isNewSchool, setIsNewSchool] = useState()
  const [completeSteps, setCompleteSteps] = useState<any>();
  const [isSubmitReport, setIsSubmitReport] = useState(false)
  const [questionData, setQuestionData] = useState<any>({});
  const [isError, setIsError] = useState(false);
  const CaseloadReducer = (state: any) => state?.CaseloadReducer
  const caseloadData = createSelector(
    CaseloadReducer,
    (state) => ({
      caseloadByIdData: state.caseloadByIdData,
    })
  );

  const { caseloadByIdData } = useSelector(caseloadData);


  const [isDisableContinue, setIsDisableContinue] = useState(false);
  const deepCopyQuestion = () => JSON.parse(JSON.stringify(question));
  const userReducer = (state: any) => state?.userManagementReducer
  const getDropdown = (response: any, name: string, last: string) => {
    let dropdown: any = []
    response && response?.map((value: any) => {
      dropdown.push({
        "value": value.id,
        "label": last ? value[name] + ' ' + value[last] : value[name],
        "email": value.email,
        "address": value.address,
        "mobile": value.mobile,
      }
      )
    })
    return dropdown
  }
  const userData = createSelector(
    userReducer,
    (state) => ({
      userList: state.userData.user,

    })
  );
  const { userList } = useSelector(userData);
  const checkAnswers = (arr: any) => {
    let hasNullAnswer = false;
    arr?.forEach((item: any) => {
      if (item.answer === null || item.answer === undefined || item.answer === "" && item.isRequired ) {
        setIsError(true)
        hasNullAnswer = true;
      }
    });
    return hasNullAnswer;
  }

  const ParentReducer = (state: any) => state?.parentReducer
  const ParentData = createSelector(
    ParentReducer,
    (state) => ({
        parentReport: state?.report,
    })
);

const { parentReport } = useSelector(ParentData);





const checkAndReturn = (param:string, data:any, parentData:any) => param === 'edit' ? parentData : data;

  
  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (checkAnswers(checkAndReturn(param, questionData?.personalDetails, parentReport?.data?.personalDetails))) {
          return;
        }
        break;
      case 1:
        if (checkAnswers(checkAndReturn(param, questionData?.familyHistory, parentReport?.data?.familyHistory))) {
          return;
        }
        break;
      case 2:
        if (checkAnswers(checkAndReturn(param, questionData?.communication, parentReport?.data?.communication))) {
          return;
        }
        break;
      case 3:
        if (checkAnswers(checkAndReturn(param, questionData?.socialInteraction, parentReport?.data?.socialInteraction))) {
          return;
        }
        break;
      case 4:
        if (checkAnswers(checkAndReturn(param, questionData?.playImagination, parentReport?.data?.playImagination))) {
          return;
        }
        break;
      case 5:
        if (checkAnswers(checkAndReturn(param, questionData?.sensory, parentReport?.data?.sensory))) {
          return;
        }
        break;
      case 6:
        if (checkAnswers(checkAndReturn(param, questionData?.motorMannerisms, parentReport?.data?.motorMannerisms))) {
          return;
        }
        break;
      case 7:
        if (checkAnswers(checkAndReturn(param, questionData?.attentionActivity, parentReport?.data?.attentionActivity))) {
          return;
        }
        break;
      case 8:
        if (checkAnswers(checkAndReturn(param, questionData?.birthDetails, parentReport?.data?.birthDetails))) {
          return;
        }
        break;
      case 9:
        if (checkAnswers(checkAndReturn(param, questionData?.education, parentReport?.data?.education))) {
          return;
        }
        break;
      case 10:
        if (checkAnswers(checkAndReturn(param, questionData?.mental, parentReport?.data?.mental))) {
          return;
        }
        break;
      case 11:
        if (checkAnswers(checkAndReturn(param, questionData?.previousAssessments, parentReport?.data?.previousAssessments))) {
          return;
        }else{
          setIsSubmitReport(true);
          return; 
        }
      default:
        break;
    }
    setCompleteSteps(activeStep)

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setEnableStep((prevActiveStep) => prevActiveStep + 1);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };



  useEffect(() => {
    if (id) {
      dispatch(getCaseloadById(id));
    } else {
      console.error("ID is undefined.");
    }
    dispatch(getUserList({ scope: CONSTANT.SCOPE.SCHOOL }));
    dispatch(changeTab('Parent / Carer Report Form'));
    setQuestionData(deepCopyQuestion());

  }, [question]);


  const handleSubmit = () => {
    const payload = param === 'edit' ? {
      data: parentReport?.data,
      total_count : 7,
      actual_count : 7,  
      isFinalSubmit : true,
      // caseload_id: id
    } : {
      data: questionData,
      caseload_id: id,
      total_count : 7,
      actual_count : 7,  
      isFinalSubmit : true,
    };
  
    if (param !== 'edit') {
      dispatch(addReport(payload, navigate));
    } else {
      dispatch(updateReport(parentReportId,id, payload, navigate));
    }
    dispatch(setCaseloadCurentTab(2))
  }
  



  useEffect(() => {

    if (userList) {
      let schoolList = getDropdown(userList, 'first_name', 'last_name')
      handleAddSchool(schoolList)
    }

  }, [userList])





  useEffect(() => {
    dispatch(getParentReport({ caseload_id: id }))
  }, [])




  const handleAddSchool = (schoolList: any) => {
    let tempQuestions: any = { ...deepCopyQuestion() };
    if (tempQuestions) {
      let tempPersonDetail = [...tempQuestions?.communication];
      // let tempInformation = [...tempQuestions?.informationSharing];
      // tempInformation[2].answer = currentDate
      setQuestionData({ ...tempQuestions, communication: tempPersonDetail });
      // setQuestionData({ ...tempQuestions, informationSharing: tempInformation });
    }
  }


  useEffect(() => {
    const toggleVisibility = () => {
      if (scrollContainerRef.current) {
        setIsVisible(scrollContainerRef.current.scrollTop > 200);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', toggleVisibility);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', toggleVisibility);
      }
    };
  }, []);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };


  const questionsToPass = param === "edit" ? parentReport?.data : questionData;





  return (
    <>
      <Grid container spacing={4} className={classes.refContainer}>


        <Grid item lg={3} md={3} sm={3} xs={12} className={classes.pt0}>
          <SideStepper activeStep={activeStep} setActiveStep={setActiveStep} completeSteps={completeSteps} enableStep={enableStep} />
        </Grid>
        <Grid ref={scrollContainerRef} item lg={9} md={9} sm={9} xs={12}
        // sx={{
        //   overflowY: 'auto',
        //   maxHeight: { xs: '', sm: '', md: '', lg: '', xl: '54rem' },
        //   '&::-webkit-scrollbar': { display: 'none' },
        //   scrollbarWidth: 'none', // Firefox
        //   msOverflowStyle: 'none', // IE and Edge

        // }}
        >

          <Grid >
            {activeStep === 0 && <Personal_Details prefiled={caseloadByIdData} patientName={patientName?.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 1 && <FamilyHIstory patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 2 && <Communication patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} setIsNewSchool={setIsNewSchool} />}
            {activeStep === 3 && <Social_Interaction patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 4 && <PlayImagination patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 5 && <Sensory patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 6 && <MotorMannerisms patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 7 && <AttentionActivity patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 8 && <BirthDetails patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 9 && <Education patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 10 && <Mental patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
            {activeStep === 11 && <Previous_assessments patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
        

          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mt: '15px' }} >
            <Grid >
              <Button
                variant="outlined"
                onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                fullWidth={true}
                disabled={activeStep === 0}
              >
                Previous
              </Button>
            </Grid>

            <Grid >
              <Button
                variant="contained"
                onClick={handleNext}
                fullWidth={true}
              // disabled={isDisableContinue}
              >
                {activeStep === 13 ? "Submit" : "Continue"}
              </Button>
            </Grid>
            {isVisible && (
              <Grid className="posFixed">
                <Button variant="outlined" onClick={scrollToTop}><ArrowUpwardIcon /></Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid >



      <ConfirmationModal
        open={isSubmitReport}
        handleClose={()=> setIsSubmitReport(false)}
        title="Confirmation"
        message={`Are you sure you want to ${ param === 'edit' ? 'update Report' : 'submit Report'} ?`}
        onConfirm={() => handleSubmit()}
        onCancel={()=> setIsSubmitReport(false)}
      />
      
    </>
  );

}

export default Index