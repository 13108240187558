import { CREATE_RESOURCES, RESOURCES_DOCUMENT, RESOURCES_LISTING, UPDATE_RESOURCES} from './actionType'

export const addResource = (data: any,getHeaders:any) => {
  return {
    type: CREATE_RESOURCES,
    data,
    getHeaders,
  }
}

export const updateResource = (id:string, data: any,getHeaders:any) => {
  return {
    type: UPDATE_RESOURCES,
    data,
    id,
    getHeaders,
  }
}


export const getResourceList = (params: any) => {
  return {
    type: RESOURCES_LISTING,
    params
  }
}

export const getResourceDoc = (params: any) => {
  return {
    type: RESOURCES_DOCUMENT,
    params
  }
}
