import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { UserManagement } = Dictionary

const User_Management = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + UserManagement.add(), data)
  },
  viewModule: async (paramsObj: {}) => {
    return axios.get(BASE_URL + UserManagement.module(), { params: paramsObj })
  },
  viewUser: async (paramsObj: {}) => {
    return axios.get(BASE_URL + UserManagement.user(), { params: paramsObj })
  },
  getUserById: async (id: string) => {
    return axios.get(BASE_URL + UserManagement.userById(id))
  },
  update: async (id:string,data: {}) => {
    return axios.put(BASE_URL + UserManagement.userById(id), data)
  },
  ReActivationMailById: async (id: string) => {
    return axios.post(BASE_URL + UserManagement.reActivationMailById(id))
  },
  deleteUser: async (id: string) => {
    return axios.delete(BASE_URL + UserManagement.deleteUser(id))
  },
  StatusChangedById: async (id: string,data:{}) => {
    return axios.post(BASE_URL + UserManagement.changeStatus(id),data)
  },
  viewOpenUser: async (paramsObj: {}) => {
    return axios.get(BASE_URL + UserManagement.openUser(), { params: paramsObj })
  },
  updateEmail: async (data: {}) => {
    return axios.post(BASE_URL + UserManagement.updateEmail(), data)
  },
  verifyEmail: async (data: {}) => {
    return axios.post(BASE_URL + UserManagement.verifyEmail(), data)
  },
}
export default User_Management
