import React, {
  Suspense,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { usePDF } from "react-to-pdf";
import Button from "../Button";
import "./pdf.scss";
import DownloadIcon from "@mui/icons-material/Download";
import nhsLogo from "../../../assets/Images/nhsLogo.png";
import loaderGif from "../../../assets/Images/loader.svg";
import { createSelector } from "reselect";
import logoLd from "../../../assets/Images/logso.png";
import constant from "../../../constants/constant";
import { Box } from "@mui/material";
import { setPdfLoader } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useGeneratePdf } from "../Hooks/useGeneratePdf";
const ReferralView = React.lazy(() => import("../../organism/ReferralView"));

interface PdfProps {
  data: any;
  title: string;
  fname: any;
}

const Pdf: React.FC<PdfProps> = ({ data, title, fname }) => {
  const currentDate = new Date().toLocaleDateString();
  const [imageError, setImageError] = React.useState(false);
  const currentTime = new Date().toLocaleTimeString();
  const layoutReducer = (state: any) => state?.LayoutReducer;
  const { generatePDF,logoBase64 } = useGeneratePdf(title);
  
  const dispatch = useDispatch();
  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: `${title}-${currentDate} ${currentTime}`,
    page: {
      format: "letter",
      margin: 3,
      orientation: "portrait",
    },
    overrides: {
      pdf: {
        compress: true,
      },
    }, 
  });

  const layoutURLData = createSelector(layoutReducer, (state) => ({
    loaderPdf: state.loaderPdf,
  }));
  const { loaderPdf } = useSelector(layoutURLData);

  const handleImageError = () => {
    setImageError(true);
  };

  useLayoutEffect(() => {
    if (fname === "DOWNLOAD") {
      dispatch(setPdfLoader(true));
      targetRef.current.style.display = "block";
      toPDF();
      targetRef.current.style.display = "none";
    } else {
      targetRef.current.style.display = "none";
    }
    setTimeout(() => {
      dispatch(setPdfLoader(false));
    }, 1000);
  }, [fname]);

  const handleDownloadClick = async () => {
    dispatch(setPdfLoader(true));
    const targetRefValue = targetRef.current;
    if (targetRefValue) {
      targetRefValue.style.display = "block";
      await generatePDF();
      targetRefValue?.style.setProperty("display", "none");
    } else {
      console.error("targetRef.current is null");
    }
    setTimeout(() => {
      dispatch(setPdfLoader(false));
    }, 1000);
  };

  // Function to get background color based on key
  const getColorByKey = useMemo(() => {
    return (key: any) => {
      switch (key) {
        case "reason":
          return "col1";
        case "referrer_detail":
          return "col2";
        case "consent":
          return "col3";
        // Add more cases as needed
        default:
          return "pdfSection"; // Default color
      }
    };
  }, [data]);

  const getColorParentByKey = useMemo(() => {
    return (key: any) => {
      switch (key) {
        case "personalDetails":
        case "familyHistory":
          return "col_parent1";
        case "playImagination":
        case "sensory":
        case "birthDetails":
        case "earlyDevelopement":
        case "informationSharing":
        case "previousAssessments":
        case "attentionActivity":
        case "mental":
          return "col_parent2";
        case "personalDetail":
          // case 'attentionActivityLevels':
          return "col_School1";
        // Add more cases as needed
        default:
          return "pdfParentSection"; // Default color
      }
    };
  }, [data]);

  function addSpaceBeforeCapitalLetters(str: any) {
    return str.replace(/([A-Z])/g, " $1").trim();
  }


  const renderPdfContent = useMemo(() => {
    return (
      <div id="pdf-content" ref={targetRef} style={{ display: !loaderPdf ? "none" : "block" }}>
        {title !== constant.PATHWAY_STATUS.CASELOAD_CREATED && (
          <div
            style={{
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img src={logoLd} alt="" width={"400"} />
          </div>
        )}
      

        {title !== constant.PATHWAY_STATUS.CASELOAD_CREATED && (
          <table style={{ width: "100%", padding: "0px 20px" }}>
            <tr style={{ width: "100%" }}>
            <td style={{ textAlign: "center" }}>
                <img
                  src={logoBase64 || ''}
                  alt="Organization Logo"
                  onError={handleImageError}
                  style={{maxWidth:"200px"}}
                />
              </td>
            </tr>
            {title === constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED && (
              <>
            <tr>
                <td
                  align="center"
                  colSpan={3}
                  style={{ padding: "1rem", fontWeight: "600" }}
                >
                  Screening Questionnaire - Neurodevelopmental Assessment,
                  Autism & ADHD Parents/Carers or Young Person
                </td>
              </tr>
            </>
            
            )}
            {title === constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED && (
                    <>
              <tr>
                <td
                  align="center"
                  colSpan={3}
                  style={{ padding: "1rem", fontWeight: "600" }}
                >
                  Screening Questionnaire - Neurodevelopmental Assessment,
                  Autism & ADHD Education Setting
                </td>
              </tr>
              </>
            )}
            {title === constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED && (
              <tr>
                <td
                  align="left"
                  colSpan={3}
                  style={{
                    padding: "20px",
                    border: "1px solid #000",
                    fontWeight: "600",
                  }}
                >
                  If you would like help filling out this form, please start by
                  asking {fname?.[0]?.answer[0]?.answer} school or social
                  worker. If this is not appropriate, please contact our team to
                  support you.
                </td>
              </tr>
            )}
          </table>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          {Object.entries(data || {}).map(([key, value], index) => (
            <React.Fragment key={index}>
              <ReferralView
                tab={index}
                viewMode='PDF'
                question={value}
                type={addSpaceBeforeCapitalLetters(key)}
                isParentTab={true}
                isPadding={{ padding: 0, borderRadius: 0 }}
                // setnesting={getValue(value)}
                setStylePdf={
                  title === constant.PATHWAY_STATUS.CASELOAD_CREATED &&
                  getColorByKey(key)
                    ? getColorByKey(key)
                    : title ===
                        constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED ||
                      (title ===
                        constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED &&
                        getColorParentByKey(key))
                    ? getColorParentByKey(key)
                    : null
                }
              />
            </React.Fragment>
          ))}
        </Suspense>
        {title === constant.PATHWAY_STATUS.CASELOAD_CREATED && (
          <table style={{ width: "100%", padding: "2rem 1rem " }}>
            <tr style={{ width: "100%" }}>
              <td>
                <small>What happens to your information?</small> <br />
                <small>
                  If you would liketo find out what happens to personal
                  information held aboutyou, pleaseread our privacy policy for
                  moreinformation:
                </small>
                <small>
                  {" "}
                  <a
                    href="https://www.nelft.nhs.uk/download.cfm?ver=1014"
                    target="_blank"
                  >
                    {" "}
                    https://www.nelft.nhs.uk/download.cfm?ver=1014{" "}
                  </a>
                </small>
              </td>
            </tr>
          </table>
        )}
      </div>
    );
  }, [data,logoBase64]);



  return (
    <>
      {loaderPdf ? (
        <Box className="loadergif" component={"div"}>
          <img src={loaderGif} alt="loader" />{" "}
        </Box>
      ) : (
        <>
          {fname !== "REMOVEBTN" && fname !== "DOWNLOAD" && (
            <Button
              variant={"outlined"}
              onClick={handleDownloadClick}
              sx={{ float: "right", marginRight: "1rem", right: 0 }}
              startIcon={<DownloadIcon />}
            >
              {" "}
              Download PDF
            </Button>
          )}
          {renderPdfContent}
        </>
      )}
    </>
  );
};

export default Pdf;
