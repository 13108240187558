const Task = {
    add: () => 'task',
    view: () => 'task',
    update: (id: string) => `task/${id}`,
    delete: (id: string) => `task/${id}`,
    getById: (id: string) => `caseload/${id}/users`,
    // getTaskDocById: (id: string) => `task/${id}/documents`,
    postById: (id: string) => `task/${id}/submit`,

}
export default Task