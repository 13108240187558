import React, { useEffect, useState } from 'react';
import {
  Menu as MenuIcon, MoveToInbox, School as SchoolIcon,
  ExpandLess, ExpandMore
} from '@mui/icons-material';
import {
  Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton,
  ListItemIcon, ListItemText, Toolbar, Collapse, Grid, Typography
} from '@mui/material';
import sidebarCss from './sidebar.module.scss';
import Overview from '../../../assets/Images/dashboard.svg';
import adminSetting from '../../../assets/Images/admin.svg';
import clinician from '../../../assets/Images/user-nurse.svg';
import parents from '../../../assets/Images/parents.svg';
import schoolIcon from '../../../assets/Images/school.svg';
import manageRef from '../../../assets/Images/refeeral_m.svg';
import closedRef from '../../../assets/Images/closedRef.svg';
import redFlag from '../../../assets/Images/redFlag.svg';
import activeRef from '../../../assets/Images/activeRef.svg';
import rejectedRef from '../../../assets/Images/rejectedRef.svg';
import Logo from '../../../assets/Images/logo.svg';
import officeLogo from '../../../assets/Images/um.svg'
import openurl from '../../../assets/Images/openurl.svg'
import uploadAssets from '../../../assets/Images/uploadAssets.svg'
import './Sidebar.css'
import { createSelector } from "reselect";
import { Avatar } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeTab, setOpenReferralUrl } from '../../../redux/action'
import constant from '../../../constants/constant';
import { useOrgnizationLogo } from '../../atoms/OrgnizationLogo';
import ConfirmationModal from '../../atoms/ConfirmationModal';

const drawerWidth = 240;
interface Props {
  mobileOpen: any;
  setMobileOpen: any;
  // window?: () => Window;
}

const Sidebar = (props: Props) => {

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const layoutReducer = (state: any) => state?.LayoutReducer
  const [isCancelReferral, setIsCancelReferral] = useState(false)
  const [currentNavigateTab, setCurrentNavigateTab] = useState('')
  const [open, setOpen] = React.useState(true);
  const [imageError, setImageError] = React.useState(false);
  const [openCaseload, setOpenCaseload] = React.useState(true);

  const {
    imageSrc,
    placeholderImage,
    loginDetail
  } = useOrgnizationLogo();


  const handleClick = () => {
    setOpen(!open);
  };

  // const { window } = props;

  const handleDrawerToggle = () => {
    props.setMobileOpen(!props.mobileOpen);
  };

  const pathnameSegments = location.pathname.split('/');
  const SegmentDir = pathnameSegments[1];


  const handleNavigate = (tab: string) => {
    setCurrentNavigateTab(tab)
    if (location.pathname === '/referral/add') {
      setIsCancelReferral(true)
    } else {
      navigate(tab)
    }
  }

  const handleImageError = () => {
    setImageError(true);
  };

  const layoutURLData = createSelector(
    layoutReducer,
    (state) => ({
      openRefurl: state.openurlposL,

    })
  );

  // useEffect(()=>{
  //   setCurrentNavigateTab('')
  // },[currentNavigateTab])


  const { openRefurl } = useSelector(layoutURLData);

  //  useEffect(()=>{
  // if(SegmentDir === 'user-management' || SegmentDir === 'manage-caseload' || SegmentDir === 'resources' || SegmentDir === 'referral'){
  // document.body.setAttribute('style','overflow:hidden')
  // }else{
  //   document.body.setAttribute('style','overflow:auto')
  // }
  // setCurrentNavigateTab('')
  //  },[SegmentDir])


  const drawer = (
    <>
      <div className={sidebarCss.wrapper} >
        <Toolbar className={sidebarCss.logoWrapper}>
          <Avatar src={Logo} alt="Logo" style={{ width: '100%', height: '100%' }} />
        </Toolbar>
        <Divider />
        <List className={sidebarCss.navigation}>
          <ListItem
            disablePadding
            className={((location.pathname || currentNavigateTab) === '/dashboard' ? sidebarCss.isDashactive : 'nav-link')}
            onClick={() => {
              handleNavigate("/dashboard")
              dispatch(changeTab('Dashboard'))
            }}
          >
            <ListItemButton
            >
              <ListItemIcon className={sidebarCss.mwFixed}>
                <Avatar src={Overview} alt="Logo" style={{ width: '25px', height: '25px', borderRadius: '0%' }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>





          {loginDetail.scope !== constant.SCOPE.CLINICIAN && <ListItemButton
            className={(SegmentDir === 'user-management' ? sidebarCss.isDirActive : 'nav-link')}
            onClick={handleClick}>
            <ListItemIcon className={sidebarCss.mwFixed}>
              <Avatar src={officeLogo} alt="Logo" style={{ width: '25px', height: '25px', borderRadius: '0%' }} />
            </ListItemIcon>
            <ListItemText primary="User Management" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>}
          {loginDetail.scope !== constant.SCOPE.CLINICIAN &&
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                {loginDetail.role === constant.ROLE.ALL_ACCESS && <ListItem
                   
                  disablePadding
                  onClick={() => {
                    handleNavigate("/user-management/admin")
                    dispatch(changeTab('Admin Accounts'))
                  }}

                  className={((location.pathname === '/user-management/admin') || (location.pathname === '/user-management/admin/add') || (currentNavigateTab === '/user-management/admin') || (currentNavigateTab === '/user-management/admin/add') ? sidebarCss.isSideActive : 'nav-link')}
                >
                  <ListItemButton sx={{ pl: 4 }}  >
                    <ListItemIcon className={sidebarCss.mwFixed}>
                      <Avatar src={adminSetting} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                    </ListItemIcon>
                    <ListItemText primary="Admin Accounts" />
                  </ListItemButton>
                </ListItem>}

                {loginDetail.scope !== constant.SCOPE.CLINICIAN && <ListItem
                   
                  disablePadding
                  onClick={() => {
                    handleNavigate("/user-management/clinician")
                    dispatch(changeTab('Clinician Account'))
                  }}

                  className={((location.pathname === '/user-management/clinician') || (currentNavigateTab === '/user-management/clinician') || (location.pathname === '/user-management/clinician/add') || (currentNavigateTab === '/user-management/clinician/add') ? sidebarCss.isSideActive : 'nav-link')}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon className={sidebarCss.mwFixed}>
                      <Avatar src={clinician} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                    </ListItemIcon>
                    <ListItemText primary="Clinician Accounts" />
                  </ListItemButton>
                </ListItem>}


                <ListItem
                   
                  disablePadding
                  onClick={() => {
                    handleNavigate("/user-management/parent")
                    dispatch(changeTab('Parent Account'))
                  }}
                  className={((location.pathname === '/user-management/parent') || (currentNavigateTab === '/user-management/parent') || (location.pathname === '/user-management/parent/add') || (currentNavigateTab === '/user-management/parent/add') ? sidebarCss.isSideActive : 'nav-link')}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon className={sidebarCss.mwFixed}>
                      {/* <FamilyRestroomIcon /> */}
                      <Avatar src={parents} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                    </ListItemIcon>
                    <ListItemText primary="Parent/Carer Accounts" />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  disablePadding
                  onClick={() => {
                    handleNavigate("/user-management/education_setting")
                    dispatch(changeTab('Education Setting Account'))
                  }}

                  className={((location.pathname === '/user-management/education_setting') || (currentNavigateTab === '/user-management/education_setting') || (location.pathname === '/user-management/education_setting/add') || (currentNavigateTab === '/user-management/education_setting/add') ? sidebarCss.isSideActive : 'nav-link')}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon className={sidebarCss.mwFixed}>
                      <Avatar src={schoolIcon} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                    </ListItemIcon>
                    <ListItemText primary="Education Setting Accounts" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          }



          <ListItemButton onClick={() => setOpenCaseload(!openCaseload)}
            className={(SegmentDir === 'manage-caseload' || (location.pathname === '/referral') || (currentNavigateTab === '/referral') ? sidebarCss.isDirActive : 'nav-link')}
          >
            <ListItemIcon className={sidebarCss.mwFixed}>
              <Avatar src={manageRef} alt="Logo" style={{ width: '25px', height: '25px', borderRadius: '0%' }} />
            </ListItemIcon>
            <ListItemText primary="Manage Referrals" />
            {openCaseload ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={openCaseload} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>


              <ListItem
                disablePadding
                onClick={() => {
                  handleNavigate("/manage-caseload/active")
                  dispatch(changeTab('Active Referrals'))
                }}

                className={((location.pathname === '/manage-caseload/active') || (currentNavigateTab === '/manage-caseload/active') ? sidebarCss.isSideActive : 'nav-link')}
              >
                <ListItemButton sx={{ pl: 4 }}  >
                  <ListItemIcon className={sidebarCss.mwFixed}>
                    <Avatar src={activeRef} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                  </ListItemIcon>
                  <ListItemText primary="Active " />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                onClick={() => {
                  dispatch(setOpenReferralUrl(true))
                }}

                className={(openRefurl ? sidebarCss.isSideActive : 'nav-link')} >
                <ListItemButton sx={{ pl: 4 }}  >
                  <ListItemIcon className={sidebarCss.mwFixed}>
                    <Avatar src={openurl} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                  </ListItemIcon>
                  <ListItemText primary="Open Referral URL" />
                </ListItemButton>
              </ListItem>

              {loginDetail.scope !== constant.SCOPE.CLINICIAN && <ListItem
                disablePadding
                onClick={() => {
                  handleNavigate("/manage-caseload/red-flag")
                  dispatch(changeTab('Red Flag'))
                }}

                className={((location.pathname === '/manage-caseload/red-flag') || (currentNavigateTab === '/manage-caseload/red-flag') ? sidebarCss.isSideActive : 'nav-link')}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon className={sidebarCss.mwFixed}>
                    <Avatar src={redFlag} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                  </ListItemIcon>
                  <ListItemText primary="Red Flag" sx={{ color: '#f00' }} />
                </ListItemButton>
              </ListItem>}


              {loginDetail.scope !== constant.SCOPE.CLINICIAN && <ListItem
                 
                disablePadding
                onClick={() => {
                  handleNavigate("/manage-caseload/draft")
                  dispatch(changeTab('Draft'))
                }}

                className={((location.pathname === '/manage-caseload/draft') || (currentNavigateTab === '/manage-caseload/draft') ? sidebarCss.isSideActive : 'nav-link')}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon className={sidebarCss.mwFixed}>
                    <Avatar src={activeRef} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                  </ListItemIcon>
                  <ListItemText primary="Draft" />
                </ListItemButton>
              </ListItem>}

              <ListItem
                disablePadding
                onClick={() => {
                  handleNavigate("/manage-caseload/closed")
                  dispatch(changeTab('Closed'))
                }}

                className={((location.pathname === '/manage-caseload/closed') || (currentNavigateTab === '/manage-caseload/closed') ? sidebarCss.isSideActive : 'nav-link')}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon className={sidebarCss.mwFixed}>
                    <Avatar src={closedRef} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                  </ListItemIcon>
                  <ListItemText primary="Closed " />
                </ListItemButton>
              </ListItem>

              {loginDetail.scope !== constant.SCOPE.CLINICIAN && <ListItem disablePadding
                className={((location.pathname === '/manage-caseload/rejected') || (currentNavigateTab === '/manage-caseload/rejected') ? sidebarCss.isSideActive : 'nav-link')}
                onClick={() => {
                  handleNavigate("/manage-caseload/rejected")
                  dispatch(changeTab('Rejected'))
                }}


              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon className={sidebarCss.mwFixed}>
                    <Avatar src={rejectedRef} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%' }} />
                  </ListItemIcon>
                  <ListItemText primary="Rejected" />
                </ListItemButton>
              </ListItem>}

            </List>
          </Collapse>




          {loginDetail.scope !== constant.SCOPE.CLINICIAN && <ListItem
            disablePadding
            className={((location.pathname || currentNavigateTab) === '/resources/upload-Assets' ? sidebarCss.isDashactive : 'nav-link')}
            onClick={() => {
              handleNavigate("/resources/upload-Assets")
              dispatch(changeTab('Upload Assets'))
            }}
          >
            <ListItemButton
            >
              <ListItemIcon className={sidebarCss.mwFixed}>
                <Avatar src={uploadAssets} alt="Logo" style={{ width: '25px', height: '25px', borderRadius: '0%' }} />
              </ListItemIcon>
              <ListItemText primary="Resource" />
            </ListItemButton>
          </ListItem>}
        </List>

      </div>
      <Box className={sidebarCss.orgLogo}>
        <img
          src={imageError ? placeholderImage : imageSrc}
          alt="Organization Logo"
          onError={handleImageError}
        />
      </Box>
    </>
  );





  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { xs: 'none', sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={props.mobileOpen}
          onClose={(e) => props.setMobileOpen(!props.mobileOpen)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', lg: 'none', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className='customScroll'
          sx={{
            display: { xs: 'none', sm: 'none', lg: 'block', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, boxShadow: '2px 2px 5px 2px rgb(0 0 0 / 13%)', borderColor: 'transparent', display: 'flex', justifyContent: 'space-between' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <>
      

        <ConfirmationModal
        open={isCancelReferral}
        handleClose={()=> setIsCancelReferral(false)}
        title="Confirmation"
        message="Do you want to exit ? It will delete all the information filled."
        onConfirm={() => {
          setIsCancelReferral(false)
          navigate(currentNavigateTab)
        }}
        onCancel={()=> setIsCancelReferral(false)}
      />

      </>
    </Box>
  );
}

export default React.memo(Sidebar)