import React, { memo, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";

import schoolIcon from "../../../assets/Images/school.svg";
import { useDispatch, useSelector } from "react-redux";
import parents from "../../../assets/Images/parents.svg";
import { createSelector } from "reselect";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { changeTab, dashReport, getUserList } from "../../../redux/action";
import constant from "../../../constants/constant";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const Index = () => {
  const chartRef = useRef(null);
  const userReducer = (state: any) => state?.userManagementReducer;
  const dispatch = useDispatch();
  let loginDetailString = localStorage.getItem("loginResponse");
  let loginDetail = loginDetailString ? JSON.parse(loginDetailString) : {};

  const referralReducer = (state: any) => state?.referralReducer;
  const dashboardData = createSelector(referralReducer, (state) => ({
    dashboardReport: state?.dashboardReport,
  }));
  const { dashboardReport } = useSelector(dashboardData);

  useEffect(() => {
    dispatch(changeTab("Overview"));
    if (loginDetail.scope !== constant.SCOPE.CLINICIAN) {
      dispatch(getUserList({}));
      dispatch(dashReport({}));
    }
  }, []);

  const userData = createSelector(userReducer, (state) => ({
    userList: state.userData.user,
  }));
  const { userList } = useSelector(userData);

  const filterByScope = (scope: string) => {
    const list = userList?.filter((item: any) => item?.scope === scope);
    return {
      total: list?.length,
      active: list?.filter(
        (item: any) => item?.status === constant.STATUS.ACTIVE
      ).length,
      notRegistered: list?.filter(
        (item: any) => item?.status === constant.STATUS.NOT_REGISTERED
      ).length,
      inactive: list?.filter(
        (item: any) => item?.status === constant.STATUS.INACTIVE
      ).length,
    };
  };

  const parentStats = filterByScope(constant.SCOPE.PARENT);
  const educationStats = filterByScope(constant.SCOPE.SCHOOL);

  const labels = [
    "Education Report",
    "Parent Report",
    "Ready for Clinician Review Days",
    "Outcome Received Days",
  ];

  const values = [
    dashboardReport.education_report_submission_days,
    dashboardReport.parent_report_submission_days,
    dashboardReport.ready_for_clinician_review_days,
    dashboardReport.outcome_received_days,
  ].map((val) => (isNaN(val) ? 0 : parseFloat(val)));

  const createGradient = (ctx: any) => {
    const gradient = ctx.createLinearGradient(100, 400, 400, 0);
    gradient.addColorStop(1, "#6A2382"); // Start color
    gradient.addColorStop(0, "#6A2382"); // End color
    return gradient;
  };

  const chartData = {
    labels, // Replace with your actual labels
    datasets: [
      {
        label: "Average Time (days)",
        data: values,
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx } = chart;
          return createGradient(ctx);
        },
        borderColor: (context: any) => {
          const chart = context.chart;
          const { ctx } = chart;
          return createGradient(ctx);
        },
        borderWidth: 0,
        borderRadius: 100,
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: "y" as const, // Ensure this is typed correctly
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          // Optional: Control tick appearance
          callback: function (value: any) {
            return value; // Custom format
          },
        },
      },
      y: {
        beginAtZero: true,
        // Optional: Adjust padding between bars
        grid: {
          display: true, // Hide grid lines for cleaner look
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
        barThickness: 100, // Fixed bar width (in pixels)
        maxBarThickness: 100, // Maximum width of the bar
        categoryPercentage: 0.8, // Relative width of bars in relation to the category width
        barPercentage: 0.9, // Relative width of bars in relation to the space available for each category
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            family: "poppins", // Change font family for the legend labels
            size: 14, // Adjust font size if needed
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#000", // Optionally set tick color
            font: {
              family: "Poppins", // Set font family for x-axis labels
              size: 20, // Set font size for x-axis labels
            },
          },
          grid: {
            display: false, // Hide grid lines if desired
          },
        },
        y: {
          ticks: {
            color: "#000", // Optionally set tick color
            font: {
              family: "Poppins", // Set font family for y-axis labels
              size: 20, // Set font size for y-axis labels
            },
          },
          grid: {
            display: true, // Show grid lines if desired
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            font: {
              family: "Poppins", // Set font family for legend labels
              size: 20, // Set font size for legend labels
            },
          },
        },
      },
    },
  };

  const cardStyles = {
    mb: {
      xs:1
    },
    mr: {
      xs:0
    },
    display: "flex",
    alignItems: "center",

  };

  const contentStyles = {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    minHeight: "120px",
    padding: 0,
    width: "100%",
    paddingBottom: "0!important",
  };

  const iconBoxStyles = {
    maxWidth: {
      lg:'130px',
      xs:'100px',
      md:'130px',
      sm:'130px'
    },
    minWidth: {
      lg:'130px',
      xs:'100px',
      md:'130px',
      sm:'130px'
    },
    minHeight: {
      lg:'130px',
      xs:'100px',
      md:'130px',
      sm:'130px'
    },
    maxHeight: {
      lg:'130px',
      xs:'100px',
      md:'130px',
      sm:'130px'
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#6A2382",
    height: "100%",
  };

  const textStyle = {
    color: "#A0AEC0",
  };

  const numberStyle = {
    color: "#48BB78",
    fontSize:{
     lg:"16px",
     xs:'12px',

    },
  };

  const sectionStyle = {
    padding: "0rem 0rem 0.1rem 0rem",
    margin: "0rem 2rem 0rem 0rem",
    borderBottom: "1px solid #eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const StatsCard = ({ icon, title, stats }: any) => (
    <Grid item md={6} sm={6} xs={12} sx={{ p: "0!important" }}>
      <Card sx={cardStyles}>
        <CardContent sx={contentStyles}>
          <Box sx={iconBoxStyles}>
            <img src={icon} width={"50px"} style={{ filter: "invert(1)" }} />
          </Box>
          <Box
            sx={{
              flexGrow: "1",
              minHeight: {
                sm: "100%",
                md: "130px",
                xs:'100px'
              },
              padding: "0.5rem",
            }}
          >
            <Typography
              fontSize={"16px"}
              component={"h2"}
              fontWeight={600}
              mb={"0.2rem"}
              sx={{
                fontSize: {
                  sm: "13px",
                  md: "16px",
                  xs:'13px'
                },
              }}
            >
              {title} &nbsp;: {stats.total}
            </Typography>
            {["active", "notRegistered", "inactive"].map((key) => (
              <Typography
                key={key}
                sx={{
                  ...sectionStyle,
                  "&:last-child": {
                    border: "none",
                  },
                }}
              >
                <span style={{ ...textStyle, textTransform: "capitalize" }}>
                  {key.replace(/([A-Z])/g, " $1")}
                </span>
                <Box component={'span'} sx={numberStyle}>{stats[key]}</Box>
              </Typography>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
      <Grid>
        {loginDetail.scope !== constant.SCOPE.CLINICIAN && (
          <>
          <Grid container sx={{gap:'1rem',flexWrap:'nowrap',flexDirection:{
            lg:'row',
            md:'row',
            sm:'row',
            xs:'column'
          }}}>
            <StatsCard
              icon={parents}
              title="Total Number of Parents"
              stats={parentStats}
            />
            <StatsCard
              icon={schoolIcon}
              title="Total Number of Education Setting"
              stats={educationStats}
            />
            </Grid>
                   <Grid container>
            <Grid item md={12} sm={12} my={5} maxWidth={"100%"}>
              <Typography fontWeight={600}>
                Average Referrals Time in Days
              </Typography>
              <Bar
                ref={chartRef}
                data={chartData}
                options={options}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  maxHeight: "300px",
                  fontFamily: "poppins",
                }}
              />
            </Grid>
          </Grid>
          </>
        )}
      </Grid>
  );
};

export default memo(Index);
