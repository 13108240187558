import  { useEffect } from 'react';
import {
  Grid,
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';

function Sensory({ patientName, questions, setQuestionData, isError, setIsError, setIsDisableContinue }: any) {
  let QuestionNumber = questions.personalDetail.length + questions.communication.length +
    questions.socialInteraction.length + questions.creativityImagination.length + questions?.behaviour?.length

  const handleAnswerChange = (index: number, value: any, key: string, updatedQuestion: string, updatedAdditionalInfo: string, updatedAdditionalQuestion: string, updatedIsHeading: string) => {
    let tempQuestions = { ...questions };
    let tempSensory = [...tempQuestions.sensory];
    tempSensory[index].answer = value;

    if (tempSensory[index]) {
      if (tempSensory[index].question) {
        tempSensory[index].question = updatedQuestion
      }
      if (tempSensory[index].additional_info) {
        tempSensory[index].additional_info = updatedAdditionalInfo
      }
      if (tempSensory[index].isAdditionalStructure && tempSensory[index].isAdditionalStructure.question) {
        tempSensory[index].isAdditionalStructure.question = updatedAdditionalQuestion
      }
    }
    const question_id = tempSensory[index].question_id;

    if (["ques34", "ques35", "ques36", "ques37", "ques38"].includes(question_id) && value === 'No') {
      tempSensory[index].isAdditionalStructure.isRequired = false;
      tempSensory[index].isAdditionalStructure.answer = '';
    } else if (["ques34", "ques35", "ques36", "ques37", "ques38"].includes(question_id) && value === 'Yes') {
      tempSensory[index].isAdditionalStructure.isRequired = true;
      tempSensory[index].isAdditionalStructure.answer = '';
    }


    setQuestionData({ ...tempQuestions, sensory: tempSensory });

  };

  const handleAdditionalChange = (index: number, value: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.sensory];
    tempOtherInformation[index].isAdditionalStructure.answer = value;
    setQuestionData({ ...tempQuestions, sensory: tempOtherInformation });
  }


  useEffect(() => {
    setIsError(false)
  }, [])
  return (
    <>

      <Grid container direction="column" spacing={2} className={classes.Report}>
        {questions?.sensory && questions?.sensory?.map((ques: any, index: number) => (
          <>
            {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "safe_guarding_detail", ques.isRequired)} */}
            {<CommonField
              type={ques.type}
              index={index}
              question={ques.question}
              answer={ques.answer}
              options={ques.option}
              fileType={"sensory"}
              required={ques.isRequired}
              question_number={QuestionNumber}
              questions={questions?.sensory}
              isError={isError}
              handleAnswerChange={handleAnswerChange}
              ques={ques}
              setIsDisableContinue={setIsDisableContinue}
              totalQuestion={questions?.sensory?.length}
              isAdditional={ques?.isAdditional}
              isAdditionalQuestion={ques?.isAdditionalStructure}
              handleAdditionalChange={handleAdditionalChange}
              patientName={patientName}
            />}
            {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
          </>))}
      </Grid>
    </>
  );
}

export default Sensory;
