
import { Typography, Container, Grid } from '@mui/material';
import successImage from '../../../assets/Images/success.png';

const SubmitSuccessPage = () => {
    return (

        <Grid sx={{mt:'12rem'}}>
            <Grid display={'flex'} alignItems="center" justifyContent="center">
                <img src={successImage} alt="Success" style={{ maxWidth: '10rem', height: 'auto' }} />
            </Grid>
            <Container maxWidth="md">
                <Grid container spacing={3} alignItems="center" justifyContent="center" mt={"10px"}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4" align="center" gutterBottom>
                          Referral Submitted Successfully
                        </Typography>
                        <Typography variant="body1" align="center" paragraph>
                            Thank you for submitting.
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Grid>
    );
};

export default SubmitSuccessPage;
