import React, { memo } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    Grid,
} from "@mui/material";
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import classes from "./index.module.scss";
import { useLocation } from 'react-router-dom';


const SideStepper = ({ activeStep, setActiveStep, completeSteps, enableStep, questions }: any) => {
    const location = useLocation()
    const pathName = location?.pathname
    const countRequiredConsent = questions?.consent?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredReason = questions?.reason?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredPersonDetail = questions?.person_Detail?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredGPDetail = questions?.gp_detail?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredSafeGuardDetail = questions?.safe_guarding_detail?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredParentCareer = questions?.parent_carers_detail?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredAdditionalParentCareer = questions?.additional_parent_carers_details?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredReferrerDetail = questions?.referrer_detail?.filter((obj: any) => obj.isRequired)?.length;
    const countRequiredSupportSoFar = questions?.support_so_far?.filter((obj: any) => obj.isRequired)?.length;
    const countDigital = questions?.digital_literacy?.filter((obj: any) => obj.isRequired)?.length;
    const steps = [
        {
            label: "Consent ",
            label2: `Total Question 1 of ${countRequiredConsent}`,
        },
        {
            label: "Referral Reason",
            label2: `Total Question ${countRequiredConsent + 1} of ${countRequiredReason + countRequiredConsent}`,
        },
        {
            label: "Child/Young Person Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReason + 1} of ${countRequiredPersonDetail + countRequiredConsent + countRequiredReason}`,
        },
        {
            label: "GP Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReason + countRequiredPersonDetail + 1} of ${countRequiredGPDetail + countRequiredConsent + countRequiredReason + countRequiredPersonDetail}`,
        },
        {
            label: "Safeguarding Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + 1} of ${countRequiredSafeGuardDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail}`,
        },
        {
            label: "Parent/Carer's Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + 1} of 
            ${countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer}`,
        },
        {
            label: "Additonal Parent/Carer's Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail +
                countRequiredParentCareer + 1} of ${countRequiredAdditionalParentCareer +
                countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer}`,
        },
        {
            label: "Referrer Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReason + countRequiredPersonDetail +
                countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer + 1} of
                 ${countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer}`,
        },
        {
            label: "Support So Far",
            label2: `Total Question ${countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer + 1} of ${countRequiredSupportSoFar +
                countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail +
                countRequiredParentCareer + countRequiredAdditionalParentCareer}`,
        },
        {
            label: "Digital Literacy",
            label2: `Total Question ${countRequiredSupportSoFar +
                countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail +
                countRequiredParentCareer + countRequiredAdditionalParentCareer + 1} of ${countDigital + countRequiredSupportSoFar + countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail +
                countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer}`,
        }
    ];


    const openStep = [
        {
            label: "Referrer Detail ",
            label2: `Total Question 1 of ${countRequiredReferrerDetail}`,
        },
        {
            label: "Consent",
            label2: `Total Question ${countRequiredReferrerDetail + 1} of ${countRequiredConsent + countRequiredReferrerDetail}`,
        },
        {
            label: "Referral Reason",
            label2: `Total Question ${countRequiredConsent + countRequiredReferrerDetail + 1} of ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason}`,
        },
        {
            label: "GP Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + 1} of 
            ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredGPDetail}`,
        },
        {
            label: "Child/Young Person Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReferrerDetail + countRequiredGPDetail + countRequiredReason + 1} of ${countRequiredConsent + countRequiredGPDetail + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail}`,
        },

        {
            label: "Safeguarding Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + 1} of 
            ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail}`,
        },
        {
            label: "Parent/Carer's Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + 1} of
             ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer}`,
        },
        {
            label: "Additonal Parent/Carer's Details",
            label2: `Total Question ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + 1} of 
            ${countRequiredConsent + countRequiredReferrerDetail + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer}`,
        },
        {
            label: "Support So Far",
            label2: `Total Question ${countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer + 1} of ${countRequiredSupportSoFar +
                countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail +
                countRequiredParentCareer + countRequiredAdditionalParentCareer}`,
        },
        {
            label: "Digital Literacy",
            label2: `Total Question ${countRequiredSupportSoFar +
                countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail +
                countRequiredParentCareer + countRequiredAdditionalParentCareer + 1} of ${countDigital + countRequiredSupportSoFar + countRequiredReferrerDetail + countRequiredConsent +
                countRequiredReason + countRequiredPersonDetail +
                countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer}`,
        }
    ];
    const CustomStepIcon: React.FC<any> = ({ active, completed, stepIndex, completeSteps, activeStep }) => {

        return (
            <>
                {completeSteps >= stepIndex ? (
                    <CheckCircleOutlineOutlinedIcon className={`${classes.custom_fill_color} ${classes.fs_30}`} />
                ) : stepIndex === activeStep ? (
                    <AdjustOutlinedIcon className={`${classes.custom_non_fill_color} ${classes.fs_30}`} />
                ) : <AdjustOutlinedIcon className={`${classes.custom_non_step_fill_color} ${classes.fs_30}`} />
                }
            </>
        );
    };
    const handleStepClick = (step: any) => {
        // Handle click event for each step
        // if (completeSteps >= step || step === activeStep) {
        if (enableStep >= step) {
            setActiveStep(step);
        }
        // }
    };

 
    return (
        <>
            <Grid className={`${classes.stepCard} stepperRefGlobal`} >
                <Card
                    sx={{
                        boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.05)",
                        mx: 3,
                        minHeight: "auto",
                        cursor: 'pointer',
                    }}
                >
                    <Stepper sx={{ mx: 3, marginTop: "2rem", color: '#6A2382' }}
                        activeStep={activeStep} orientation="vertical" className={classes.stepperStyle}
                    >
                        {openStep.map((step: any, index: any) => (
                            <Step key={step.label} className={[
                                completeSteps >= index ? classes.custom_fill_color : index === enableStep ? classes.custom_non_fill_color : classes.custom_non_step_fill_color
                            ].join(' ')}  >

                                <StepLabel

                                    className={[
                                        completeSteps >= index ? classes.custom_fill_color : index !== enableStep ? classes.custom_non_step_fill_color : ''
                                    ].join(' ')}
                                    sx={{ cursor: 'pointer' }}
                                    StepIconComponent={(props) => <CustomStepIcon {...props} stepIndex={index} completeSteps={completeSteps} activeStep={enableStep} />}
                                    onClick={() => handleStepClick(index)}
                                >

                                    <Typography className={[
                                        completeSteps >= index ? classes.custom_fill_color : index !== enableStep ? classes.custom_non_step_fill_color : ''
                                    ].join(' ')} >{step.label}</Typography>
                                    <Typography variant="subtitle2" >
                                        {step.label2}
                                    </Typography>

                                </StepLabel>

                            </Step>
                        ))}
                    </Stepper>
                    {/* {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 0 }}>
                            <Typography>
                                All steps completed.
                            </Typography>
                        </Paper>
                    )} */}
                </Card>
            </Grid>
        </>
    )
}

export default memo(SideStepper)