import { put, takeLatest } from 'redux-saga/effects';
import { ADD_SCHOOL_REPORT, VIEW_SCHOOL_REPORT, REPORT_SCHOOL_RESPONSE, GET_REPORT_CASELOAD_DETAIL, REPORT_CASELOAD_RESPONSE, GET_REPORT_SCHOOL_ALL_DETAIL, GET_REPORT_SCHOOL_ALL_RESPONSE, GET_REPORT_BY_ID, GET_REPORT_DETAIL, EDIT_SCHOOL_REPORT } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* addSchoolReport({ payload, navigate, isEdit, caseload_Id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let response: AxiosResponse<any>
        if (isEdit) {
            response = yield service.report.updateSchoolReport(caseload_Id, payload);
        } else {
            response = yield service.referral.add(payload);
        }
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            navigate("/manage-caseload/active");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* editSchoolReport({ payload,schoolReportId, navigate, isEdit, caseload_Id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let response: AxiosResponse<any>
        if (isEdit) {
            response = yield service.report.updateSchoolReport(schoolReportId, payload);
        } else {
            response = yield service.referral.add(payload);
        }
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            navigate(`/manage-caseload/create?id=${caseload_Id}`);

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getReport({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.view(params);

        yield put({ type: REPORT_SCHOOL_RESPONSE, payload: response?.data })
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getAllReportDetail({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.viewAllDetail(params);

        yield put({ type: GET_REPORT_SCHOOL_ALL_RESPONSE, payload: response?.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getDetailById({ paramsObj }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.getReportById(paramsObj);

        yield put({ type: REPORT_CASELOAD_RESPONSE, payload: response.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getReportById({ paramsObj }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.viewSchoolReport(paramsObj);

        yield put({ type: GET_REPORT_DETAIL, payload: response.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}


function* reportSaga() {
    yield takeLatest(ADD_SCHOOL_REPORT, addSchoolReport);
    yield takeLatest(EDIT_SCHOOL_REPORT, editSchoolReport);
    yield takeLatest(VIEW_SCHOOL_REPORT, getReport);
    yield takeLatest(GET_REPORT_CASELOAD_DETAIL, getDetailById);
    yield takeLatest(GET_REPORT_SCHOOL_ALL_DETAIL, getAllReportDetail);
    yield takeLatest(GET_REPORT_BY_ID, getReportById);
}

export default reportSaga;
