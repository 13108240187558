import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Grid, InputLabel, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import classes from './index.module.scss';
import { style } from './style'
const LightBox = ({ open, handleClose, children, title }: any) => {
    const handleCloseDrawer = () => {
        handleClose(false); // Call handleClose when the Drawer is closed
    };

    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={handleCloseDrawer} // Assign the function reference
            PaperProps={{
                sx: {
                    width: '40%'
                }
            }}
        >
            <Grid  className={classes.modalTitle}>
                {title && <Typography variant="h4" sx={{ textAlign: 'center', mt: '10px' }} >{title}</Typography>}
                <Grid ><CancelIcon sx={{fontSize:'40px',color:'#6A2382',cursor:'pointer'}} onClick={handleCloseDrawer}/></Grid>
            </Grid>
            <Box
                sx={{ width: '100%' }}
                role="presentation"
            // onClick={handleCloseDrawer}
            // onKeyDown={handleCloseDrawer}
            >
                {children}
            </Box>
        </Drawer>
    );
};

export default memo(LightBox);
