import React from 'react'
import Layout from '../components/common/Layout'

const AuthLayout = (props: any) => {
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Layout >
                    <div className="main-content">{props.children}</div>
                </Layout>
            </div>
        </React.Fragment>
    )
}

export default AuthLayout