import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { AuditTrail } = Dictionary

const Audit_Trail = {
  view: async (id: string, paramsObj: {}) => {
    return axios.get(BASE_URL + AuditTrail.view(id), { params: paramsObj })
  },
}
export default Audit_Trail
