import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CREATE_RESOURCES, RESOURCES_LISTING, RES_RESOURCES_LISTING, UPDATE_RESOURCES } from './actionType';




function* createResources({ data, getHeaders }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Resources_Management.add(data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            let params = {
                page: 1,
                search: '',
                limit: 10,
            };
            // yield new Promise(resolve => setTimeout(resolve, 2000));
            yield call(getResourcesList, { params });
            
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}



function* updateResources({ id, data, getHeaders }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Resources_Management.update(id, data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
    
            let params = {
                page: 1,
                search: '',
                limit: 10,
            };
            // yield new Promise(resolve => setTimeout(resolve, 2000));
            yield call(getResourcesList, { params });
            
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* getResourcesList({params}:any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Resources_Management.view(params);

        if (response.status === 201 || response.status === 200) {
            yield put({ type: RES_RESOURCES_LISTING, payload: response?.data })
    
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}




function* resourcesSaga() {
    yield takeLatest(CREATE_RESOURCES, createResources);
    yield takeLatest(RESOURCES_LISTING, getResourcesList);
    yield takeLatest(UPDATE_RESOURCES, updateResources);

     

}

export default resourcesSaga;
