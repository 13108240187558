import AddReferral from './Referral/addForm'
import Referral from './Referral'
import AddReport from './Report/addForm'
import Dashboard from './Dashboard'
import AdminAcounts from './UserManagement/adminAcounts'
import Listing from './UserManagement/listing'
import Caseload from './ManageCaseLoad'
import CreateCaseload from './ManageCaseLoad/CreateCaseload'
import ReferralCaseload from './Referral/ReferralCaseLoad'
import ReferralView from './Referral/ReferralView'
import RedFlag from './ManageCaseLoad/Redflag'
import Resources from './Resources'
import SchoolReport from './SchoolReport/addForm'
import AuditTrail from './AuditTrail'
const Pages = {
  Dashboard,
  Referral,
  Resources,
  AddReport,
  AddReferral,
  AdminAcounts,
  Listing,
  Caseload,
  CreateCaseload,
  ReferralCaseload,
  ReferralView,
  RedFlag,
  SchoolReport,
  AuditTrail,
}
export default Pages
