import React, { memo, useEffect, useRef, useState } from 'react'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        border: "1px solid #E7E7E7",
        borderRadius: "60px",
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '35ch',
        },
    },
}));

const Index = ({ searchCallBack, placeholder, searchValue }: { searchCallBack: (e: string) => void, placeholder?: string, searchValue: string }) => {
    const [localValue, setLocalValue] = useState(searchValue);
    const isTyping = useRef(false);
    const debounceRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!isTyping.current) {
            setLocalValue(searchValue);
        }
    }, [searchValue]);

    const handleSearch = (e: string) => {
        const trimmedValue = e.trim();
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                searchCallBack(trimmedValue);
            }, 1000);
    };

    useEffect(() => {
        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
        };
    }, []);

    return (
        <Grid>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder={placeholder || 'Search...'}
                    value={localValue}
                    onChange={(e) => {
                        isTyping.current = true;
                        setLocalValue(e.target.value);
                        handleSearch(e.target.value);
                    }}
                    onBlur={() => isTyping.current = false}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
        </Grid>
    );
};

export default memo(Index);
