import React from "react";
import Select from "react-select";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import classes from "./index.module.scss";
import { Grid, InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { style } from "./style";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectComponent = ({
  className,
  placeholder,
  options,
  value,
  onChange,
  isMulti,
  menuIsOpen,
  isDisabled,
  defaultValue,
  label,
  htmlFor,
  errors,
  disableCloseOnSelect,
  ref,
  autoFocus,
  isOptionDisabled,
  onInputChange,
  isSearchable,
  openURL,
  onsidebyside,
}: any) => {
  const handleChange = (option: any) => {
    onChange(option);
  };

  const handleInputChange = (option: any) => {
    onInputChange(option);
  };


  return (
    <>
      <Grid className="">
        {label && (
          <InputLabel sx={style.label_color} htmlFor={htmlFor}>
            {label}
          </InputLabel>
        )}
        <Autocomplete
          disabled={isDisabled && options[0].isDisabled}
          ref={ref}
          // filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
          )}
          className={`${classes.multi_select}  cursor-pointer  ${className} `}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => {
            const label = option?.label || "";
            const isFirstOption = option?.code_number === "placeholder";

            // Handle rendering based on whether it's the first option or not
            if (isFirstOption) {
              return (
                <li
                  {...props}
                  style={{
                    cursor: option?.isDisabled ? "not-allowed" : "pointer",
                    opacity: option?.isDisabled ? 0.5 : 1,
                    pointerEvents: option?.isDisabled ? "none" : "auto",
                  }}
                >
                  {label}
                </li>
              );
            }

            // Split the label into two parts for other options
            const words = label.split(" , ");
            const firstWord = words[0] || "";
            const secondWord = words.slice(1).join(" ") || "";

            return (
              <li
                {...props}
                style={{
                  cursor: option.isDisabled ? "not-allowed" : "pointer",
                  opacity: option.isDisabled ? 0.5 : 1,
                  pointerEvents: option.isDisabled ? "none" : "auto",
                }}
              >
                {onsidebyside ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span className={classes.leftAlign}>{firstWord}</span>
                    <span className={classes.rightAlign}>{secondWord}</span>
                  </div>
                ) : (
                  <span>{label}</span>
                )}
                {isMulti && !option.isDisabled && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
              </li>
            );
          }}
          //   style={{ width: 500 }}
          defaultValue={defaultValue}
          value={value}
          onChange={(e, value) => handleChange(value)}
          onInputChange={(e, value) => handleInputChange(value)}
          options={options}
          multiple={isMulti}
          disableCloseOnSelect={disableCloseOnSelect}
        />
      </Grid>
      {errors && <span className="error-text">{errors}</span>}
    </>
  );
};

export default SelectComponent;
