export const question: any = {
  personalDetails: [
    {
      question_id: 'ques1',
      question_number: 1,
      question: '[forename] Overview',
      answer: 'NESTING',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          { cid: 1, answer: '', id: 1, name: 'Name', type: "text", placeholder: 'Enter Name', fullWidth: true, col: 6 },
          { cid: 1, answer: '', id: 2, name: 'Date of Birth', type: "date", placeholder: 'Enter DOB', fullWidth: true, col: 6 },
          { cid: 1, answer: '', id: 3, name: 'Address', type: "text", placeholder: 'Enter Address', fullWidth: true, col: 6 },
          { cid: 1, answer: '', id: 4, name: 'NHS Number', type: "text", placeholder: 'Enter NHS Number', fullWidth: true, col: 6 },
          { cid: 1, answer: '', id: 5, name: 'GP Details', type: "textArea", placeholder: 'Enter GP Details', multiline: true, fullWidth: true, col: 12, rows: 4 },
          {
            cid: 1, answer: '', id: 6, name: 'Sex', type: "radio", placeholder: '', options:
              [
                { id: 1, name: 'Male' },
                { id: 2, name: 'Female' },
                { id: 3, name: 'Other' },

              ]
          },

        ],
      },
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques2',
      question_number: 2,
      question:
        'Other Overview',
      answer: 'NESTING',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          { cid: 2, answer: '', id: 1, name: 'Name', type: "text", placeholder: 'Enter Name', fullWidth: true, col: 6 },
          { cid: 2, answer: '', id: 2, name: 'Contact Number', type: "number", placeholder: 'Enter Contact Number', fullWidth: true, col: 6 },
          { cid: 2, answer: '', id: 3, name: 'Email Address', type: "email", placeholder: 'Enter Email', fullWidth: true, col: 6 },
          { cid: 2, answer: '', id: 4, name: 'Address', type: "text", placeholder: 'Enter Address', fullWidth: true, col: 6 },

        ],
      },
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques3',
      question_number: 3,
      question: "Is [forename] gender the same as the sex they were registered at birth?",
      answer: '',
      type: 'radio',
      nesting: false,
      nestingObj: {},
      option: ['Yes', 'No'],
      isRequired: true,
    },
    {
      question_id: 'ques4',
      question_number: 4,
      question: 'How does [forename] like to be addressed and what pronouns are preferred?',
      answer: '',
      type: 'text',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques5',
      question_number: 5,
      question: 'Please summarise your main concerns',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques6',
      question_number: 6,
      question: 'Are there concerns at home/ at school or in both situations?',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques7',
      question_number: 7,
      question: 'How old was [forename] when you became concerned?',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: ' ',
      isRequired: true,
    },
    {
      question_id: 'ques8',
      question_number: 8,
      question: 'In what way do you hope this assessment will help [forename]?',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: ' ',
      isRequired: true,
    },
    {
      question_id: 'ques9',
      question_number: 9,
      question: 'Is there a previous diagnosis we should be aware of? (including ones done privately)',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: ' ',
      isRequired: true,
    },
  ],

  familyHistory: [
    {
      question_id: 'ques10',
      question_number: 10,
      question: 'Who is in [forename] ’s close family?',
      answer: '',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          { cid: 1, answer: '', id: 0, name: 'Name', type: "text", placeholder: 'Enter Name', fullWidth: true, col: 3 },
          { cid: 1, answer: '', id: 0, name: 'Age', type: "number", placeholder: 'Age', fullWidth: true, col: 2 },
          { cid: 1, answer: '', id: 0, name: 'Relationship', type: "text", placeholder: 'Relationship to young person', fullWidth: true, col: 3 },
          { cid: 1, answer: '', id: 0, name: 'Gender', type: "radio", placeholder: '', options: [{ id: 1, name: 'Male' }, { id: 2, name: 'Female' }, { id: 3, name: 'Other' }], col: 3 },
          { cid: 1, answer: '', id: 0, name: 'X', type: "button", col: 1, jsonStyle: 'onlybutton' },

        ],
        'Action': [
          { cid: 1, answer: '', id: 6, name: 'Add Family Member', type: "button", fullWidth: true, col: 12, jsonStyle: 'onlybutton' },
        ]
      },
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques11',
      question_number: 11,
      question: "Does any family member have any of the following conditions?",

      answer: '',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          {
            cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: 'Neurological Disease', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]

          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Learning difficulties', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'ADHD', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Autistic Spectrum Disorder', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Mental Health disorder/ concerns', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Other significant health issue', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          { cid: 1, answer: '', id: 5, name: 'More Details', type: "textArea", placeholder: 'More Details', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


        ],
      },
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques12',
      question_number: 12,
      question: "Developmental milestones",
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    }
  ],

  communication: [

    {
      question_id: 'ques13',
      question_number: 13,
      question: "Please describe any speech and language difficulty [forename] is experiencing now or has had in the past",
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques14',
      question_number: 14,
      question:
        // `Please describe [forename]’s communication. Comment on who, how and why they communicate – for example: to express needs, to give information, to share experiences, to have a to and fro conversation. 
        // If [forename] is non-speaking, please state what methods they do use.?`,
        `Please describe [forename]’s communication. Comment on who they can communicate with, how and why they communicate – for example: to express needs, to give information, to share experiences, to have a two-way conversation. If [forename] is non-speaking, please state what methods they do use.?`,
      answer: '',
    type: 'textArea',
    nesting: false,
    nestingObj: {},
    option: '',
    isRequired: true,
    },
  {
    question_id: 'ques15',
    question_number: 15,
    question:
        'Please describe how [forename] responds to instructions and if you have any concerns around their listening.',
    answer: '',
    type: 'textArea',
    nesting:false,
    nestingObj: { },
    option: '',
    isRequired: true,
  },
  {
    question_id: 'ques16',
    question_number: 16,
    question: "Please describe how [forename] uses non-verbal communication. For example – gestures, eye contact, facial expressions, tone of voice.",
    answer: '',
    type: 'textArea',
    nesting:false,
    nestingObj: { },
    option: '',
    isRequired: true,
  }
  ],

socialInteraction: [
  {
    question_id: 'ques17',
    question_number: 17,
    question: 'How does [forename] get on with other members of the family? (e.g. sharing interests, understanding feelings of others)',
    answer: '',
    type: 'textArea',
    nesting: false,
    nestingObj: {},
    option: '',
    isRequired: true,
  },
  {
    question_id: 'ques18',
    question_number: 18,
    question: "How does [forename] get on with other children/young people? (e.g. making and keeping friends, showing concerns for others and their feelings)",
    answer: '',
    type: 'textArea',
    nesting: false,
    nestingObj: {},
    option: '',
    isRequired: true,
  }
],

  playImagination: [

    {
      question_id: 'ques19',
      question_number: 19,
      question:
        'What does [forename] like to play with or how do they spend their time?',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques20',
      question_number: 20,
      question: "Does [forename]",

      answer: '',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          {
            cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: 'Play with toys in the way they are intended', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]

          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Use pretend and imaginary play (e.g. playing a role like a teacher, feeding a baby or a parent cooking etc)', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          { cid: 1, answer: '', id: 5, name: 'More Details', type: "textArea", placeholder: 'More Details', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


        ],
      },
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques21',
      question_number: 21,
      question: "Has [forename] got any focused interests",

      answer: '',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          {
            cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]

          },
          { cid: 1, answer: '', id: 5, name: 'More Details', type: "textArea", placeholder: 'Please give details of any focussed interests that [forename] may have: ', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


        ],
      },
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques22',
      question_number: 22,
      question: 'Please outline any routines that [forename] shows a strong preference for or has to follow: ',
      answer: '',
      type: 'textArea',
      nesting: false,
      nestingObj: {},
      option: '',
      isRequired: true,
    },

    {
      question_id: 'ques23',
      question_number: 23,
      question: "Does [forename]",

      answer: '',
      type: 'form1',
      nesting: true,
      nestingObj: {
        "onetype": [
          {
            cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: 'Engage in repetitive behaviours or rituals (doing the same thing in a certain way?)', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]

          },
          {
            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Cope with minor changes in routine?', type: "radio", placeholder: '', col: 12, options: [
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' }]
          },
          { cid: 1, answer: '', id: 5, name: 'Please describe:', type: "textArea", placeholder: 'Please describe:', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


        ],
      },
      option: '',
      isRequired: true,
    },
  ],

    sensory: [
      {
        question_id: 'ques24',
        question_number: 24,
        question: "Have you got concerns about how [forename] responds to noise?",

        answer: '',
        type: 'form1',
        nesting: true,
        nestingObj: {
          "onetype": [
            {
              cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' }]

            },
            { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please describe how [forename] responds to noise (E.g. covers ears; slow to respond when you speak to them; gets easily distracted; gets distressed, makes loud noises or hums) ', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


          ],
        },
        option: '',
        isRequired: true,
      },
      {
        question_id: 'ques25',
        question_number: 25,
        question: "Have you got concerns about how [forename] responds to touch?",

        answer: '',
        type: 'form1',
        nesting: true,
        nestingObj: {
          "onetype": [
            {
              cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' }]

            },
            { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please describe how [forename] responds to touch (E.g. dislikes messy play; has difficulty touching or wearing certain materials; reacts in unexpected ways when someone touches them) ', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


          ],
        },
        option: '',
        isRequired: true,
      },
      {
        question_id: 'ques26',
        question_number: 26,
        question: "Have you got concerns about how [forename] responds to movement? ",

        answer: '',
        type: 'form1',
        nesting: true,
        nestingObj: {
          "onetype": [
            {
              cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' }]

            },
            { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please describe how [forename] responds to movement (E.g. flaps hands and enjoys it; dislikes swings and slides; difficulty climbing steps; often fidgets or bounces, walks on tiptoes) ', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


          ],
        },
        option: '',
        isRequired: true,
      },
      {
        question_id: 'ques27',
        question_number: 27,
        question: "Have you got concerns about how [forename] responds to taste or smell? ",

        answer: '',
        type: 'form1',
        nesting: true,
        nestingObj: {
          "onetype": [
            {
              cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' }]

            },
            { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please describe how [forename] responds to taste and smell (E.g. avoids certain tastes, textures or smells; eats a small range of food; prefers food with bold textures and flavours) ', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


          ],
        },
        option: '',
        isRequired: true,
      },
      {
        question_id: 'ques28',
        question_number: 28,
        question: "Have you got concerns about how [forename] responds to light?  ",

        answer: '',
        type: 'form1',
        nesting: true,
        nestingObj: {
          "onetype": [
            {
              cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' }]

            },
            { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please describe how [forename] responds to light (E.g. enjoys looking at things move, enjoys moving or flickering light, avoids or is distressed by bright lights)', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


          ],
        },
        option: '',
        isRequired: true,
      },
      {
        question_id: 'ques29',
        question_number: 29,
        question: 'Is [forename] able to recognise and label feelings in their body? ',
        answer: '',
        type: 'form1',
        nesting: true,
        nestingObj: {
          "onetype": [
            {
              cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' }]

            },
            { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please 500 Characters', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


          ],
        },
        option: '',
        isRequired: true,
      },
      {
        question_id: 'ques30',
        question_number: 30,
        question: "Please describe any other sensory seeking behaviours that [forename] enjoys:",
        answer: '',
        type: 'textArea',
        nesting: false,
        nestingObj: {},
        option: '',
        isRequired: true,
      },
      // {
      //   question_id: 'ques31',
      //   question_number: 31,
      //   question: "Have you got concerns about how [forename] responds to movement?",
      //   answer: '',
      //   type: 'radio',
      //   nesting:false,
      //   nestingObj:{},
      //   option: ['Yes','No'],
      //   isRequired: true,
      // },
      {
        question_id: 'ques32', //question add after some time
        question_number: 32,
        question: 'Please describe any other sensory avoiding behaviours [forename] shows',
        answer: '',
        type: 'textArea',
        nesting: false,
        nestingObj: {},
        option: '',
        isRequired: true,
      }
    ],

      motorMannerisms: [
        {
          question_id: 'ques33',
          question_number: 33,
          question: `Please outline any repetitive/unusual body movements that [forename] engages in (e.g walks on their tiptoes or in an unusual way; likes to spin around more than other children; flaps their hands; bounces on their feet when excited; staring blankly at objects, collecting unusual items; attachment to objects)? `,
          answer: '',
          type: 'textArea',
          nesting: false,
          nestingObj: {},
          option: '',
          isRequired: true,
        }
      ],

        attentionActivity: [
          {
            question_id: 'ques34',
            question_number: 34,
            question: "Blurts out answers without waiting for questions to be finished",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques35',
            question_number: 35,
            question: "Is overbearing and loud while playing with peers",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques36',
            question_number: 36,
            question: "Takes actions without thinking of the consequences",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques37',
            question_number: 37,
            question: 'Acts then instantly says they didn’t mean to',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques38',
            question_number: 38,
            question: 'Difficulty staying on task in the class or in play',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques39',
            question_number: 39,
            question: 'Disturbs others when playing or working',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques40',
            question_number: 40,
            question: 'Has ‘careless mistakes’ or inaccuracies in work',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques41',
            question_number: 41,
            question: "Gets out of their seat when not expected",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques42',
            question_number: 42,
            question: 'Climbs and jumps when being still is expected',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques43',
            question_number: 43,
            question: 'Fidgets and squirms',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques44',
            question_number: 44,
            question: "Is always ‘on the go’",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques45',
            question_number: 45,
            question:
              "Difficulty listening to teaching part of lesson/assembly",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques46',
            question_number: 46,
            question:
              "Avoids or dislikes activities which require mental effort",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques47',
            question_number: 47,
            question: "Doesn’t finish tasks",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques48',
            question_number: 48,
            question:
              'Finds it difficult to start tasks (even ones they could easily do)',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques49',
            question_number: 49,
            question:
              'Is forgetful during tasks',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques50',
            question_number: 50,
            question:
              "Often loses items",
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques51',
            question_number: 51,
            question:
              'Can not get organised with equipment needed',
            answer: '',
            type: 'radio',
            nesting: false,
            nestingObj: {},
            option: ['Never', 'Sometimes', 'Always', 'N/A'],
            isRequired: true,
          },
          {
            question_id: 'ques52',
            question_number: 52,
            question: "Any comments you would like to add with respect to the attention and activity levels above?",
            answer: '',
            type: 'textArea',
            nesting: false,
            nestingObj: {},
            option: '',
            isRequired: true,
          }
        ],

          birthDetails: [
            {
              question_id: 'ques53',
              question_number: 53,
              question: "Were there any concerns about mum’s health during the pregnancy?",

              answer: '',
              type: 'form1',
              nesting: true,
              nestingObj: {
                "onetype": [
                  {
                    cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                      { id: 1, name: 'Yes' },
                      { id: 2, name: 'No' },
                      { id: 2, name: 'Not Sure' },
                    ]

                  },
                  { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please describe', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                ],
              },
              option: '',
              isRequired: true,
            },
            {
              question_id: 'ques54',
              question_number: 54,
              question: "Did mum take any medication during the pregnancy?",

              answer: '',
              type: 'form1',
              nesting: true,
              nestingObj: {
                "onetype": [
                  {
                    cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                      { id: 1, name: 'Yes' },
                      { id: 2, name: 'No' },
                      { id: 2, name: 'Not Sure' },
                    ]

                  },
                  { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'What medication was taken?', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                ],
              },
              option: '',
              isRequired: true,
            },
            {
              question_id: 'ques55',
              question_number: 55,
              question: "How long was the pregnancy in weeks (full-term is 37 to 40 weeks).",
              answer: '',
              type: 'text',
              nesting: false,
              nestingObj: {},
              option: '',
              isRequired: true,
            },
            {
              question_id: 'ques56',
              question_number: 56,
              question: "Do you know [forename] ’s birth weight?",

              answer: '',
              type: 'form1',
              nesting: true,
              nestingObj: {
                "onetype": [
                  {
                    cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                      { id: 1, name: 'Yes' },
                      { id: 2, name: 'No' }
                    ]

                  },
                  { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'What was [forename] ’s birth weight?', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                ],
              },
              option: '',
              isRequired: true,
            },
            {
              question_id: 'ques57',
              question_number: 57,
              question: "Any history of post-natal depression?",
              answer: '',
              type: 'radio',
              nesting: false,
              nestingObj: {},
              option: ['Yes', 'No'],
              isRequired: true,
            },
            {
              question_id: 'ques58',
              question_number: 58,
              question: "How was [forename] delivered? Please tick all that apply",

              answer: '',
              type: 'form1',
              nesting: true,
              nestingObj: {
                "onetype": [
                  {
                    cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                      { id: 1, name: 'Unassisted vaginal birth' },
                      { id: 2, name: 'C-Section' },
                      { id: 3, name: 'Ventouse/ Forceps' },
                      { id: 4, name: 'Not Sure' }

                    ]

                  },
                  { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Comments', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                ],
              },
              option: '',
              isRequired: true,
            },
            {
              question_id: 'ques59',
              question_number: 59,
              question: "Did [forename] require any after birth care at or after delivery? please tick all that apply:",

              answer: '',
              type: 'form1',
              nesting: true,
              nestingObj: {
                "onetype": [
                  {
                    cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                      { id: 1, name: 'Resuscitation needed' },
                      { id: 2, name: 'Admitted to special care' },
                      { id: 3, name: 'Feeding difficulties' },
                      { id: 4, name: 'Not Sure' },
                      { id: 5, name: 'None' }

                    ]

                  },
                  { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Comments', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                ],
              },
              option: '',
              isRequired: true,
            },
            // {
            //   question_id: 'ques56',
            //   question_number: 56,
            //   question: " How was [forename] delivered? Please tick all that apply",
            //   answer: '',
            //   type: 'radio',
            //   nesting:false,
            //   nestingObj:{},
            //   option: ['Normal','C-Section','Ventouse/ Forceps','Not Sure'],
            //   isRequired: true,
            // },

            // {
            //   question_id: 'ques60',
            //   question_number: 60,
            //   question: "Did [forename] require any after birth care at or after delivery? please tick all that apply:",
            //   answer: '',
            //   type: 'radio',
            //   nesting:false,
            //   nestingObj:{},
            //   option: ['Resuscitation needed','Admitted to special care','Feeding difficulties','Not sure','None'],
            //   isRequired: true,
            // }
          ],
            // earlyDevelopement: [
            //   {
            //     question_id: 'ques60',
            //     question_number: 60,
            //     question: "Were any of the following areas of [forename] ’s development of concern after birth please tick all that apply",
            //     answer: '',
            //     type: 'form2',
            //     nesting:true,
            //     nestingObj:{
            //       "onetype": [
            //           {cid:58,answer:'',id:1,name:'development_areas',type:"checkbox",placeholder:'',options:
            //           [{id:1,name:'Skills such as sitting walking or running'},
            //               {id:2,name:'Skills such as picking up and handling toys'},
            //               {id:3,name:'Speech regression (Was there a time when [forename] spoke more or better than they currently do?)'},
            //               {id:4,name:'Dressing and toileting'}, 
            //               {id:5,name:'Hearing'},
            //               {id:6,name:'Eyesight'}]
            //           },
            //           {cid:58,answer:'',id:5,name:'More Details',type:"textArea",placeholder:'More Details', multiline:true,fullWidth:true,col:12,rows:4,jsonStyle:'chkmore'},

            //       ],
            //   },
            //     option: '',
            //     isRequired: true,
            //   },
            //   {
            //     question_id: 'ques61',
            //     question_number: 61,
            //     question: 'Please comment on any concerns about early development ',
            //     answer: '',
            //     type: 'textArea',
            //     nesting:false,
            //     nestingObj:{},
            //     option: '',
            //     isRequired: true,
            //   }
            // ],
            education: [
              {
                question_id: 'ques60',
                question_number: 60,
                question: "Name of Preschool/nursery or education setting attended (state if the CYP is home educated)",
                answer: '',
                type: 'text',
                nesting: false,
                nestingObj: {},
                option: '',
                isRequired: true,
              },
              {
                question_id: 'ques61',
                question_number: 61,
                question: 'Please describe difficulties [forename] experienced during their preschool, nursery or primary or secondary school years if applicable? (Bullying, running away from school, social isolation, poor school attendance, exclusions etc.) ',
                answer: '',
                type: 'textArea',
                nesting: false,
                nestingObj: {},
                option: '',
                isRequired: true,
              },
              {
                question_id: 'ques62',
                question_number: 62,
                question: 'Please describe any extra support [forename] received or is receiving at preschool nursery, primary or secondary school:',
                answer: '',
                type: 'textArea',
                nesting: false,
                nestingObj: {},
                option: '',
                isRequired: true,
              }

            ],
              mental: [
                {
                  question_id: 'ques63',
                  question_number: 63,
                  question: "Please tick against any concerns you have about [forename] ’s emotional well-being:",
                  answer: '',
                  type: 'form2',
                  nesting: true,
                  nestingObj: {
                    "onetype": [
                      {
                        cid: 58, answer: '', id: 1, name: 'development_areas', type: "checkbox", placeholder: '', options:
                          [

                            { id: 1, name: 'Anxiety' },
                            { id: 2, name: 'Hyperactivity' },
                            { id: 3, name: 'Mood Swings' },
                            { id: 4, name: 'Low Mood' },
                            { id: 5, name: 'Bereavement' },
                            { id: 6, name: 'Impulsivity' },
                            { id: 7, name: 'Fears or phobias' },
                            { id: 8, name: 'Hallucinations' },
                            { id: 9, name: 'Eating Disorder' },
                            { id: 10, name: 'Self-Harm' },
                            { id: 11, name: 'Short Attention span' },
                            { id: 12, name: 'Obsessive Compulsive Behaviours' },
                            { id: 13, name: 'School attendance issues' },
                            { id: 14, name: 'Anger or aggression' },
                            { id: 15, name: 'Domestic Violence' },
                            { id: 16, name: 'Criminal activity/ antisocial behaviours' },
                            { id: 17, name: 'Involvement with Youth Offending Team' }

                          ]
                      },
                      { cid: 58, answer: '', id: 5, name: 'More Details', type: "textArea", placeholder: 'More Details', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'chkmore' },

                    ],

                  },
                  option: '',
                  isRequired: true,
                },

                {
                  question_id: 'ques64',
                  question_number: 64,
                  question: "Has [forename] ever had treatment (including hospitalisation) by, or is currently seeing, a psychiatrist, psychologist, therapist, or counsellor?",

                  answer: '',
                  type: 'form1',
                  nesting: true,
                  nestingObj: {
                    "onetype": [
                      {
                        cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: '', type: "radio", placeholder: '', col: 12, options: [
                          { id: 1, name: 'Yes' },
                          { id: 2, name: 'No' }
                        ]

                      },
                      { cid: 1, answer: '', id: 5, name: 'Details', type: "textArea", placeholder: 'Please give the following details: Nature of the concerns; start and end date of support; where seen and clinician’s name; type of support, for example: counselling, play therapy, cognitive behaviour therapy, group work, family work, parent support and advice.', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                    ],
                  },
                  option: '',
                  isRequired: true,
                },
              ],
                previousAssessments : [
                  {
                    question_id: 'ques65',
                    question_number: 65,
                    question: "Please indicate if [forename] has had any of the following assessments? Please attach copies of any reports and information on support provided. if available",
                    answer: '',
                    type: 'form2',
                    nesting: true,
                    nestingObj: {
                      "onetype": [
                        {
                          cid: 58, answer: '', id: 1, name: 'development_areas', type: "checkbox", placeholder: '', options:

                            [{ id: 0, name: 'None' },
                            { id: 1, name: 'Paediatric developmental assessment' },
                            { id: 2, name: 'Clinical psychological assessment' },
                            { id: 3, name: 'CAMHS assessment' },
                            { id: 4, name: 'Health visitor' },
                            { id: 5, name: 'SEN Specialist Advice and Support \Service' },
                            { id: 6, name: 'Social Services including CIN ([forename] in Need) and CP ([forename] Protection)' },
                            { id: 7, name: 'Families First/ Intensive Family Support' },
                            { id: 8, name: 'Educational psychological assessment' },
                            { id: 9, name: 'Speech and language assessment' },
                            { id: 10, name: 'Occupational Therapy assessment' },
                            { id: 11, name: 'Special Needs Health visitor' },
                            { id: 12, name: 'Early Years SEN team or Communication and Autism Team (advisory teachers)' },
                            { id: 13, name: 'School support including SENCO, TAC (Team Around the child), parent support, counselling, circle of friends, social support, behaviour Pupil Support Base' },
                            { id: 14, name: 'CAMHS Step 2 and Specialist CAMHS' },
                            { id: 15, name: 'Angels/Add-vance/Space/other voluntary agency' },
                            { id: 16, name: 'Other- Please specify (including in the NHS, Independent or charity sector)' }

                            ]
                        },

                      ],
                    },
                    isRequired: true,
                  },
                ],
                  informationSharing : [
                    {
                      question_id: 'ques66',
                      question_number: 66,
                      question: "Information about [forename] may be shared with other teams and agencies (e.g. Education services, Children’s Centres and social care) in order to identify the most appropriate support.",

                      answer: '',
                      type: 'form1',
                      nesting: true,
                      nestingObj: {
                        "onetype": [
                          {
                            cid: 17, answer: '', id: 1, jsonStyle: 'radioInline', name: 'Does the parent/carer consent to this referral', type: "radio", placeholder: '', col: 12, options: [
                              { id: 1, name: 'Yes' },
                              { id: 2, name: 'No' }]

                          },
                          {
                            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Does [forename] consent to this referral', type: "radio", placeholder: '', col: 12, options: [
                              { id: 1, name: 'Yes' },
                              { id: 2, name: 'No' }]
                          },
                          {
                            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Is there consent for enquiry/onward referral to other agencies?', type: "radio", placeholder: '', col: 12, options: [
                              { id: 1, name: 'Yes' },
                              { id: 2, name: 'No' }]
                          },
                          {
                            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Is there consent to contact school?', type: "radio", placeholder: '', col: 12, options: [
                              { id: 1, name: 'Yes' },
                              { id: 2, name: 'No' }]
                          },
                          {
                            cid: 17, answer: '', id: 2, jsonStyle: 'radioInline', name: 'Is there consent to contact [forename] whilst at school?', type: "radio", placeholder: '', col: 12, options: [
                              { id: 1, name: 'Yes' },
                              { id: 2, name: 'No' }]
                          },
                          { cid: 1, answer: '', id: 5, name: 'More Details', type: "textArea", placeholder: 'More Details', multiline: true, fullWidth: true, col: 12, rows: 4, jsonStyle: 'more' },


                        ],
                      },
                      option: '',
                      isRequired: true,
                    },
                    {
                      question_id: 'ques67',
                      question_number: 67,
                      question: "Who completed this form?",
                      answer: '',
                      type: 'radio',
                      nesting: false,
                      nestingObj: {},
                      option: ['Parent/Career', '[forename]', 'Parent/Carer and [forename] together'],
                      isRequired: true,
                    },
                    {
                      question_id: 'ques68',
                      question_number: 68,
                      question: "Select Date",
                      answer: '',
                      type: 'date',
                      nesting: false,
                      nestingObj: {},
                      option: '',
                      isRequired: true,
                    },
                  ]
  
}
// 