const UserManagement = {
  add: () => 'user',
  module: () => 'modules',
  user: () => 'user',
  openUser: () => 'referral/data',
  userById: (id: string) => `user/${id}`,
  reActivationMailById: (id: string) => `user/${id}/resend-activation-email`,
  changeStatus: (id: string) => `user/${id}/change-status`,
  deleteUser : (id: string) => `user/${id}`,
  updateEmail:() => 'user/update-email',
  verifyEmail:() => 'user/verify/email-change'
}
export default UserManagement
