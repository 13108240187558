export const style = {
  non_verified_button: {
    textTransform: 'none',
    color: '#656565',
    borderColor: '#656565',
    marginRight:'1rem',
  },

  font_custom_style: { fontSize: '14px', color: '#444C55' },
  card_custom:{ borderRadius: "10px", height: "100%",maxHeight:'400px',minHeight:'290px',position:'relative' },
  label_color: {
    color: '#212B36',
    fontFamily: 'poppins',
    marginBottom: '0.5rem',
    fontWeight: 600,
    textTransform:'none'
  },
}
