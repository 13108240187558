import React from "react";
import withAccordion from "./withAccordian";
import { WithAccordionProps } from "./withAccordionProps";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, Typography } from "@mui/material";
import { styles } from "./style";

interface AccordionProps {
  title: string;
  content: any;
}

const Accordion: React.FC<AccordionProps & WithAccordionProps> = ({
  title,
  content,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <Box>
      <Box onClick={toggleAccordion} sx={styles.accordianHeader}>
        <Typography sx={styles.title}>{title}</Typography>
        {isOpen ? (
          <KeyboardArrowUpIcon style={styles.iconStyle} />
        ) : (
          <KeyboardArrowDownIcon style={styles.iconStyle} />
        )}
      </Box>
      {isOpen &&   <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box sx={styles.contentBody}>{content}</Box>
      </Collapse>}
    </Box>
  );
};

export default withAccordion(Accordion);
