import {
  REFERRAL_RESPONSE,
  GP_LIST_RESPONSE,
  REFERRAL_CASELOAD_RESPONSE,
  GET_REFERRAL_ALL_RESPONSE,
  REFERRAL_FULL_RESPONSE,
  CANCEL_REFERRAL_REQ,
  DASHBOARD_RESPONSE,
  SCHOOL_RESPONSE,
} from "./actionType";

let initialValues = {
  referralList: [],
  referralFullList: [],
  referralCaseload: {},
  referralAllDetail: {},
  gpList: [],
  dashboardReport: [],
  allschoolData: [],
};
export const referralReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case REFERRAL_RESPONSE:
      state = { ...state, referralList: action?.payload.data };
      break;
    case CANCEL_REFERRAL_REQ:
      state = { ...state, referralList: [] };
      break;
    case REFERRAL_FULL_RESPONSE:
      state = { ...state, referralFullList: action?.payload.data };
      break;
    case REFERRAL_CASELOAD_RESPONSE:
      state = { ...state, referralCaseload: action?.payload.data };
      break;
    case GET_REFERRAL_ALL_RESPONSE:
      state = { ...state, referralAllDetail: action?.payload.data };
      break;
    case SCHOOL_RESPONSE:
      state = { ...state, allschoolData: action?.payload?.data };
      break;
    case GP_LIST_RESPONSE:
      state = { ...state, gpList: action?.payload.data };
      break;
    case DASHBOARD_RESPONSE:
      state = { ...state, dashboardReport: action?.payload.data };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
