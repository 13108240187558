import React from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    Grid,
} from "@mui/material";
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import classes from "./index.module.scss";

// interface CustomStepIconProps {
//     active: boolean;
//     completed: boolean;
//     stepIndex: number;
//     completeSteps: number;
//     activeStep: number;
//     // classes: {
//     //     custom_fill_color: string;
//     //     custom_non_fill_color: string;
//     //     fs_30: string;
//     // };
// }



const SideStepper = ({ activeStep, setActiveStep, completeSteps, enableStep }: any) => {
    // const [selectedOption, setSelectedOption] = React.useState("");
    const steps = [
        {
            label: "Personal Detail  ",
            label2: "Total Question 01 of 09",
        },
        {
            label: "Communication",
            label2: "Total Question 10 of 17",
        },
        {
            label: "Social Interaction",
            label2: "Total Question 18 of 24",
        },
        {
            label: "Creativity Imagination",
            label2: "Total Question 25 of 26",
        },
        {
            label: "Behaviour",
            label2: "Total Question 27 of 33",
        },
        {
            label: "Sensory",
            label2: "Total Question 34 of 40",
        },
        {
            label: "Attention And Activity Levels",
            label2: "Total Question 41 of 67",
        },
        {
            label: "Other Relevant Information",
            label2: "Total Question 68 of 74",
        },
    ];
    const CustomStepIcon: React.FC<any> = ({ active, completed, stepIndex, completeSteps, activeStep }) => {

        return (
            <>
                {completeSteps >= stepIndex ? (
                    <CheckCircleOutlineOutlinedIcon className={`${classes.custom_fill_color} ${classes.fs_30}`} />
                ) : stepIndex === activeStep ? (
                    <AdjustOutlinedIcon className={`${classes.custom_non_fill_color} ${classes.fs_30}`} />
                ) : <AdjustOutlinedIcon className={`${classes.custom_non_step_fill_color} ${classes.fs_30}`} />
                }
            </>
        );
    };
    const handleStepClick = (step: any) => {
        // Handle click event for each step
        // if (completeSteps >= step || step === activeStep) {
        if (enableStep >= step) {
            setActiveStep(step);
        }
        // }
    };
    return (
        <>
              <Grid className={`${classes.stepCard} stepperRefGlobal`} >
                <Card
                    sx={{
                        boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.05)",
                        mx: 3,
                        minHeight: "auto",
                        cursor: 'pointer',
                    }}
                >
                    <Stepper sx={{ mx: 3, marginTop: "2rem", color: '#6A2382' }}
                        activeStep={activeStep} orientation="vertical" className={classes.stepperStyle}
                    >
                        {steps.map((step: any, index: any) => (
                            <Step key={step.label} className={[
                                completeSteps >= index ? classes.custom_fill_color : index === enableStep ? classes.custom_non_fill_color : classes.custom_non_step_fill_color
                            ].join(' ')}  >

                                <StepLabel

                                    className={[
                                        completeSteps >= index ? classes.custom_fill_color : index !== enableStep ? classes.custom_non_step_fill_color : ''
                                    ].join(' ')}
                                    sx={{ cursor: 'pointer' }}
                                    StepIconComponent={(props) => 
                                    <CustomStepIcon {...props} stepIndex={index} completeSteps={completeSteps} activeStep={enableStep} 
                                    />
                                }
                                    onClick={() => handleStepClick(index)}
                                >

                                    <Typography className={[
                                        completeSteps >= index ? classes.custom_fill_color : index !== enableStep ? classes.custom_non_step_fill_color : ''
                                    ].join(' ')} >{step.label}</Typography>
                                    <Typography variant="subtitle2" >
                                        {step.label2}
                                    </Typography>

                                </StepLabel>

                            </Step>
                        ))}
                    </Stepper>
                    {/* {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 0 }}>
                            <Typography>
                                All steps completed.
                            </Typography>
                        </Paper>
                    )} */}
                </Card>
            </Grid>
        </>
    )
}

export default SideStepper