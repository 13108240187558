import { combineReducers } from "redux";
import { Login_Response } from "./auth/reducers";
import { referralReducer } from "./refferal/reducers";
import { userManagementReducer } from "./userManagement/reducers";
import { LayoutReducer, conversationIdReducer, selectedDocTabReducer, selectedTabReducer, selectedTaskTabReducer, selectedUserReducer, userListActiveReducer,refClosedReducer } from "./Layout/reducers";
import { CaseloadReducer, UploadDocuments,createConversationsReducer,chatMsgReducer,chatConversationReducer, redFlag,taskDocReducer, caseDocumentReducer, TaskListReducer, CaseLoadUserReducer,parentReducer,schoolReducer } from "./caseload/reducers";
import { ResourceDocumentReducer } from "./resources/reducers";
import { schoolReportReducer } from "./schoolReport/reducers";
import { auditTrailReducer } from "./auditTrail/reducers";


const rootReducer: any = combineReducers({
    Login_Response,
    referralReducer,
    userManagementReducer,
    CaseloadReducer,
    LayoutReducer,
    selectedTabReducer,
    UploadDocuments,
    caseDocumentReducer,
    TaskListReducer,
    CaseLoadUserReducer,
    taskDocReducer,
    parentReducer,
    ResourceDocumentReducer,
    conversationIdReducer,
    refClosedReducer,
    userListActiveReducer,
    selectedDocTabReducer,
    selectedTaskTabReducer,
    selectedUserReducer,
    schoolReducer,
    chatMsgReducer,
    chatConversationReducer,
    createConversationsReducer,
    redFlag,
    schoolReportReducer,
    auditTrailReducer,

});

export default rootReducer;
