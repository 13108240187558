import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classes from './index.module.scss';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-container': {
        '& .MuiDialog-paper': {
            minWidth: '500px!important', // Ensure the minWidth is applied
            borderRadius: '8px'
        },
    },
}));

const Modal = ({ open, handleClose, title, children, dividers, isSave, handleClick,sx }: any) => {
    const handleDialogClose = (event:any, reason:any) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      };
      
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classes.custom_style}
              
            >
                <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} id="customized-dialog-title" className={classes.modalTitle}>
                    <Typography variant="h4">{title}</Typography>
                </DialogTitle>
               { handleClose && <IconButton
                    aria-label="close"
                    className={classes.closeBtn}
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>}
                <DialogContent dividers={dividers} sx={sx}>
                    {children}
                </DialogContent>
                {isSave &&
                    <DialogActions>
                        <Button autoFocus onClick={handleClick}>
                            Save changes
                        </Button>
                    </DialogActions>}
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default React.memo(Modal)