import { useEffect, useState } from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const useGeneratePdf = (title:string) => {
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    const [logoBase64, setLogoBase64] = useState<string | null>(null);


    const fetchLogoAsBase64 = async (url: string) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            setLogoBase64(reader.result as string);
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Failed to fetch logo:", error);
          setLogoBase64(null);
        }
      };


  const generatePDF = async () => {
    const input:any = document.getElementById("pdf-content");
    const canvas = await html2canvas(input, {
        scale: 1,
        useCORS: true, // Reduce canvas resolution
    });
 
    const imgData = canvas.toDataURL("image/jpeg", 1); // Use JPEG format with 50% quality

    const pdf = new jsPDF();
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageHeight = pdf.internal.pageSize.getHeight();

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }

    pdf.save(`${title}-${currentDate} ${currentTime}`);
};

useEffect(() => {
    let loginDetailString = localStorage.getItem("loginResponse");
    let loginDetail = loginDetailString ? JSON.parse(loginDetailString) : {};
    const orgLogo = loginDetail?.organization?.logo;

    if (orgLogo) {
      fetchLogoAsBase64(orgLogo);
    }
  }, []);

  return {
    generatePDF,
    logoBase64
  };
};
