import { useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.scss";
import CommonField from "./CommonField";
import { CurrentUserData } from "../ManageCaseLoad/interface";
import { formatNHSNumber } from "../../../constants/utils";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCaseloadById } from "../../../redux/action";
import constant from "../../../constants/constant";

interface User {
  scope: string;
  // Add other properties as needed
}

interface CaseloadMember {
  user: User;
  role: string;
}

const Personal_Details = ({
  questions,
  setQuestionData,
  patientName,
  prefiled,
  isError,
  setIsError,
  setIsDisableContinue,
}: any) => {
  let QuestionNumber = 0;
  const dispatch = useDispatch();
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const id = pathParts[2];

  const setAnswerIfNotSet = (
    x: any,
    idCondition: any,
    nameCondition: any,
    value: any
  ) => {
    if ((x.id === idCondition && x.name === nameCondition) || !x.answer) {
      x.answer = value;
      x.disabled = x.answer !== "";
      if (x.name === "Sex") {
        x.value = prefiled?.patient_gender;
        x.options.forEach((option: any) => {
          if (option?.name == x?.value) {
            option.checked = true;
            option.disabled = false;
          } else {
            option.checked = false;
            option.disabled = true;
          }
        });
      }
    }
    return x;
  };

  questions?.personalDetails &&
    questions?.personalDetails[0].nestingObj.onetype.map((x: any) => {
      setAnswerIfNotSet(x, 1, "Name", prefiled?.patient_name);
      setAnswerIfNotSet(x, 2, "Date of Birth", prefiled?.patient_dob);
      setAnswerIfNotSet(x, 3, "Address", prefiled?.patient_address);
      setAnswerIfNotSet(
        x,
        4,
        "NHS Number",
        formatNHSNumber(prefiled?.nhs_number)
      );
      setAnswerIfNotSet(
        x,
        5,
        "GP Details",
        `${prefiled?.gp_name},${prefiled?.gp_address}`
      );
      setAnswerIfNotSet(x, 6, "Sex", prefiled?.patient_gender);
      return x;
    });

  const userData: CurrentUserData | null = JSON.parse(
    localStorage.getItem("loginResponse") || "null"
  );

  const otherData = prefiled?.caseload_members?.filter(
    (x: CaseloadMember) =>
      x.user.scope === constant.SCOPE.PARENT &&
      x.role === constant.ROLE.ALL_ACCESS
  );
  const usersObject = otherData?.reduce((acc: User, item: CaseloadMember) => {
    acc = item.user;
    return acc;
  }, {});

  questions?.personalDetails &&
    questions?.personalDetails[1].nestingObj.onetype.map((x: any) => {
      setAnswerIfNotSet(
        x,
        1,
        "Name",
        `${usersObject?.first_name} ${usersObject?.last_name}`
      );
      setAnswerIfNotSet(x, 3, "Email Address", usersObject?.email);
      setAnswerIfNotSet(x, 2, "Contact Number", usersObject?.phone_no);
      setAnswerIfNotSet(x, 4, "Address", usersObject?.address);

      return x;
    });

  questions?.personalDetails &&
    questions?.personalDetails?.map((x: any) => {
      x.question = x.question.replace("[forename]", patientName);
      return x;
    });

  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let temppersonalDetails = [...tempQuestions.personalDetails];
    temppersonalDetails[index].question = temppersonalDetails[
      index
    ].question.replace("[forename]", patientName);
    temppersonalDetails[index].answer = value;
    setQuestionData({ ...tempQuestions, personalDetails: temppersonalDetails });
  };

  useEffect(() => {
    dispatch(getCaseloadById(id));
    setIsError(false);
  }, []);
  return (
    <Grid container spacing={3} className={classes.Referral}>
      {questions?.personalDetails &&
        questions?.personalDetails?.map((ques: any, index: number) => (
          <>
            {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "gp_detail", ques.isRequired,ques.question_number)}  */}
            {
              <CommonField
                type={ques.type}
                index={index}
                question={ques.question.replace("[forename]", patientName)}
                answer={ques.answer}
                options={ques.option}
                key={"personalDetails"}
                required={ques.isRequired}
                question_number={QuestionNumber}
                questions={questions?.personalDetails}
                isError={isError}
                handleAnswerChange={handleAnswerChange}
                ques={ques}
                setIsDisableContinue={setIsDisableContinue}
                totalQuestion={questions?.personalDetails?.length}
              />
            }
          </>
        ))}
    </Grid>
  );
};

export default Personal_Details;
