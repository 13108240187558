import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import atoms from '../../../components/atoms';
import { useNavigate } from 'react-router-dom';

const { Button } = atoms
const RejectionPage = () => {
    const navigate = useNavigate()
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            textAlign="center"
        >
            <ErrorOutlineIcon sx={{ fontSize: 60, color: 'red' }} />
            <Typography variant="h4" sx={{ mt: 2 }}>
                Referral Rejected
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, color: 'text.secondary' }}>
                Your referral was not accepted. Please review your submission and try again.
            </Typography>
            <Button variant="outlined" sx={{ mt: 3, border: '1px solid #d74040b5', color: '#d74040b5' }} onClick={() => {
                navigate('/referral-form')
            }}>
                Retry
            </Button>
        </Box>
    );
};

export default RejectionPage;
