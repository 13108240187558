import React, {
  Suspense,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { usePDF } from "react-to-pdf";
import Button from "../Button";
import "./pdf.scss";
import DownloadIcon from "@mui/icons-material/Download";
import loaderGif from "../../../assets/Images/loader.svg";
import { createSelector } from "reselect";
import logoLd from "../../../assets/Images/logso.png";
import constant from "../../../constants/constant";
import { Box } from "@mui/material";
import { setPdfLoader } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useOrgnizationLogo } from "../OrgnizationLogo";
import { useGeneratePdf } from "../Hooks/useGeneratePdf";

const ReferralView = React.lazy(() => import("../../organism/ReferralView"));

interface PdfProps {
  data: any;
  title: string;
  fname: any;
  dataUrl?:any
}

const PdfRefeeral: React.FC<PdfProps> = ({ data,dataUrl, title, fname }) => {
  const currentDate = new Date().toLocaleDateString();
  const [imageError, setImageError] = React.useState(false);
  const currentTime = new Date().toLocaleTimeString();
  const layoutReducer = (state: any) => state?.LayoutReducer;
  // const { imageSrc, placeholderImage } = useOrgnizationLogo();
  const { generatePDF,logoBase64 } = useGeneratePdf(title);
  const dispatch = useDispatch();
  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: `${title}-${currentDate} ${currentTime}`,
    page: {
      format: "letter",
      margin: 3,
      orientation: "portrait", 
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  });

  const displayOrder = [
    "referrer_detail",
    "consent",
    "reason",
    "gp_detail",
    "person_Detail",
    "safe_guarding_detail",
    "parent_carers_detail",
    "additional_parent_carers_details",
    "support_so_far",
    "digital_literacy",
  ];

  const transformData = displayOrder?.reduce((acc: any, key) => {
    acc[key] = data?.[key] ?? null;
    return acc;
  }, {});

  const layoutURLData = createSelector(layoutReducer, (state) => ({
    loaderPdf: state.loaderPdf,
  }));
  const { loaderPdf } = useSelector(layoutURLData);

  const handleImageError = () => {
    setImageError(true);
  };

  useLayoutEffect(() => {
    if (fname === "DOWNLOAD") {
      dispatch(setPdfLoader(true));

      if (targetRef.current) {
        targetRef.current.style.display = "block";
        generatePDF();
        targetRef.current.style.display = "none";
      }
    } else if (targetRef.current) {
      targetRef.current.style.display = "none";
    }

    const timeoutId = setTimeout(() => {
      dispatch(setPdfLoader(false));
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [fname, dispatch]);


  const handleFileDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const contentType = response.headers.get("Content-Type") || "";
      const blob = await response.blob();
      const fileExtension = "pdf";
      const completeFileName = `${fileName}.${fileExtension}`;

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = completeFileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownloadClick = async () => {
    try {
      dispatch(setPdfLoader(true));
      const targetRefValue = targetRef.current;
  
      if (targetRefValue && !dataUrl.url) {
        targetRefValue.style.display = "block";
        await generatePDF();
        targetRefValue.style.display = "none"; 
      } else if (dataUrl.url) {
        const formattedDate = new Date().toISOString().replace(/[:.]/g, "-");
        handleFileDownload(dataUrl.url, `${dataUrl.unique_id} ${formattedDate}`);
      } else {
        console.error("targetRef.current is null or invalid");
      }
    } catch (error) {
      console.error("Error during PDF download:", error);
    } finally {
      dispatch(setPdfLoader(false));
    }
  };
  

  // Function to get background color based on key
  const getColorByKey = useMemo(() => {
    return (key: any) => {
      switch (key) {
        case "reason":
          return "col1";
        case "referrer_detail":
          return "col2";
        case "consent":
          return "col3";
        // Add more cases as needed
        default:
          return "pdfSection"; // Default color
      }
    };
  }, [data]);

  const getColorParentByKey = useMemo(() => {
    return (key: any) => {
      switch (key) {
        case "personalDetails":
        case "familyHistory":
          return "col_parent1";
        case "playImagination":
        case "sensory":
        case "birthDetails":
        case "earlyDevelopement":
        case "informationSharing":
        case "previousAssessments":
        case "attentionActivity":
        case "mental":
          return "col_parent2";
        case "personalDetail":
          // case 'attentionActivityLevels':
          return "col_School1";
        // Add more cases as needed
        default:
          return "pdfParentSection"; // Default color
      }
    };
  }, [data]);

  function addSpaceBeforeCapitalLetters(str: string) {
    const withSpaces = str.replace(/_/g, " ");
    const finalResult = withSpaces.replace(/([A-Z])/g, " $1").trim();
    return finalResult.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const getValue = useMemo(() => {
    return (val: any) => {
      for (const element of val) {
        if (Array.isArray(element.answer)) {
          for (const element1 of element.answer) {
            if (
              element1.name === "More Details" ||
              element1.name === "Details"
            ) {
              return "DetailsSection";
            } else if (element1.name === "X") {
              return "hiddenx";
            }
          }
        }
      }
    };
  }, [data]);

  const renderPdfContent = useMemo(() => {
    return (
      <div
        id="pdf-content"
        style={{ display: !loaderPdf ? "none" : "block" }}
        ref={targetRef}
      >
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logoLd} alt="" width={"400"} />
        </div>
        <table style={{ width: "100%", marginBottom: "1rem" }}>
          <tr style={{ width: "100%" }}>
            <td style={{ visibility: "hidden" }}></td>

            <td style={{ textAlign: "center" }}>
            {logoBase64 ? (
          <img style={{ maxWidth: "200px" }} src={logoBase64} alt="Organization Logo" />
        ) : (
          <span>Loading logo...</span>
        )}
            </td>

            <td style={{ visibility: "hidden" }}></td>
          </tr>
     
        </table>
        <Suspense fallback={<div>Loading...</div>}>
          {Object.entries(transformData || {}).map(([key, value], index) => (
            <React.Fragment key={index}>
              <ReferralView
                tab={index}
                viewMode="PDF"
                question={value}
                type={addSpaceBeforeCapitalLetters(key)}
                isParentTab={true}
                isPadding={{ padding: 0, borderRadius: 0 }}
                // setnesting={getValue(value)}
                setStylePdf={
                  title === constant.PATHWAY_STATUS.CASELOAD_CREATED &&
                  getColorByKey(key)
                    ? getColorByKey(key)
                    : title ===
                        constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED ||
                      (title ===
                        constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED &&
                        getColorParentByKey(key))
                    ? getColorParentByKey(key)
                    : null
                }
              />
            </React.Fragment>
          ))}
        </Suspense>
        {title === constant.PATHWAY_STATUS.CASELOAD_CREATED && (
          <table style={{ width: "100%", padding: "2rem 1rem " }}>
            <tr style={{ width: "100%" }}>
              <td>
                <small>What happens to your information?</small> <br />
                <small>
                  If you would liketo find out what happens to personal
                  information held aboutyou, pleaseread our privacy policy for
                  moreinformation:
                </small>
                <small>
                  {" "}
                  <a
                    href="https://www.nelft.nhs.uk/download.cfm?ver=1014"
                    target="_blank"
                  >
                    {" "}
                    https://www.nelft.nhs.uk/download.cfm?ver=1014{" "}
                  </a>
                </small>
              </td>
            </tr>
          </table>
        )}
      </div>
    );
  }, [data, generatePDF]);

  const isDownloadVisible = fname !== "REMOVEBTN" && fname !== "DOWNLOAD";

  return (
    <>
      {loaderPdf ? (
        <>
          <Box className="loadergif" component="div">
            <img src={loaderGif} alt="Loading..." />
          </Box>
        </>
      ) : (
        <>
          {isDownloadVisible && (
            <Button
              variant="outlined"
              onClick={handleDownloadClick}
              sx={{
                padding: "0px!important",
                minWidth: "44px!important",
                position: "absolute",
                right: 20,
                top: 20,
                zIndex: 9, // Material-UI uses camelCase for inline styles
              }}
            >
              <DownloadIcon />
            </Button>
          )}
          {renderPdfContent}
        </>
      )}
    </>
  );
};

export default memo(PdfRefeeral);
