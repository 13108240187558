import React, { memo, useState } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/SideBar'
import Wrapper from '../../../layout/Wrapper/Wrapper'
import { createTheme, ThemeProvider } from '@mui/material'
const Index = ({ children }: any) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleOpenDrawer = () => {
    setMobileOpen(!mobileOpen)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#6A2382',  // Replace with your desired primary color
      },
      secondary: {
        main: '#6A2382', // Replace with your desired secondary color
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <Header handleOpenDrawer={() => handleOpenDrawer()} />
          <Wrapper >
            <Sidebar   mobileOpen={mobileOpen} setMobileOpen={(e: any) => setMobileOpen(e)} />
            {children}
          </Wrapper>
        </div>
      </ThemeProvider>
    </>
  )
}

export default memo(Index)
