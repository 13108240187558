import { ADD_SCHOOL_REPORT, VIEW_SCHOOL_REPORT, GET_REPORT_CASELOAD_DETAIL, GET_REPORT_SCHOOL_ALL_DETAIL, GET_PATIENT_NAME, GET_REPORT_BY_ID, EDIT_SCHOOL_REPORT } from './actionType'

export const addSchoolReport = (payload: any, navigate: any, isEdit: boolean, caseload_Id: string) => {
    return {
        type: ADD_SCHOOL_REPORT,
        payload,
        navigate,
        isEdit,
        caseload_Id,
    }
}

export const editSchoolReport = (payload: any,schoolReportId:any, navigate: any, isEdit: boolean, caseload_Id: string) => {
    return {
        type: EDIT_SCHOOL_REPORT,
        payload,
        navigate,
        isEdit,
        schoolReportId,
        caseload_Id,
    }
}



export const viewSchoolReport = (params: any) => {
    return {
        type: VIEW_SCHOOL_REPORT,
        params

    }
}
export const currentPatient = (name: any) => {
    return {
        type: GET_PATIENT_NAME,
        payload: name,

    }
}
export const viewAllSchoolReportDetail = (params: any) => {
    return {
        type: GET_REPORT_SCHOOL_ALL_DETAIL,
        params

    }
}
export const getReportCaseloadByID = (paramsObj: any) => {

    return {
        type: GET_REPORT_CASELOAD_DETAIL,
        paramsObj
    }
}

export const getReportByID = (paramsObj: any) => {
  
    return {
        type: GET_REPORT_BY_ID,
        paramsObj
    }
}