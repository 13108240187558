const Caseload = {
  view: () => 'caseload',
  overview: (id: string) => `${id}/overview`,
  getById: (id: string) => `caseload/${id}`,
  addSystemDate: (id: string) => `caseload/${id}/systemOne`,
  getParentReport: () => `parent-report`,
  getSchoolReport: () => `school-report`,
  getRedFlag: () => `referral/red-flag`,
  postClinicalReview: (id:string) => `caseload/${id}/clinical-review`,
  deleteAdditionalParent: (id:string,userId:string) => `caseload/${id}/remove-user-from-case/${userId}`,
  redReferralsystem: (id:string) => `referral/${id}/change-systemOne-date`

}
export default Caseload
