import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { ADD_REFERRAL, VIEW_REFERRAL, REFERRAL_RESPONSE, GET_GP_LIST, GP_LIST_RESPONSE, GET_REFERRAL_CASELOAD_DETAIL, REFERRAL_CASELOAD_RESPONSE, GET_REFERRAL_ALL_DETAIL, GET_REFERRAL_ALL_RESPONSE, VIEW_FULL_REFERRAL, REFERRAL_FULL_RESPONSE, ADD_REPORT, VIEW_REPORT, REJECT_REFERRAL_BY_ID, UPDATE_REPORT, EDIT_REFERRAL, DASH_REPORT, DASHBOARD_RESPONSE, SCHOOL_LISTING } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { schoolResponse } from './action';
import constant from '../../constants/constant';

function* addReferral({ payload, navigate, isOpenReferral }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let response: any = ''
        if (isOpenReferral) {
            response = yield service.referral.addOpenReferral(payload);
        } else {
            response = yield service.referral.add(payload);
        }
        if (response.status === 201 || response.status === 200 || response.statusCode === 201 || response.statusCode === 200) {
            toast.success(response?.data?.message);
           
            if (isOpenReferral) {
                if (payload.isRejected) {
                    navigate('/rejected')
                } else {
                    navigate("/success");
                }
            } else {
                if (payload.isRejected && payload.isFinalSubmit) {
                    navigate("/manage-caseload/rejected");
                } else if (payload.isRedFlag && payload.isFinalSubmit){
                    navigate("/manage-caseload/red-flag");
                } else if (!payload.isFinalSubmit){
                    navigate("/manage-caseload/draft");
                } else if (payload.isFinalSubmit){
                    navigate("/manage-caseload/active");
                } 
                else  {
                    navigate("/manage-caseload/active");
                }
            }
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}



function* editReferral({ payload, id,navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response = yield service.referral.edit(id,payload)
        if (response.status === 201 || response.status === 200 || response.statusCode === 201 || response.statusCode === 200) {
            toast.success(response?.data?.message);

            if (payload.isRejected && payload.isFinalSubmit) {
                navigate("/manage-caseload/rejected");
            } else if (payload.isRedFlag && payload.isFinalSubmit){
                navigate("/manage-caseload/red-flag");
            } else if (!payload.isFinalSubmit){
                navigate("/manage-caseload/draft");
            } else if (payload.isFinalSubmit){
                navigate("/manage-caseload/active");
            } 
            else  {
                navigate("/manage-caseload/active");
            }
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* addReport({ payload, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.referral.addReport(payload);
        if (response.status === 201) {
            toast.success(response?.data?.message);
            navigate(`/manage-caseload/create?id=${payload.caseload_id}`);

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* updateReport({id,caseload, payload, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.referral.updateReport(id,payload);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            navigate(`/manage-caseload/create?id=${caseload}`);

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}




function* getReport({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.viewReport(params);


        if (response.status === 201) {
            yield put({ type: REFERRAL_RESPONSE, payload: response?.data })
        } else {

        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getDashboardReport({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.dashboard.view(params);


        if (response.status === 200 || response.status === 201) {
            yield put({ type: DASHBOARD_RESPONSE, payload: response?.data })
        } else {

        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getReferral({ params, type }: any): Generator<any, AxiosResponse<any>, any> {
    const controller = new AbortController();
    try {
        const response: any = yield service.referral.view(params);

        // yield put({ type: REFERRAL_RESPONSE, payload: response?.data })
        yield put(type === VIEW_REFERRAL ? { type: REFERRAL_RESPONSE, payload: response?.data } : { type: REFERRAL_FULL_RESPONSE, payload: response?.data });
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }finally {
        if (yield cancelled()) {
          controller.abort(); // Abort the API call
        }
      }
}

function* getGPDetail({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.getGPList(params);

        yield put({ type: GP_LIST_RESPONSE, payload: response?.data })
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* getUserList({ headers }: any): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: any = yield service.referral.allSchool(headers);

        yield put(schoolResponse(response.data))
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getAllReferralDetail({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.viewAllDetail(params);

        yield put({ type: GET_REFERRAL_ALL_RESPONSE, payload: response?.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getDetailById({ paramsObj }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.getReferralById(paramsObj);

        yield put({ type: REFERRAL_CASELOAD_RESPONSE, payload: response.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}


function* rejectRefferal({ id, data }: any): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: AxiosResponse<any> = yield service.referral.rejectReferral(id, data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            let params = {
                page: 1,
                search: '',
                limit: 10,
                status:constant.REFERRAL_STATUS.REJECTED
            };
            yield new Promise(resolve => setTimeout(resolve, 100));
            yield call(getReferral, { params, type: VIEW_REFERRAL });
        } else {
            toast.error(response?.data?.message);

        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* referralSaga() {
    yield takeLatest(ADD_REFERRAL, addReferral);
    yield takeLatest(EDIT_REFERRAL, editReferral);
    yield takeLatest(ADD_REPORT, addReport);
    yield takeLatest(DASH_REPORT, getDashboardReport);
    yield takeLatest(UPDATE_REPORT, updateReport);
    yield takeLatest(VIEW_REPORT, getReport);
    yield takeLatest(SCHOOL_LISTING, getUserList);
    yield takeLatest(VIEW_REFERRAL, (action) => getReferral({ ...action, type: VIEW_REFERRAL }));
    yield takeLatest(VIEW_FULL_REFERRAL, (action) => getReferral({ ...action, type: VIEW_FULL_REFERRAL }));
    yield takeLatest(GET_REFERRAL_CASELOAD_DETAIL, getDetailById);
    yield takeLatest(GET_REFERRAL_ALL_DETAIL, getAllReferralDetail);
    yield takeLatest(GET_GP_LIST, getGPDetail);
    yield takeLatest(REJECT_REFERRAL_BY_ID, rejectRefferal);

}

export default referralSaga;
