import React, { ComponentType } from 'react';

// Higher-Order Component definition
const withPdf = (WrappedComponent: ComponentType<any>) => {
    // Functional component that will wrap the given component
    const WithPdfComponent = (props: any) => {
        return <WrappedComponent {...props} />;
    };
    return WithPdfComponent;
};

export default withPdf;
