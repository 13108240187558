import React from 'react';
import {Box, Grid, Typography, Button } from '@mui/material';
import Modal from '../Modal';

const index = ({
  open,
  handleClose,
  title,
  message,
  onConfirm,
  onCancel,
  confirmLabel = "Yes",
  cancelLabel = "No",
}: {
  open: boolean;
  handleClose?: () => void;
  title: string;
  message: any;
  onConfirm: any;
  onCancel: any;
  confirmLabel?: string;
  cancelLabel?: string;
}) => {
  return (
    <Modal open={open} handleClose={handleClose} title={title}>
        <Grid sx={{ width: '100%', textAlign: 'center', py:3 }}>
          <Typography variant="body1">{message}</Typography>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper',
            p: 2
          }}
        >
 
         {confirmLabel && <Button sx={{ width: '20%',borderRadius:'2rem',minHeight:'35px' }} variant={"contained"} onClick={onConfirm}>{confirmLabel}</Button>}
          {cancelLabel && <Button sx={{ width: '20%',borderRadius:'2rem',minHeight:'35px' }} variant={"outlined"} onClick={onCancel}>{cancelLabel}</Button>}
        </Box>
    </Modal>
  );
};

export default index;
