import ConfirmationModal from "../ConfirmationModal";


const index = ({ open, onReload }:any) => (
   <ConfirmationModal
   open={open}
   title="New Update Available"
   message="Please refresh the page to get the latest version."
   onConfirm={onReload}
   confirmLabel = 'Refresh'
   cancelLabel = ''
   onCancel={()=> undefined}
 />
);

export default index;
