import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import OTPInput from "react-otp-input";
import { Box, Typography, Fade, Grid, TextField } from "@mui/material";
import Modal from "../../../components/atoms/Modal";
import { createSelector } from "reselect";
import Button from "../../../components/atoms/Button";
import { useNavigate } from "react-router";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useDispatch, useSelector } from "react-redux";
import { clearEmailResponse, updateEmail, verifyEmail } from "../../../redux/userManagement/action";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Current password is required")
    .min(6, "Password must be at least 6 characters"),
  new_email: Yup.string()
    .required("New email is required")
    .email("Invalid email address"),
});

const userReducer = (state: any) => state?.userManagementReducer;

const EmailChangeModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [otp, setOTP] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [otpSent, setOtpSent] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [timer, setTimer] = useState(10);

  const userData = createSelector(userReducer, (state) => ({
    updateEmailResponse: state.updateEmailData,
    verifyEmailResponse: state.verifyEmailData,
  }));
  const { updateEmailResponse, verifyEmailResponse } = useSelector(userData);

  const handleFormSubmit = ({
    password,
    new_email,
  }: {
    password: string;
    new_email: string;
  }) => {
    const formValues = { password, new_email };
    setValues(formValues);
    dispatch(updateEmail(formValues));
  };

  const handleResendOtp = () => {
    if (!values) return;

    setOTP("");
    dispatch(updateEmail(values));
    setRemainingTime(60);
    setIsButtonVisible(false);
  };

  const handleOtpSubmit = (otp: string) => {
    const token = updateEmailResponse?.token;
    if (token) {
      dispatch(verifyEmail({ otp, token }));
    }
  };

  useEffect(() => {
    if (updateEmailResponse?.token) {
      if (verifyEmailResponse?.status === 200) {
        setStep(3);
        setOtpSent(false);
      } else {
        setStep(2);
        setOtpSent(true);
      }
    } else {
      setStep(1);
    }

    return () => {
    setStep(1);
  }
  }, [updateEmailResponse?.token, verifyEmailResponse?.status]);

  useEffect(() => {
    let countdown: any;

    if (step === 3) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            handleClose();
            navigate("/login");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    if (step === 2) {
      countdown = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsButtonVisible(true);
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(countdown);
    };
  }, [step, handleClose, navigate]);

  return (
    <Modal
      open={open}
      handleClose={
        step === 3
          ? undefined
          : () => {
              dispatch(clearEmailResponse());
              handleClose();
            }
      }
      aria-labelledby="modal-title"
      title={
        step === 1
          ? "Update Email"
          : step === 2
          ? "Enter OTP"
          : "Redirect to Login Page"
      }
    >
      <Fade in={open}>
        <Grid sx={{ maxWidth: "500px", py: 2 }}>
          {step === 1 && (
            <Formik
              initialValues={{ password: "", new_email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <Field
                    name="new_email"
                    type="email"
                    as={TextField}
                    placeholder="Enter New Email"
                    fullWidth
                    margin="normal"
                  />
                  <br />
                  <Field
                    name="password"
                    type="password"
                    as={TextField}
                    placeholder="Enter Current Password"
                    fullWidth
                    margin="normal"
                  />
                  <br /> <br />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!(isValid && dirty)}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          )}

          {step === 2 && otpSent && (
            <Box>
              <Typography py={3} ml={"8px"} fontWeight={"500"}>
                A One-Time Password (OTP) has been sent to your updated email
                address.It will be valid till 10 min, Please enter the OTP to proceed. 
              </Typography>
              <OTPInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  fontSize: "2rem",
                  margin: "0 0.5rem",
                  borderRadius: "4px",
                  background: "rgb(106 35 130 / 24%)",
                  border: "transparent",
                }}
                renderInput={(props) => <input {...props} />}
              />

              <Box
                sx={{
                  pt: 5,
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!otp}
                  color="primary"
                  onClick={() => handleOtpSubmit(otp)}
                >
                  Proceed
                </Button>

                {isButtonVisible ? (
                  <Button
                    variant="outlined"
                    onClick={() => handleResendOtp()}
                    base="small"
                    sx={{ float: "right", padding: "1rem 2rem" }}
                  >
                    Resend
                  </Button>
                ) : (
                  <Typography
                    sx={{
                      float: "right",
                      padding: "1rem 2rem",
                      fontWeight: 500,
                      color: "#737373",
                      fontSize: "1rem",
                    }}
                  >
                    {remainingTime} seconds
                  </Typography>
                )}
              </Box>
            </Box>
          )}

          {timer > 0 && step === 3 && (
            <Box>
              <QueryBuilderIcon
                sx={{
                  my: "2rem",
                  color: "#6A2382",
                  fontSize: "80px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              />
              <Typography py={3} ml={"8px"} fontWeight={"500"}>
                Your email address has been successfully updated. You will be
                redirected to the login page in a moment.{" "}
                <span style={{ color: "#6A2382",fontWeight:'600' }}> {timer} seconds...</span> 
              </Typography>
            </Box>
          )}
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EmailChangeModal;
