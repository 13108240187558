import { useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.scss";
import CommonField from "./CommonField";

const SocialInteraction = ({
  patientName,
  questions,
  setQuestionData,
  isError,
  setIsError,
  setIsDisableContinue,
  setIsNewSchool,
}: any) => {
  let QuestionNumber =
    questions.personalDetail.length + questions.communication.length;

  const handleAnswerChange = (
    index: number,
    value: any,
    key: string,
    updatedQuestion: string,
    updatedAdditionalInfo: string,
    updatedAdditionalQuestion: string,
    updatedIsHeading: string
  ) => {
    let tempQuestions = { ...questions };
    let tempSocialInteraction = [...tempQuestions.socialInteraction];
    tempSocialInteraction[index].answer = value;

    if (tempSocialInteraction[index]) {
      if (tempSocialInteraction[index].question) {
        tempSocialInteraction[index].question = updatedQuestion;
      }
      if (tempSocialInteraction[index].additional_info) {
        tempSocialInteraction[index].additional_info = updatedAdditionalInfo;
      }
      if (
        tempSocialInteraction[index].isAdditionalStructure &&
        tempSocialInteraction[index].isAdditionalStructure.question
      ) {
        tempSocialInteraction[index].isAdditionalStructure.question =
          updatedAdditionalQuestion;
      }
    }

    const question_id = tempSocialInteraction[index].question_id;

    if (
      ["ques19", "ques20", "ques22", "ques24"].includes(question_id) &&
      value === "No"
    ) {
      tempSocialInteraction[index].isAdditionalStructure.isRequired = false;
      tempSocialInteraction[index].isAdditionalStructure.answer = "";
    } else if (
      ["ques19", "ques20", "ques22", "ques24"].includes(question_id) &&
      value === "Yes"
    ) {
      tempSocialInteraction[index].isAdditionalStructure.isRequired = true;
      tempSocialInteraction[index].isAdditionalStructure.answer = "";
    }

    setQuestionData({
      ...tempQuestions,
      socialInteraction: tempSocialInteraction,
    });
  };

  const handleAdditionalChange = (index: number, value: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.socialInteraction];
    tempOtherInformation[index].isAdditionalStructure.answer = value;
    setQuestionData({
      ...tempQuestions,
      socialInteraction: tempOtherInformation,
    });
  };

  useEffect(() => {
    setIsError(false);
  }, []);

  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.Report}>
        {questions?.socialInteraction &&
          questions?.socialInteraction?.map((ques: any, index: number) => (
            <>
              {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "person_Detail", ques.isRequired)} */}
              {
                <CommonField
                  type={ques.type}
                  index={index}
                  question={ques.question}
                  answer={ques.answer}
                  options={ques.option}
                  fileType={"socialInteraction"}
                  required={ques.isRequired}
                  question_number={QuestionNumber}
                  questions={questions?.socialInteraction}
                  isError={isError}
                  handleAnswerChange={handleAnswerChange}
                  ques={ques}
                  setIsDisableContinue={setIsDisableContinue}
                  totalQuestion={questions?.socialInteraction?.length}
                  isAdditional={ques?.isAdditional}
                  isAdditionalQuestion={ques?.isAdditionalStructure}
                  handleAdditionalChange={handleAdditionalChange}
                  patientName={patientName}
                />
              }
            </>
          ))}
      </Grid>
    </>
  );
};

export default SocialInteraction;
