import React, { useState } from 'react';
import { WithAccordionProps } from './withAccordionProps';

const withAccordion = <P extends object>(WrappedComponent: React.ComponentType<P & WithAccordionProps>) => {
  return (props: P) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <WrappedComponent
        isOpen={isOpen}
        toggleAccordion={toggleAccordion}
        {...props}
      />
    );
  };
};

export default withAccordion;