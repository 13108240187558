import CaseloadView from './CaseloadView'
import ReferralView from './ReferralView'
import OverviewCaseload from './overviewCaseload'

const organism = {
    CaseloadView,
    ReferralView,
    OverviewCaseload,
}

export default organism