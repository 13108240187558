import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Task } = Dictionary

const Task_Management = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + Task.add(), data)
  },
  view: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Task.view(), { params: paramsObj })
  },
  getById: async (id: string) => {
    return axios.get(BASE_URL + Task.getById(id))
  },
  delete: async (id: string) => {
    return axios.delete(BASE_URL + Task.delete(id))
  },
  // getTaskDocById: async (id: string) => {
  //   return axios.get(BASE_URL + Task.getTaskDocById(id))
  // },
  postById: async (id:string, data: {}) => {
    return axios.post(BASE_URL + Task.postById(id), data)
  },
//   overveiw: async (id: string) => {
//     return axios.get(BASE_URL + Task.overview(id))
//   },
  update: async (id: string, data: {}) => {
    return axios.put(BASE_URL + Task.update(id), data)
  },
}
export default Task_Management
