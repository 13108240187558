import { memo, useEffect, useState } from 'react'
import {  InputLabel, TextField, FormHelperText, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from "yup";
import classes from './index.module.scss'
import { adminAccount, } from './interface'
import { Grid, Box } from '@mui/material'
import { addAdminAccount, changeTab, getClearGetDataByID, getModules, getUserByID, updateUserAccount } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { getDropdown, getDropdownValue } from "../../../constants/utils"
import { useNavigate, useParams } from 'react-router-dom';
import constants from '../../../constants/constant'
import { useLocation } from 'react-router-dom';
import atoms from '../../../components/atoms'

const { Button, Modal, Select, Table } = atoms
const AdminAcounts = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params: any = useParams()
    const pathParts = location.pathname.split('/').filter(Boolean);
    const param = pathParts[1]
    const [moduleError, setModuleError] = useState(false)
    const [moduleOptions, setModuleOptions] = useState([])
    const [selectedModuleId, setSelectedModuleId] = useState([])
    const scopeMap: any = {
        "admin": constants.SCOPE.CLIENT_ADMIN,
        "clinician": constants.SCOPE.CLINICIAN,
        "education_setting": constants.SCOPE.SCHOOL,
        "parent": constants.SCOPE.PARENT
    };
    const scope: any = scopeMap[param];
    let schoolInitialValues = {
        first_name: '',
        last_name: '',
        email: '',
        postCode: '',
        school_name: '',
        address: '',
        phone_no: '',
        jobRole: '',
        role: constants.ROLE.ALL_ACCESS,
        scope: scope
    }
    const modulesReducer = (state: any) => state?.userManagementReducer
    const modulesData = createSelector(
        modulesReducer,
        (state) => ({
            modulesList: state.moduleData,
            detailById: state.detailById
        })
    );
    const { modulesList, detailById } = useSelector(modulesData);
    const validation = useFormik<adminAccount>({
        enableReinitialize: true,
        initialValues: param === 'education_setting' ? schoolInitialValues : {
            first_name: '',
            last_name: '',
            email: '',
            postCode: '',
            jobRole: '',
            phone_no: '',
            role: param === 'admin' ? constants.ROLE.RESTRICTED_ACCESS : constants.ROLE.ALL_ACCESS,
            scope: scope
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            email: Yup.string().required("Please Enter Email"),
            postCode: param !== "clinician" && param !== "admin" && param !== "parent" ? Yup.string()
                .matches(/[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}/i, 'Invalid postcode format')
                .required("Please Enter Postcode") : Yup.string().nullable(),
            jobRole: param !== "education_setting" && param !== "parent" && param !== "admin" ? Yup.string().required("Please Enter Job Role") : Yup.string().nullable(),
            school_name: param === "education_setting" ? Yup.string().required("Please Enter School Name") : Yup.string().nullable(),
            address: param === "education_setting" ? Yup.string().required("Please Enter Address") : Yup.string().nullable(),
            phone_no: (param !== "clinician" && param !== "admin" && param !== "parent" && param !== "education_setting")? Yup.string().required("Please Enter Mobile"): Yup.string().nullable(),

            // modules: Yup.string().required("Please Select"),

        }),
        onSubmit: (values: adminAccount) => {
            if (param === "admin") {
                if (selectedModuleId?.length === 0) {
                    return
                }
                let ids: any = selectedModuleId?.map((a: any) => a.value);
                values.module_id = ids;
            }
            if (param === "education_setting") {
                values.school_name = values.school_name;
                values.address = values.address;
                values.phone_no = values.phone_no;
            }


            if (params.id) {
                dispatch(updateUserAccount(params.id, values, navigate, param))
            } else {
                dispatch(addAdminAccount(values, navigate, param))
            }

        }
    });

    useEffect(() => {
        dispatch(getClearGetDataByID())
        validation.resetForm()
        const scopeType: any = {
            "admin": 'Admin Accounts',
            "clinician": 'Clinician Accounts',
            "education_setting": 'Education Setting Accounts',
            "parent": 'Parent Accounts'
        };
        dispatch(changeTab(scopeType[param]))
        if (param === "admin") {
            dispatch(getModules({}))
        }
        if (params.id) {
            dispatch(getUserByID(params.id))
        }
    }, [])

    useEffect(() => {
        let temModuleData = getDropdown(modulesList.modules, "name", '')
        setModuleOptions(temModuleData)
        if (detailById && params.id) {
            let storeModuleData: any = []
            validation.setValues({
                first_name: detailById.first_name,
                last_name: detailById.last_name,
                email: detailById.email,
                postCode: detailById.postCode || "aas",
                jobRole: detailById.jobRole || "aas",

                school_name: detailById?.school_name,
                address: detailById?.address,
                phone_no: detailById?.phone_no,
            })

            detailById.org_module && detailById?.org_module?.map((item: any) => {
                storeModuleData.push(getDropdownValue(temModuleData, item.module.id))
            })

            setSelectedModuleId(storeModuleData)
        }
    }, [modulesList, detailById])

    const moduleSelectcheck = () => {
        if (selectedModuleId?.length === 0) {
            setModuleError(true)
        } else {
            setModuleError(false)
        }
    }
    return (

        <Grid className={classes.addAdminForm}>
            <form onSubmit={validation.handleSubmit}>
                <Grid container spacing={3}>
        
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >{param === "education_setting" ? "Contact Person First Name" : "First Name"} <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography> </InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter First Name"
                                {...validation.getFieldProps('first_name')}
                                error={validation.touched.first_name && Boolean(validation.errors.first_name)}
                                helperText={validation.touched.first_name && validation.errors.first_name}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >{param === "education_setting" ? "Contact Person Last Name" : "Last Name"} <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography></InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter Last Name"
                                {...validation.getFieldProps('last_name')}
                                error={validation.touched.last_name && Boolean(validation.errors.last_name)}
                                helperText={validation.touched.last_name && validation.errors.last_name}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Email <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography></InputLabel>
                            <TextField
                                fullWidth
                                type={"email"}
                                placeholder="Enter Email"
                                {...validation.getFieldProps('email')}
                                error={validation.touched.email && Boolean(validation.errors.email)}
                                helperText={validation.touched.email && validation.errors.email}
                            />
                        </Box>

                    </Grid>
                </Grid>

                {param === "education_setting" && <Grid container spacing={3} className={classes.mt_3}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} > Name <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography></InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter School Name"
                                {...validation.getFieldProps('school_name')}
                                error={validation.touched.school_name && Boolean(validation.errors.school_name)}
                                helperText={validation.touched.school_name && validation.errors.school_name}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} > Address <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography></InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter Address"
                                {...validation.getFieldProps('address')}
                                error={validation.touched.address && Boolean(validation.errors.address)}
                                helperText={validation.touched.address && validation.errors.address}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Contact Number</InputLabel>
                            <TextField
                                fullWidth
                                type={"text"}
                                // onInput = {(e:any) =>{
                                //     e.target.value = Math.max(0, parseInt(e.target.value, 12)).toString().slice(0, 14)
                                // }}
                                onInput={(e:any) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                    e.target.value = value.slice(0, 12); // Limit to 12 digits
                                }}
                                placeholder="Enter Contact Number"
                                {...validation.getFieldProps('phone_no')}
                                error={validation.touched.phone_no && Boolean(validation.errors.phone_no)}
                                helperText={validation.touched.phone_no && validation.errors.phone_no}
                            />
                        </Box>

                    </Grid>
                </Grid>}

                <Grid container spacing={3} className={classes.mt_3}>
                    {param !== "clinician" && param !== "admin" && param !== "parent" && <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Postcode {param === "education_setting" && <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography>}</InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder="Enter Postcode"
                                {...validation.getFieldProps('postCode')}
                                error={validation.touched.postCode && Boolean(validation.errors.postCode)}
                                helperText={validation.touched.postCode && validation.errors.postCode}
                            />
                        </Box>

                    </Grid>}
                    {param === "admin" &&
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Box>
                                <InputLabel className={classes.label_color} >Modules <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography></InputLabel>
                                <Select options={moduleOptions} value={selectedModuleId}
                                    placeholder={"Select Modules"}
                                    isMulti={true}
                                    onChange={(e: any) => setSelectedModuleId(e)}
                                />
                                {selectedModuleId?.length === 0 && moduleError && <FormHelperText error>Please Select Module</FormHelperText>}
                            </Box>
                        </Grid>
                    }
                    {(param !== "education_setting" && param !== "parent" && param !== "admin") && <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Job Role {param === "clinician" && <Typography variant='body2' component={'span'} sx={{color:'#d32f2f',fontWeight:'600'}}>*</Typography>}</InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder="Enter Job Role"
                                {...validation.getFieldProps('jobRole')}
                                error={validation.touched.jobRole && Boolean(validation.errors.jobRole)}
                                helperText={validation.touched.jobRole && validation.errors.jobRole}
                            />
                        </Box>
                    </Grid>}
                    {(param !== "education_setting" && param !== "clinician") && <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Contact Number</InputLabel>
                            <TextField
                                fullWidth
                                onInput={(e:any) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                    e.target.value = value.slice(0, 12); // Limit to 12 digits
                                }}
                                type={"text"}
                                placeholder="Enter Contact Number"
                                {...validation.getFieldProps('phone_no')}
                                error={validation.touched.phone_no && Boolean(validation.errors.phone_no)}
                                helperText={validation.touched.phone_no && validation.errors.phone_no}
                            />
                        </Box>

                    </Grid>}
                </Grid>

                <Grid container spacing={3} className={`${classes.mt_3} ${classes.btnClr}`}>
                    <Grid item xs={12} sm={3} md={3} lg={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box className={classes.cancelBtn}>
                            <Button fullWidth={false} onClick={() => navigate(-1)}
                                variant={"outlined"} >
                                Cancel
                            </Button>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={3} md={3} lg={6} >
                        <Box>
                            <Button type={"submit"} fullWidth={false}
                                // sx={{width:}}
                                variant={"contained"} onClick={() => moduleSelectcheck()}>
                                {params.id ? "Update" : " Submit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Grid>

    )
}

export default memo(AdminAcounts)