import { ADD_REFERRAL,VIEW_REFERRAL,GET_GP_LIST,GET_REFERRAL_CASELOAD_DETAIL,GET_REFERRAL_ALL_DETAIL, VIEW_FULL_REFERRAL, ADD_REPORT, VIEW_REPORT, REJECT_REFERRAL_BY_ID, CANCEL_REFERRAL_REQ, UPDATE_REPORT, EDIT_REFERRAL, DASH_REPORT, SCHOOL_LISTING, SCHOOL_RESPONSE} from './actionType'
export const addReferral = (payload: any, navigate: any,isOpenReferral:boolean) => {
    return {
        type: ADD_REFERRAL,
        payload,
        navigate,
        isOpenReferral
    }
}

export const editReferral = (payload: any, id:string,navigate: any) => {
    return {
        type: EDIT_REFERRAL,
        payload,
        id,
        navigate
    }
}

export const schoolResponse = (Response: any) => {
    return {
        type: SCHOOL_RESPONSE,
        payload: Response,
    }
}

export const getSchoolList = (headers: any) => {

    return {
        type: SCHOOL_LISTING,
        headers
    }
}

export const addReport = (payload: any, navigate: any) => {
    return {
        type: ADD_REPORT,
        payload,
        navigate
    }
}

export const updateReport = (id:any,caseload:any, payload: any, navigate: any) => {
    return {
        type: UPDATE_REPORT,
        payload,
        id,
        caseload,
        navigate
    }
}

export const viewReport = (params:any) => {
    return {
        type: VIEW_REPORT,
        params
        
    }
}

export const dashReport = (params:any) => {
    return {
        type: DASH_REPORT,
        params
        
    }
}


export const viewReferral = (params:any) => {
    const type = params?.limit === '' && params?.page === '' ? VIEW_FULL_REFERRAL : VIEW_REFERRAL;
    return {
        type,
        params
        
    }
}

export const ClearReferralsList = () => {
  return {
    type : CANCEL_REFERRAL_REQ
  }
}




export const viewAllReferralDetail = (params:any) => {
    return {
        type: GET_REFERRAL_ALL_DETAIL,
        params
        
    }
}

export const getAllGPList = (params:any) => {
    return {
        type: GET_GP_LIST,
        params
        
    }
}

export const getReferalCaseloadByID = (paramsObj: any) => {

    return {
        type: GET_REFERRAL_CASELOAD_DETAIL,
        paramsObj
    }
}


export const rejectRefferalByID = (id:any,data:any) => {

    return {
        type: REJECT_REFERRAL_BY_ID,
        id,
        data
    }
}