

export const calculateAge = (dateOfBirth: string) => {
  const today = new Date()
  const birthDate = new Date(dateOfBirth)
  let age = today?.getFullYear() - birthDate?.getFullYear()
  const month = today?.getMonth() - birthDate?.getMonth()

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}


export const getDropdownValue = (constant: any, ind: any) => {
  let temp = {
    value: 0,
    label: '',
  }
  constant?.map((item: any) => {
    if (item.value === ind) {
      temp.value = item.value
      temp.label = item.label
    }
  })
  return temp
}


export const isOlderThanFiveYears = (dateOfBirth: string) => {
  const birthDate = new Date(dateOfBirth)
  const today = new Date(Date.now())
  const fiveYearsAgo = new Date(
    today.getFullYear() - 5,
    today.getMonth(),
    today.getDate()
  )

  // Check if the date of birth is before five years ago from today
  return birthDate < fiveYearsAgo
}

export const getUniqueObj = (data: any, id: string, compareId: string) => {
  let tempObj
  data &&
    data?.map((item: any) => {
      if (item[id] === compareId) {
        tempObj = item
      }
    })
  return tempObj
}

export const getDropdown = (response: any, name: string, last: string) => {
  let dropdown: any = []
  response &&
    response?.map((value: any) => {
      dropdown.push({
        value: value.id,
        label: last ? value[name] + ' ' + value[last] : value[name],
      })
    })
  return dropdown
}



export const get_url_extension = (url: any) => {
  const extension = url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim()
  if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
    return 'image'
  } else if (extension === 'pdf') {
    return 'pdf'
  } else if (
    extension === 'mp4' ||
    extension === '3gp' ||
    extension === 'mov'
  ) {
    return 'video'
  } else {
    return 'Unknown File Type'
  }
}

export function isOlderThan18Years(dateString: string) {
  const birthDate = new Date(dateString)
  const currentDate = new Date()

  // Add one day to the current date
  currentDate.setDate(currentDate.getDate() - 1)

  // Calculate the age based on years
  let age = currentDate.getFullYear() - birthDate.getFullYear()

  // Adjust age if the current month and day are before the birth month and day
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--
  }

  // Return true if age is 18 or more
  return age >= 18
}
// export const isEmptyObject = (obj: any) => {
//   return Object.keys(obj).length === 0 && obj.constructor === Object
// }

// export const generateDocObject = (data:any, uploadType:any) => {
//   return data?.reduce((acc:any, curr:any) => {
//     if (curr?.uploadType === uploadType) {
//       acc = curr;
//     }
//     return acc
//   }, {})
// }

// export const formatNHSNumber = (nhsNumber: any) => {
//   if (!nhsNumber) return '-' // Return empty string if the NHS number is not provided
//   // Format NHS number as XXX-XXX-XXXX
//   return `${nhsNumber?.slice(0, 3)} ${nhsNumber.slice(3, 6)} ${nhsNumber.slice(
//     6
//   )}`
// }

export const isEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const generateDocObject = (data: any[], uploadType: any): any => {
  return data?.find((item) => item?.uploadType === uploadType) || {};
}

export const formatNHSNumber = (nhsNumber: string | undefined): string => {
  if (!nhsNumber) return '-';
  // Format NHS number as XXX XXX XXXX
  return `${nhsNumber.slice(0, 3)} ${nhsNumber.slice(3, 6)} ${nhsNumber.slice(6)}`;
}
