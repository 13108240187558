import React from "react";
import { Container, Typography, Paper, Box, Grid, Card } from "@mui/material";
import classes from "./index.module.scss";
import Accordion from "../../atoms/Accordian";
import moment from "moment";
const ConsentFormView = ({
  question,
  type,
  tab,
  isParentTab,
  setStylePdf,
  setnesting,
  viewMode,
  patientName,
  question_number,
}: any) => {
  let questionArray = question?.filter((obj: any) => obj?.isRequired);

  
  return (

    <Grid className={classes.counterRest}>
      <Grid
        className={classes.Referral}
        sx={{
          "&::before": {
            display: viewMode === "ACCORDIAN" ? "block" : "none",
          },
        }}
      >
       <Card
          className={`${classes.card} ${setStylePdf} `}
          sx={{
            padding: viewMode === "ACCORDIAN" ? '0' : '20px',
            marginRight: viewMode === "ACCORDIAN" ? '0' : '10px',
            borderRadius: viewMode === "ACCORDIAN" ? '0' : '0.7rem',
            border: 'none',
          }}
        >
          {viewMode === "ACCORDIAN" ? (
            <Accordion
              title={type}
              content={
                questionArray &&
                questionArray?.map((item: any, index: number) => {
                  return (
                    <Grid key={index}>
                      {/* {index === 0 && <Typography sx={{ fontWeight: 600 }} variant="h6" gutterBottom >
                                {tab + 1}. {type}
                            </Typography>} */}
                      <Box my={2}>
                        <Grid
                          display={"flex"}
                          sx={{ gap: "5px" }}
                          className={`${classes.viewOutput} questionText`}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {!isNaN(question_number)
                              ? `${question_number + index + 1}.`
                              : ""}{" "}

                        {item?.question.replaceAll('[forename]',patientName)}                       
                          </Typography>
                        </Grid>
                        <Grid
                          display={"flex"}
                          sx={{
                            gap: "5px",
                            mt: "5px",
                            flexDirection: "column",
                          }}
                          className={`${classes.viewOutput}  ansOutput`}
                        >
                          {isParentTab &&
                          item?.answer &&
                          Array.isArray(item.answer) ? (
                            // Rendering for array of answers
                            item.answer.map(
                              (nestedItem: any, index: number) => (
                                <React.Fragment key={index}>
                                  <Box
                                    className={`${setnesting}`}
                                    display={"flex"}
                                    marginY={"0.2rem"}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {nestedItem.name === ""
                                        ? "Ans"
                                        : nestedItem.name}
                                    </Typography>
                                    <Typography variant="body1">
                                      <span style={{ fontWeight: 600 }}>
                                        &nbsp; :{" "}
                                      </span>{" "}
                                      {nestedItem.type === "date"
                                        ? moment(nestedItem.answer).format(
                                            "DD-MM-YYYY"
                                          )
                                        : nestedItem.answer ?? nestedItem}
                                    </Typography>
                                  </Box>
                                </React.Fragment>
                              )
                            )
                          ) : (
                            // Rendering for non-array answers or specific conditions
                            <Typography variant="body1">
                              {item.answer === "NESTING" ? (
                                // Condition for specific nested object rendering
                                item.nestingObj.onetype.map(
                                  (nestedItem: any, index: number) => (
                                    <React.Fragment key={index}>
                                      <Box
                                        className={`${setnesting}`}
                                        display={"flex"}
                                        marginY={"0.8rem"}
                                      >
                                        <Typography sx={{ fontWeight: 600 }}>
                                          {nestedItem.name}
                                        </Typography>
                                        <Typography variant="body1">
                                          <span style={{ fontWeight: 600 }}>
                                            &nbsp; :{" "}
                                          </span>{" "}
                                          {nestedItem.type === "date"
                                            ? nestedItem.answer
                                            : nestedItem.answer}
                                        </Typography>
                                      </Box>
                                    </React.Fragment>
                                  )
                                )
                              ) : (
                                // Default rendering for other cases
                                <>
                                  <span style={{ fontWeight: "bold" }}>
                                    Ans : &nbsp;
                                  </span>{" "}
                                  {
                                  // item?.question_id !== "ques22" ||
                                  // item?.question_id !== "ques30"
                                  //   ? item?.answer?.label
                                  //   : typeof item?.answer === "object"
                                  //   ? `${item?.answer?.label}: ${item?.answer?.value}`
                                     item.type === "date"
                                    ? moment(item.answer).format("DD-MM-YYYY")
                                    : item.answer}
                                </>
                              )}
                            </Typography>
                          )}
                        </Grid>
                        {item?.isAdditional && item?.isAdditionalStructure && (
                          <Grid sx={{ ml: "21px" }}>
                            {item?.isAdditionalStructure?.question && (
                              <Grid
                                display={"flex"}
                                sx={{ gap: "5px" }}
                                className={`${classes.viewOutput}  ansOutput`}
                              >
                                <Typography sx={{ fontWeight: 600 }}>
                                  1.
                                </Typography>
                                <Typography sx={{ fontWeight: 600 }}>
                                  {item?.isAdditionalStructure?.question}
                                </Typography>
                              </Grid>
                            )}
                            {item?.isAdditionalStructure?.answer && (
                              <Grid
                                display={"flex"}
                                sx={{ gap: "5px", mt: "5px" }}
                                className={`${classes.viewOutput} ansOutput`}
                              >
                                <Typography sx={{ fontWeight: 600 }}>
                                  Ans.
                                </Typography>
                                <Typography variant="body1">
                                  {item?.isAdditionalStructure?.type === "date"
                                    ? moment(
                                        item?.isAdditionalStructure?.answer
                                      ).format("DD-MM-YYYY")
                                    : item?.isAdditionalStructure?.answer}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  );
                })
              }
            />
          ) : (
            questionArray &&
                questionArray?.map((item: any, index: number) => {
                  return (
                    <Grid key={index}>
                      {index === 0 && <Typography sx={{ fontWeight: 600 }} variant="h6" gutterBottom >
                                {tab + 1}. {type}
                            </Typography>}
                      <Box my={2}>
                        <Grid
                          display={"flex"}
                          sx={{ gap: "5px" }}
                          className={`${classes.viewOutput} questionText`}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {!isNaN(question_number)
                              ? `${question_number + index + 1}.`
                              : ""}{" "}
                            {item?.question_number}{'.'}{'  '}{item?.question}
                          </Typography>
                        </Grid>
          
                        <Grid
                          display={"flex"}
                          sx={{
                            gap: "5px",
                            mt: "5px",
                            flexDirection: "column",
                          }}
                          className={`${classes.viewOutput}  ansOutput`}
                        >
                          {isParentTab &&
                          item?.answer &&
                          Array.isArray(item.answer) ? (
                            // Rendering for array of answers
                            item.answer.map(
                              (nestedItem: any, index: number) => (
                            
                                <React.Fragment key={index}>

                                  <Box
                                    className={`${setnesting}`}
                                    display={"flex"}
                                    marginY={"0.2rem"}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {nestedItem.name === ""
                                        ? "Ans"
                                        : nestedItem.name}
                                   
                                    </Typography>
                                    <Typography variant="body1">
                                      <span style={{ fontWeight: 600 }}>
                                        &nbsp; :{" "}
                                      </span>{" "}
                                      {nestedItem.type === "date"
                                        ? moment(nestedItem.answer).format(
                                            "DD-MM-YYYY"
                                          )
                                        : nestedItem.answer ?? nestedItem}
                                  
                                    </Typography>
                                  </Box>
                                </React.Fragment>
                              )
                            )
                          ) : (
                     
                            <Typography variant="body1">
                              {item.answer === "NESTING" ? (
       
                                item.nestingObj.onetype.map(
                                  (nestedItem: any, index: number) => (
                                    <React.Fragment key={index}>
                                      <Box
                                        className={`${setnesting}`}
                                        display={"flex"}
                                        marginY={"0.8rem"}
                                      >
                                        <Typography sx={{ fontWeight: 600 }}>
                                          {nestedItem.name}
                             
                                        </Typography>
                                        <Typography variant="body1">
                                          <span style={{ fontWeight: 600 }}>
                                            &nbsp; :{" "}
                                          </span>{" "}
                                          {nestedItem.type === "date"
                                            ? moment(nestedItem.answer).format(
                                                "DD-MM-YYYY"
                                              )
                                            : nestedItem.answer} 
                                     
                                        </Typography>
                                      </Box>
                                    </React.Fragment>
                                  )
                                )
                              ) : (
                                // Default rendering for other cases
                                <>
                                  <span style={{ fontWeight: "bold" }}>
                                    Ans : &nbsp;
                                  </span>{" "}
                                  {
                                  item?.question_id === "ques22" && item?.type !== 'radio' && item?.type !== "textArea" ||
                                  item?.question_id === "ques30" && item?.type !== 'radio' && item?.type !== "textArea"
                                    ? item?.answer?.label
                                    : typeof item?.answer === "object"
                                    ? `${item?.answer?.label}: ${item?.answer?.value}`
                                    : item.type === "date"
                                    ? moment(item.answer).format("DD-MM-YYYY")
                                    : item.answer}
                                </>
                                     
                              )}
                            </Typography>
                          )}
                        </Grid>
                        {item?.isAdditional && item?.isAdditionalStructure && (
                          <Grid sx={{ ml: "21px" }}>
                            {item?.isAdditionalStructure?.question && (
                              <Grid
                                display={"flex"}
                                sx={{ gap: "5px" }}
                                className={`${classes.viewOutput}  ansOutput`}
                              >
                                <Typography sx={{ fontWeight: 600 }}>
                                  1.
                                </Typography>
                                <Typography sx={{ fontWeight: 600 }}>
                                  {item?.isAdditionalStructure?.question}
                                </Typography>
                              </Grid>
                            )}
                            {item?.isAdditionalStructure?.answer && (
                              <Grid
                                display={"flex"}
                                sx={{ gap: "5px", mt: "5px" }}
                                className={`${classes.viewOutput} ansOutput`}
                              >
                                <Typography sx={{ fontWeight: 600 }}>
                                  Ans.
                                </Typography>
                                <Typography variant="body1">
                                  {item?.isAdditionalStructure?.type === "date"
                                    ? moment(
                                        item?.isAdditionalStructure?.answer
                                      ).format("DD-MM-YYYY")
                                    : item?.isAdditionalStructure?.answer}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  );
                })
              )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ConsentFormView;
