import React, {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
  Chip,
  TextField,
  InputLabel,
  Tooltip,
} from "@mui/material";

import {
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  AddCircle as AddCircleIcon,
  FilterAlt as FilterAltIcon,
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  NoteAlt as NoteAltIcon,
  ArrowRight,
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ClearCaseloadList,
  ClearReferralsList,
  ResetPagination,
  SetActiveTab,
  addSystemDate,
  caseDocumentsById,
  changeTab,
  clearUnmountData,
  getCaseloadList,
  rejectRefferalByID,
  removeChat,
  viewAllReferralDetail,
  viewReferral,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import CONSTANT from "../../../constants/constant";
import newRef from "../../../assets/Images/newRef.svg";
import activeRef from "../../../assets/Images/activeRef.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import Search from "../../../components/atoms/Search";
import css from "./index.module.scss";
import constant from "../../../constants/constant";
import { calculateAge, formatNHSNumber } from "../../../constants/utils";
import Modal from "../../../components/atoms/Modal";
import { CurrentUserData } from "../../../components/organism/overviewCaseload/interface";
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";
import { useResponsive } from "../../../components/atoms/useResponsive";
import atoms from "../../../components/atoms";
import { useRejectedReferral } from "./RejectedRefferal";
import { useClearSearch } from "../../../components/atoms/Hooks/useClearSearch";
import { DatePicker } from "@mui/x-date-pickers";

const { DateRangePicker, Table } = atoms;
const PdfRefeeral = React.lazy(
  () => import("../../../components/atoms/PdfRefeeral")
);
const Export = React.lazy(() => import("../../../components/atoms/Export"));

interface TabItemProps {
  activateTab: React.MutableRefObject<number>;
  index: number;
  title: string;
  iconSrc: string;
  onClickHandler: () => void;
  isActive: boolean;
}

interface Referral {
  ind: number;
  referral_id: string;
  patient_name: any;
  createdAt: string; // or Date if you're storing Date objects
  updatedAt: string;
  age: number;
  patient_nhs_number: string;
  reject_reason?: string; // Optional if not always present
  notes?: string; // Optional if not always present
  status: string;
  action: string;
}

const Index = () => {
  let loginDetailString = localStorage.getItem("loginResponse");
  let loginDetail = loginDetailString ? JSON.parse(loginDetailString) : {};
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [triggerDownload, setTriggerDownload] = useState(false);
  const pathParts = location.pathname.split("/").filter(Boolean);
  const [isfilterObject, setIsfilterObject] = useState<any>({});
  const [isActiveFilterVal, setActiveFilterVal] = useState<string>("");
  const param = pathParts[1];
  const activateTab = useRef(0);
  const [isViewRejectedRef, setisViewRejectedRef] = useState(false);
  const [isSystemOne, setisSystemOne] = useState(false);
  const { setSearch, search, clearSearch } = useClearSearch();
  const [limit, setLimit] = useState(10);
  const [pdfApply, setpdfApply] = useState("REMOVEBTN");
  const [CaseLoadID, setCaseLoadID] = useState("");
  const [refType, setrefType] = useState("");
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortType, setSortType] = useState("");
  const [anchorFilterEl, setAnchorFilterEl] =
    React.useState<null | HTMLElement>(null);
  const { windowWidth, isMediumScreen } = useResponsive();
  const open = Boolean(anchorFilterEl);
  const {
    handlerejectByID,
    formData,
    setFormData,
    selectedUserId,
    isRejectedRef,
    RefIDForPDF,
    anchorEl,
    setAnchorEl,
    setRefIDForPDF,
    setSelectedUserId,
    setisRejectedRef,
  } = useRejectedReferral();

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorFilterEl(null);
  };

  const handleChipClick = (id: string, type: string) => {
    setisSystemOne(true);
    setCaseLoadID(id);
    setrefType(type);
  };

  const caseloadReducer = (state: any) => state?.CaseloadReducer;
  const layoutReducer = (state: any) => state?.LayoutReducer;
  const docListReducer = (state: any) => state?.caseDocumentReducer;

  const columns = {
    columnAcceptRef: [
      {
        name: "#",
        cell: (row: any, index: number) => row.ind,
        width: "50px",
        tblName: "",
      },

      {
        name: "Referral ID",
        cell: (item: any, ind: number) => (
          <span style={{ whiteSpace: "nowrap" }}>
            {item?.caseload_unique_id}
          </span>
        ),
        sortable: false,
        width: "150px",
        tblName: "caseload_unique_id",
      },
      {
        name: "Patient Name",
        cell: (item: any, ind: number) => (
          <Typography
            sx={{
              fontSize: "14px",
              display: "-webkit-box",
              textTransform: "capitalize",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis", // Optional: Adds ellipsis at the end of the truncated text
            }}
          >
            <Tooltip title={item?.patient_name}>{item.patient_name}</Tooltip>
          </Typography>
        ),
        selector: (item: any, ind: number) => item.patient_name,
        sortable: false,
        width: "150px",
        tblName: "patient_name",
      },
      {
        name: `Submitted Date ${
          sortType === "asc" ? " ▲" : sortType === "desc" ? " ▼" : ""
        }`,
        cell: (item: any, ind: number) => (
          <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>
        ),
        sortable: true,
        width: "150px",
        tblName: "createdAt",
      },
      {
        name: "SystmOne Action",
        width: "220px",
        cell: (item: any, ind: number) =>
          activateTab.current === 1 ? (
            <Box className={css.chipsStyle}>
              {[
                {
                  label: "Referral Uploaded",
                  date: item?.system_one_referral_date,
                  key: "system_one_referral_date",
                  path: item.pathway_status,
                },
                {
                  label: "Education Reports Uploaded",
                  date: item?.system_one_education_report_date,
                  key: "system_one_education_report_date",
                  path: item.pathway_status,
                },
                {
                  label: "Parent Reports Uploaded",
                  date: item?.system_one_parent_report_date,
                  key: "system_one_parent_report_date",
                  path: item.pathway_status,
                },
                ...(param === "closed"
                  ? [
                      {
                        label: "Outcome Letter Uploaded",
                        date: item?.system_one_outcome_date,
                        key: "system_one_outcome_date",
                        path: item.pathway_status,
                      },
                    ]
                  : []),
              ].map(({ label, date, key, path }) => {
                // Determine chip color and clickability
                let chipColor = "gray";
                let clickable = false;

                if (date) {
                  chipColor = "green"; // Date present
                } else if (
                  (path.includes("1") && key === "system_one_referral_date") ||
                  (path.includes("2") &&
                    key === "system_one_education_report_date") ||
                  (path.includes("3") &&
                    key === "system_one_parent_report_date") ||
                  (path.includes("6") && key === "system_one_outcome_date")
                ) {
                  chipColor = "red"; // No date but meets path conditions
                  clickable = true;
                }

                return (
                  <Tooltip
                    key={key}
                    title={
                      date
                        ? moment(date).utc().format("DD/MM/YYYY : HH:mm")
                        : "No date available"
                    }
                  >
                    <Chip
                      label={label}
                      size="small"
                      sx={{
                        color: chipColor,
                        "& .MuiChip-label": {
                          padding: 0,
                        },
                      }}
                      onClick={() =>
                        clickable && handleChipClick(item?.id, key)
                      }
                    />
                  </Tooltip>
                );
              })}
            </Box>
          ) : null,
        sortable: false,
        tblName: "action",
      },

      {
        name: "Patient DOB",
        cell: (item: any, ind: number) => (
          <span>{moment(item.patient_dob).format("DD/MM/YYYY")}</span>
        ),
        sortable: false,
        width: "120px",
        tblName: "patient_dob",
      },
      {
        name: "NHS Number",
        cell: (item: any, ind: number) => (
          <span>{formatNHSNumber(item?.nhs_number)}</span>
        ),
        sortable: false,
        width: "120px",
        tblName: "nhs_number",
      },
      {
        name: "Pathway Status",
        cell: (item: any, ind: number) => (
          <span>{getStatusDescription(item, "pathway")}</span>
        ),
        sortable: false,
        width: "180px",
        tblName: "pathway_status",
      },
      {
        name: "Next Milestone",
        cell: (item: any, ind: number) => (
          <span>
            {param === "closed" ? "-" : getStatusDescription(item, "milestone")}
          </span>
        ),
        sortable: false,
        width: "180px",
        tblName: "milestone",
      },
      ...(param === "closed"
        ? [
            {
              name: "Outcome",
              cell: (item: any, ind: number) => (
                <>
                  {item?.clinical_review_accepted ? (
                    <span className="refReceived">Accepted</span>
                  ) : (
                    <span className="refClosed">Rejected</span>
                  )}
                </>
              ),
              sortable: false,
              width: "10%",
              tblName: "clinical_review_accepted",
            },
          ]
        : []),
      {
        name: "Source",
        width: "100px",
        cell: (item: any, ind: number) => (
          <span>
            {item?.referral?.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
              <Typography className="activeStatus">Education</Typography>
            ) : item?.referral?.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
              <Typography className="deactiveStatus">Admin</Typography>
            ) : item?.referral?.submitted_by === CONSTANT.SCOPE.UNKNOWN ? (
              <Typography className="deactiveStatus">Open URL</Typography>
            ) : (
              <></>
            )}
          </span>
        ),
        sortable: false,
        tblName: "status",
      },

      {
        name: "Action",
        width: "80px",
        cell: (item: any, ind: number) => <span>{item.action}</span>,
        sortable: false,
        tblName: "action",
      },
    ],
    columnNewRef: [
      {
        name: "#",
        cell: (row: Referral, index: number) => row.ind,
        width: "50px",
        tblName: "",
      },
      {
        name: "Referral ID",
        cell: (item: Referral, ind: number) => <span>{item.referral_id}</span>,
        sortable: false,
        width: "15%",
        tblName: "referral_id",
      },
      {
        name: "Patient Name",
        cell: (item: Referral, ind: number) => (
          <Typography
            sx={{
              fontSize: "14px",
              display: "-webkit-box",
              textTransform: "capitalize",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis", // Optional: Adds ellipsis at the end of the truncated text
            }}
          >
            <Tooltip title={item?.patient_name}>{item?.patient_name}</Tooltip>
          </Typography>
        ),
        selector: (item: any, ind: number) => item.patient_name,
        sortable: false,
        width: "15%",
        tblName: "patient_name",
      },
      {
        name: `Submitted Date ${
          sortType === "asc" ? " ▲" : sortType === "desc" ? " ▼" : ""
        }`,
        cell: (item: Referral, ind: number) => {
          const dateToFormat = param === "rejected" ? item.updatedAt : item.createdAt;
          return <span>{moment(dateToFormat).format("DD/MM/YYYY")}</span>;
        },
        sortable: true,
        width: "10%",
        tblName: "createdAt",
      },

      {
        name: "Patient Age",
        cell: (item: Referral, ind: number) => <span>{item.age}</span>,
        sortable: false,
        width: "10%",
        tblName: "age",
      },
      {
        name: "NHS Number",
        width: "10%",
        cell: (item: Referral, ind: number) => (
          <span>{formatNHSNumber(item?.patient_nhs_number)}</span>
        ),
        sortable: false,
        tblName: "patient_nhs_number",
      },

      ...(param === "rejected"
        ? [
            {
              name: "Rejected By",
              cell: (item: Referral) => <span>{item.reject_reason}</span>,
              sortable: false,
              width: "10%",
              tblName: "reject_reason",
            },
            {
              name: "Notes",
              cell: (item: Referral) => <span>{item.notes}</span>,
              sortable: false,
              width: "10%",
              tblName: "notes",
            },
          ]
        : []),

      {
        name: "Source",
        width: "10%",
        cell: (item: Referral, ind: number) => <span>{item?.status}</span>,
        sortable: false,
        tblName: "status",
      },
      {
        name: "Action",
        width: "80px",
        cell: (item: Referral, ind: number) => <span>{item?.action}</span>,
        sortable: false,
        tblName: "action",
      },
    ],
  };

  const userData: CurrentUserData = JSON.parse(
    localStorage.getItem("loginResponse") || "null"
  );

  const layoutURLData = createSelector(layoutReducer, (state) => ({
    currentTabRef: state.currentTabRef,
    caseloadIndex: state.caseloadIndex,
  }));
  const { currentTabRef, caseloadIndex } = useSelector(layoutURLData);

  const caseloadData = createSelector(caseloadReducer, (state) => ({
    caseloadList: state?.caseloadData,
  }));
  const { caseloadList } = useSelector(caseloadData);

  const referralReducer = (state: any) => state?.referralReducer;
  const referralData = createSelector(referralReducer, (state) => ({
    referralList: state?.referralList,
  }));
  const { referralList } = useSelector(referralData);

  const referralReducerData = createSelector(referralReducer, (state) => ({
    referralAllDetail: state?.referralAllDetail?.data,
  }));
  const { referralAllDetail } = useSelector(referralReducerData);

  const caseDocuments = createSelector(docListReducer, (state) => ({
    casedocList: state?.data?.data?.caseload_documents,
  }));

  const { casedocList } = useSelector(caseDocuments);

  const getStatusDescription = useCallback((item: any, type: string) => {
    let compareType =
      type === "pathway"
        ? Math.max(...(item?.pathway_status || []))
        : type === "milestone"
        ? findGreatestNumber(item?.pathway_status, item) + 1
        : "";

    switch (compareType.toString()) {
      case CONSTANT.PATHWAY_STATUS.CASELOAD_CREATED:
        return <span className="refReceived">Referral Received</span>;
      case CONSTANT.PATHWAY_STATUS.CASELOAD_CLOSED:
        return <span className="refClosed">Referral Closed</span>;
      case CONSTANT.PATHWAY_STATUS.PARENT_REPORT_RECEIVED:
        return <span className="parentReport">Parent Report</span>;
      case CONSTANT.PATHWAY_STATUS.READY_FOR_MDT_REVIEW:
        return <span className="clinicalReview">Ready Clinical Review</span>;
      case CONSTANT.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED:
        return <span className="eduReport">Education Report</span>;
      case CONSTANT.PATHWAY_STATUS.TASK:
        return <span className="taskClr">Task Completed</span>;
      default:
        return <span className="refClosed">Outcome Pending</span>;
    }
  }, []);

  useEffect(() => {
    const handleActiveTab = () => {
      dispatch(changeTab(`Active Referrals`));
      {
        activateTab.current === 1 &&
          dispatch(
            getCaseloadList({
              page,
              search,
              limit,
              end_date: endDate,
              sortBy: sortType,
              start_date: startDate,
              status: constant.ACTIVE_REFERRAL_STATUS.ACCEPTED,
              filter: isfilterObject,
            })
          );
      }
      {
        activateTab.current === 0 &&
          dispatch(
            viewReferral({
              page,
              search,
              limit,
              end_date: endDate,
              start_date: startDate,
              sortBy: sortType,
            })
          );
      }
      {
        activateTab.current === 2 &&
          dispatch(
            viewReferral({
              page,
              search,
              limit,
              sortBy: sortType,
              end_date: endDate,
              start_date: startDate,
              status: constant.REFERRAL_STATUS.DRAFT,
            })
          );
      }
      if (loginDetail?.organization?.autoCaseloadCreation) {
        activateTab.current = 1;
      }
    };

    const handleClosedTab = () => {
      dispatch(changeTab(`Closed Referrals`));
      dispatch(
        getCaseloadList({
          page,
          search,
          limit,
          end_date: endDate,
          start_date: startDate,
          sortBy: sortType,
          status: constant.ACTIVE_REFERRAL_STATUS.CLOSED,
          filter: isfilterObject,
        })
      );
      activateTab.current = 1;
    };

    const handleRejectedTab = () => {
      dispatch(changeTab(`Rejected Referrals`));
      activateTab.current = 0;
      const status =
        param === "rejected" ? constant.REFERRAL_STATUS.REJECTED : null;
      dispatch(viewReferral({ page, search, limit, sortBy: sortType, status }));
    };

    if (param === "active") {
      handleActiveTab();
    } else if (param === "closed") {
      handleClosedTab();
    } else if (param === "rejected") {
      dispatch(SetActiveTab(0));
      handleRejectedTab();
    }

    return () => {
      dispatch(ClearCaseloadList());
      dispatch(ClearReferralsList());
    };
  }, [
    param,
    page,
    search,
    limit,
    activateTab.current,
    endDate && endDate,
    sortType,
  ]);

  const handleClick = (event: any, refId: string, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
    setRefIDForPDF(refId);
    dispatch(removeChat());
  };

  const handleRefClick = (event: any, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function findGreatestNumber(arr: any, fullData: any) {
    let numbersArr = arr
      ?.filter((num: any) => num !== null && num !== undefined)
      .map(Number);
    if (numbersArr && numbersArr?.length > 0) {
      let maxNumber = Math.max(...numbersArr);
      if (numbersArr?.includes(3) && !numbersArr?.includes(2)) {
        return 1;
      } else if (numbersArr?.includes(5)) {
        return 6;
      } else if (numbersArr?.includes(4) && !numbersArr?.includes(2)) {
        return 1;
      } else if (numbersArr?.includes(4) && !numbersArr?.includes(3)) {
        return 2;
      } else if (
        numbersArr?.includes(2) &&
        numbersArr?.includes(3) &&
        fullData?.pending_task === 0
      ) {
        return 4;
      } else {
        return maxNumber;
      }
    }
    return 0;
  }

  const handleRowClick = (id: any) => {
    Navigate(`/manage-caseload/create?id=${id}`);
  };

  const handleNavigate = (type: string, mode: string) => {
    let url: string | null = null;
    setFormData({ date: "", time: "", reason: "" });

    if (mode === "newRef") {
      if (type === "view") {
        url = `/referral/view/${selectedUserId}`;
      } else if (type === "create-caseload") {
        url = `/referral/create-caseload/${selectedUserId}`;
      } else if (type === "reject") {
        setisRejectedRef(true);
      } else if (type === "editRef") {
        url = `/referral/edit/${selectedUserId}`;
      }
    } else if (mode === "Accept") {
      if (type === "view") {
        if (param === "closed") {
          url = `/manage-caseload/create?type=${param}&id=${selectedId}`;
        } else {
          url = `/manage-caseload/create?id=${selectedId}`;
        }
      } else if (type === "download") {
        setRefIDForPDF(RefIDForPDF);
      }
    }

    if (url) {
      Navigate(url);
    }
  };

  // const handleFileDownload = async (fileUrl: string, fileName: string) => {
  //   try {
  //     const response = await fetch(fileUrl);

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const contentType = response.headers.get("Content-Type") || "";
  //     const blob = await response.blob();
  //     const fileExtension = "pdf";
  //     const completeFileName = `${fileName}.${fileExtension}`;

  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = completeFileName;

  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  // const handleDownload = (refId: string | null, caseloadId: string | null) => {
  //   if (refId && caseloadId) {
  //     dispatch(caseDocumentsById(caseloadId, 'navigate'));
  //     setTriggerDownload(true);
  //   }
  // };

  // useEffect(() => {
  //   if (triggerDownload && casedocList) {
  //     const isAvailable = casedocList.filter((item: { uploadType: string }) => item.uploadType === 'referral-report');

  //     if (isAvailable.length > 0) {
  //       console.log("Available");
  //       handleFileDownload(isAvailable[0]?.url, isAvailable[0]?.document_name);
  //     } else {
  //       console.log("Not Available");
  //       dispatch(viewAllReferralDetail({ ReferralId: RefIDForPDF }));
  //       setTimeout(() => {
  //         setpdfApply('DOWNLOAD');
  //       }, 1000);
  //       setAnchorEl(null);
  //       setpdfApply('REMOVEBTN');
  //     }

  //     setTriggerDownload(false); 
  //   }
  // }, [triggerDownload, casedocList]);
  
  const handleDownload = (id: any) => {
    dispatch(viewAllReferralDetail({ ReferralId: id }));
    setTimeout(() => {
      setpdfApply('DOWNLOAD');
    }, 1000);
    setAnchorEl(null);
    setpdfApply('REMOVEBTN');
  }

  const handleAudit = (id: any) => {
    Navigate(`/audit-trail/${id}`);
  };

  useEffect(() => {
    if (userData?.scope === constant.SCOPE.CLINICIAN) {
      activateTab.current = 1;
    } else if (
      loginDetail?.organization?.autoCaseloadCreation &&
      param === "active"
    ) {
      activateTab.current = 1;
    } else if (param === "closed") {
      activateTab.current = 1;
    } else {
      activateTab.current = currentTabRef || caseloadIndex;
    }
    clearSearch();
  }, [currentTabRef, param, caseloadIndex]);

  const handleSelectChange = (e: any) => {
    dispatch(ResetPagination(true));
    const selectedValues = e.target.value.toString();
    setActiveFilterVal(selectedValues);
    let filterVal = {
      pathway_status: selectedValues,
    };

    const filterValString = JSON.stringify(filterVal);
    setIsfilterObject(filterValString);
    dispatch(
      getCaseloadList({
        page: 1,
        search,
        limit,
        status: constant.ACTIVE_REFERRAL_STATUS.ACCEPTED,
        filter: filterValString,
      })
    );
    handleFilterClose();
  };

  const filterEle = () => {
    return (
      <>
        <IconButton
          size="large"
          className="iconStyle"
          color="primary"
          sx={{ border: `1px solid #6A2382` }}
          onClick={handleFilterClick}
        >
          <FilterAltIcon fontSize="medium" />
          {isfilterObject?.length > 0 && (
            <CircleIcon
              style={{
                fontSize: "10px",
                color: "red",
                position: "absolute",
                right: "-3px",
                top: "6px",
              }}
            />
          )}
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorFilterEl}
          open={open}
          onClose={handleFilterClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              width: "350px",
              p: 0,
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {convertDataToOptions(constant)}
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(
                getCaseloadList({
                  page,
                  search,
                  limit,
                  status: constant.ACTIVE_REFERRAL_STATUS.ACCEPTED,
                  filter: "",
                })
              );
              handleFilterClose();
              setIsfilterObject({});
            }}
            sx={{ borderRadius: "26px", margin: "0.5rem", float: "right" }}
          >
            <span> Clear All</span>
          </Button>
        </Menu>
      </>
    );
  };

  const convertDataToOptions = (data: any) => {
    const options = [];
    if (data?.PATHWAY_STATUS) {
      const statusMap = {
        CASELOAD_CREATED: "Referral Received",
        SCHOOL_REPORT_RECEIVED: "Education Report",
        PARENT_REPORT_RECEIVED: "Parent Report",
        TASK: "Task",
        READY_FOR_MDT_REVIEW: "Ready for Clinical Review",
      } as const;

      let index = 1; // Initialize a counter for numbering

      for (const key in data.PATHWAY_STATUS) {
        if (key === "CASELOAD_CLOSED" || key === "TASK") {
          continue; // Skip these keys
        }

        options.push(
          <MenuItem
            key={key}
            value={data.PATHWAY_STATUS[key]}
            selected={Boolean(isActiveFilterVal === data?.PATHWAY_STATUS[key])}
            onClick={handleSelectChange}
          >
            {`${index}. ${statusMap[key as keyof typeof statusMap]}`}{" "}
            {/* Add the numbering */}
          </MenuItem>
        );

        index++; // Increment the counter
      }
    }
    return options;
  };

  const handleViewNotes = (data: string) => {
    setFormData((prevFormData) => ({ ...prevFormData, reason: data }));
    setisViewRejectedRef(true);
  };

  const data =
    activateTab.current === 0
      ? referralList?.referrals?.map((item: any, index: number) => ({
          ...item,
          ind: ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
          status:
            item.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
              <Typography className="activeStatus">Education</Typography>
            ) : item.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
              <Typography className="deactiveStatus">Admin</Typography>
            ) : item.submitted_by === CONSTANT.SCOPE.UNKNOWN ? (
              <Typography className="deactiveStatus">Open URL</Typography>
            ) : (
              <></>
            ),
          reject_reason:
            item.reject_reason === null ? (
              <Typography className="activeStatus">System</Typography>
            ) : (
              <Typography className="deactiveStatus">Admin</Typography>
            ),
          notes:
            item.reject_reason === null ? (
              <Typography sx={{ textAlign: "center" }}> - </Typography>
            ) : (
              <Button
                className={css.btnStyle}
                size="small"
                variant="outlined"
                sx={{ borderRadius: "20px" }}
                onClick={() => handleViewNotes(item.reject_reason)}
              >
                View
              </Button>
            ),
          age: calculateAge(item.patient_dob),
          action: (
            <div key={index}>
              <IconButton
                aria-controls={`item-menu-${item?.id}`}
                aria-haspopup="true"
                className="iconStyleAction"
                onClick={(e) => handleRefClick(e, item?.id)}
                color="primary"
                sx={{ border: `1px solid #6A2382` }}
              >
                <MoreHorizIcon fontSize="medium" />
              </IconButton>

              <Menu
                id={`item-menu-${item?.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => handleNavigate("view", "newRef")}>
                  <ListItemIcon>
                    <VisibilityIcon />
                  </ListItemIcon>
                  View
                </MenuItem>

                {param !== "closed" && param !== "rejected" && (
                  <MenuItem
                    onClick={() => handleNavigate("create-caseload", "newRef")}
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    Accept Referral
                  </MenuItem>
                )}

                {param !== "closed" && param !== "rejected" && (
                  <MenuItem onClick={() => handleNavigate("reject", "newRef")}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Reject
                  </MenuItem>
                )}
              </Menu>
            </div>
          ),
        }))
      : activateTab.current === 1
      ? caseloadList?.ActiveReferrals?.map((item: any, index: number) => ({
          ...item,
          ind: ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
          caseload_unique_id: (
            <div
              onClick={() => handleRowClick(item.id)}
              style={{ cursor: "pointer" }}
            >
              {item.caseload_unique_id}
            </div>
          ),
          status:
            item.status === CONSTANT.STATUS.ACTIVE ? (
              <Typography className="activeStatus">Active</Typography>
            ) : (
              <Typography className="deactiveStatus">Deactive</Typography>
            ),
          action: (
            <div key={index}>
              <IconButton
                size="large"
                className="iconStyleAction"
                color="primary"
                aria-controls={`item-menu-${item?.id}`}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, item?.referral_id, item?.id)}
                sx={{ border: `1px solid #6A2382` }}
              >
                <MoreHorizIcon fontSize="medium" />
              </IconButton>

              <Menu
                id={`item-menu-${item?.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => handleNavigate("view", "Accept")}>
                  <ListItemIcon>
                    <VisibilityIcon />
                  </ListItemIcon>
                  View
                </MenuItem>
                <MenuItem onClick={() => handleAudit(RefIDForPDF)}>
                  <ListItemIcon>
                    <NoteAltIcon />
                  </ListItemIcon>
                  Audit Trail
                </MenuItem>
                <MenuItem onClick={() => handleDownload(RefIDForPDF)}>
                  <ListItemIcon>
                    <FileDownloadOutlinedIcon />
                  </ListItemIcon>
                  Download
                </MenuItem>
                {param !== "closed" && param !== "rejected" && (
                  <MenuItem onClick={() => handleNavigate("reject", "newRef")}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Reject
                  </MenuItem>
                )}
              </Menu>
            </div>
          ),
        }))
      : activateTab.current === 2
      ? referralList?.referrals?.map((item: any, index: number) => ({
          ...item,
          ind: ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
          status:
            item.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
              <Typography className="activeStatus">Education</Typography>
            ) : item.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
              <Typography className="deactiveStatus">Admin</Typography>
            ) : item.submitted_by === CONSTANT.SCOPE.UNKNOWN ? (
              <Typography className="deactiveStatus">Open URL</Typography>
            ) : (
              <></>
            ),
          age: calculateAge(item.patient_dob),
          action: (
            <div key={index}>
              <IconButton
                aria-controls={`item-menu-${item?.id}`}
                aria-haspopup="true"
                className="iconStyleAction"
                onClick={(e) => handleRefClick(e, item?.id)}
                color="primary"
                sx={{ border: `1px solid #6A2382` }}
              >
                <MoreHorizIcon fontSize="medium" />
              </IconButton>

              <Menu
                id={`item-menu-${item?.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {activateTab.current !== 2 && (
                  <MenuItem onClick={() => handleNavigate("view", "newRef")}>
                    <ListItemIcon>
                      <VisibilityIcon />
                    </ListItemIcon>
                    View
                  </MenuItem>
                )}
                {activateTab.current === 2 && (
                  <MenuItem onClick={() => handleNavigate("editRef", "newRef")}>
                    Continue
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                  </MenuItem>
                )}

                {param !== "closed" &&
                  param !== "rejected" &&
                  activateTab.current === 0 && (
                    <MenuItem
                      onClick={() =>
                        handleNavigate("create-caseload", "newRef")
                      }
                    >
                      <ListItemIcon>
                        <AddCircleIcon />
                      </ListItemIcon>
                      Accept Referral
                    </MenuItem>
                  )}

                {param !== "closed" &&
                  param !== "rejected" &&
                  activateTab.current === 0 && (
                    <MenuItem
                      onClick={() => handleNavigate("reject", "newRef")}
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      Reject
                    </MenuItem>
                  )}
              </Menu>
            </div>
          ),
        }))
      : null;

  const handleOnchange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const systemSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const combinedDateTime = `${formData.date} ${formData.time}`; 
    const localMoment = moment.utc(combinedDateTime, "DD/MM/YYYY HH:mm");
    const isoString = localMoment.format("YYYY-MM-DDTHH:mm:ssZ");
    let payload = {
      [refType]: isoString,
    };
    dispatch(addSystemDate(CaseLoadID, payload));
    setTimeout(() => {
      dispatch(
        getCaseloadList({
          page,
          search,
          limit,
          status:
            param !== "closed"
              ? constant.ACTIVE_REFERRAL_STATUS.ACCEPTED
              : constant.ACTIVE_REFERRAL_STATUS.CLOSED,
          filter: isfilterObject,
        })
      );
    }, 500);
    setisSystemOne(false);
  };

  const isFormValid = formData.date !== "" && formData.time !== "";

  const tableWidth = () => {
    const sidebarWidth = !isMediumScreen ? 0 : 270;
    const adjustedWidth = windowWidth - sidebarWidth;
    return `calc(${adjustedWidth}px)`;
  };

  const handleSort = (
    column: { tblName: string },
    sortDirection: "asc" | "desc"
  ) => {
    const { tblName } = column;
    if (tblName === "createdAt") {
      setSortType(sortDirection);
    }
  };

  const TabItem: React.FC<TabItemProps> = ({
    activateTab,
    index,
    title,
    iconSrc,
    onClickHandler,
    isActive,
  }) => (
    <ListItem
      disablePadding
      sx={{
        borderRadius: index === 0 ? "2rem 0 0 2rem" : "0 2rem 2rem 0",
      }}
      onClick={onClickHandler}
      className={isActive ? "nav-active" : "nav-Pagelink"}
    >
      <ListItemButton>
        <ListItemIcon>
          <Avatar
            src={iconSrc}
            alt={title}
            sx={{
              width: {
                xl: 24,
                lg: 20,
                md: 20,
              },
              height: {
                xl: 24,
                lg: 20,
                md: 20,
              },
              borderRadius: "0%",
              margin: "auto",
            }}
          />
        </ListItemIcon>
        <ListItemText primary={title} sx={{ whiteSpace: "nowrap" }} />
      </ListItemButton>
    </ListItem>
  );

  const handleTabClick = (index: number, title: string) => {
    activateTab.current = index;
    setPage(1);
    setLimit(10);
    dispatch(changeTab(title));
    dispatch(SetActiveTab(index));
    dispatch(ResetPagination(true));
    setSortType("");
    setStartDate("");
    setIsfilterObject({});
    setEndDate("");
  };

  useEffect(() => {
    const currentDate = dayjs();
    const formattedDate = currentDate.format("DD/MM/YYYY"); // Format as DD/MM/YYYY
    const formattedTime = currentDate.format("HH:mm"); // Format as HH:mm

    setFormData((prevFormData) => ({
      ...prevFormData,
      date: formattedDate,
      time: formattedTime,
    }));
  }, [isSystemOne]);

  return (
    <>
      <Box className="innerheader">
        {referralAllDetail && (
          <Suspense fallback={<div>Loading...</div>}>
            <PdfRefeeral
              data={referralAllDetail}
              title={constant.PATHWAY_STATUS.CASELOAD_CREATED}
              fname={pdfApply}
            ></PdfRefeeral>
          </Suspense>
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="forMobilecss"
        >
          <Grid item xs={6} className="searchInputbx">
            <Search
              searchCallBack={(e: string) => setSearch(e)}
              searchValue={search}
            />
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            gap={2}
            justifyContent={"end"}
            className="filterOption"
          >
            {activateTab.current === 1 && filterEle()}
            <Suspense fallback={<div>Loading...</div>}>
              <Export
                data={
                  activateTab.current === 0
                    ? referralList?.referrals
                    : activateTab.current === 1
                    ? caseloadList?.ActiveReferrals
                    : null
                }
                columns={
                  activateTab.current === 0
                    ? columns["columnNewRef"]
                    : activateTab.current === 1
                    ? columns["columnAcceptRef"]
                    : null
                }
              ></Export>
            </Suspense>
            {param === "active" &&
            userData?.scope !== constant.SCOPE.CLINICIAN ? (
              <Button
                className={css.btnStyle}
                onClick={() => Navigate("/referral/add")}
                size="large"
                variant="contained"
                sx={{ borderRadius: "30px" }}
                startIcon={<AddCircleIcon />}
              >
                Add new Referral
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          my={2}
        >
          {param === "active" &&
            !loginDetail?.organization?.autoCaseloadCreation &&
            userData?.scope !== constant.SCOPE.CLINICIAN && (
              <Grid item lg={4}>
                <List
                  component="div"
                  disablePadding
                  sx={{ display: "flex", maxWidth: "600px" }}
                >
                  <TabItem
                    activateTab={activateTab}
                    index={0}
                    title="New Referrals"
                    iconSrc={newRef}
                    onClickHandler={() => handleTabClick(0, "New Referrals")}
                    isActive={activateTab.current === 0}
                  />
                  <TabItem
                    activateTab={activateTab}
                    index={1}
                    title="Active Referrals"
                    iconSrc={activeRef}
                    onClickHandler={() => handleTabClick(1, "Active Referrals")}
                    isActive={activateTab.current === 1}
                  />
                </List>
              </Grid>
            )}

          {param === "active" && (
            <Grid
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              width={"50%"}
              ml={"auto"}
            >
              {endDate && (
                <Button
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                  }}
                  sx={{
                    textDecoration: "underline",
                    color: "#6A2382",
                    marginRight: "1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Reset Filter
                </Button>
              )}
              <DateRangePicker
                handleEndDate={(date) => {
                  dispatch(ResetPagination(true));
                  setEndDate(date);
                }}
                handleStartDate={(date) => setStartDate(date)}
                startDateValue={startDate}
                endDateValue={endDate}
                endMinDate={startDate}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Grid
        className="tableWrapper"
        style={{ maxWidth: tableWidth() }}
        sx={{
          'div[role="columnheader"]': {
            span: {
              display: "none",
            },
          },
        }}
      >
        <Table
          handleSort={handleSort}
          className={"userLisingTable"}
          columns={
            activateTab.current === 0
              ? columns["columnNewRef"]
              : activateTab.current === 1
              ? columns["columnAcceptRef"]
              : activateTab.current === 2
              ? columns["columnNewRef"]
              : {}
          }
          data={data}
          setLimitPerPage={(e: number) => setLimit(e)}
          limit={limit}
          paginationTotalRows={
            activateTab.current === 0
              ? referralList?.totalPages
              : caseloadList?.totalPages
          }
          pagination={true}
          setCurrentPage={(e: number) => setPage(e)}
        />
      </Grid>

      <ConfirmationModal
        open={isRejectedRef || isViewRejectedRef}
        handleClose={
          isViewRejectedRef
            ? () => setisViewRejectedRef(false)
            : () => setisRejectedRef(false)
        }
        title="Reject Referral"
        message={
          <Grid item sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                textAlign: "left",
                fontFamily: "poppins",
                marginBottom: "0.5rem",
              }}
            >
              Reason
            </InputLabel>
            <TextField
              multiline
              sx={{ marginBottom: "0.5rem", width: "100%" }}
              placeholder="Write a Reason..."
              rows={4}
              defaultValue={formData.reason}
              disabled={isViewRejectedRef ? true : false}
              name="reason"
              variant="outlined"
              onChange={handleOnchange}
            ></TextField>
          </Grid>
        }
        onConfirm={isViewRejectedRef ? undefined : () => handlerejectByID()}
        confirmLabel={isViewRejectedRef || !formData.reason ? "" : "Reject"}
        cancelLabel={"Cancel"}
        onCancel={
          isViewRejectedRef
            ? () => setisViewRejectedRef(false)
            : () => setisRejectedRef(false)
        }
      />

      <Modal
        open={isSystemOne}
        handleClose={() => {
          setisSystemOne(false);
        }}
        title={"Add SystmOne Date/Time"}
      >
        <Box className={css.clinicModal}>
          <form onSubmit={systemSubmit}>
            <Grid container spacing={2} py={4}>
              <Grid item sx={{ width: "50%" }}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    textAlign: "left",
                    fontFamily: "poppins",
                    marginBottom: "0.5rem",
                  }}
                >
                  Select Date
                </InputLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select Date"
                    value={
                      formData.date ? dayjs(formData.date, "DD/MM/YYYY") : null
                    }
                    onChange={(newValue) => {
                      if (newValue) {
                        handleOnchange({
                          target: {
                            name: "date",
                            value: newValue.format("DD/MM/YYYY"),
                          },
                        });
                      }
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    textAlign: "left",
                    fontFamily: "poppins",
                    marginBottom: "0.5rem",
                  }}
                >
                  Select Time
                </InputLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select Time"
                    value={
                      formData.time && dayjs(formData.time, "HH:mm").isValid()
                        ? dayjs(formData.time, "HH:mm")
                        : null
                    } // Ensure valid Day.js object
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        // Check if the value is valid
                        handleOnchange({
                          target: {
                            name: "time",
                            value: newValue.format("HH:mm"),
                          },
                        });
                      }
                    }}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              className={css.btnWrapper}
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                alignItems: "center",
                mt: "15px",
              }}
            >
              <Button
                sx={{ width: "30%" }}
                variant={"contained"}
                disabled={!isFormValid}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default memo(Index);
