import { useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./index.module.scss";
import CommonField from "./CommonField";

const Communication = ({
  questions,
  patientName,
  setQuestionData,
  isError,
  setIsError,
  setIsDisableContinue,
  setIsNewSchool,
}: any) => {
  let QuestionNumber =
    questions.personalDetails.length + questions.familyHistory.length;
  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.communication];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace(
      "[forename]",
      patientName
    );
    tempPersonDetail[index].answer = value;

    setQuestionData({ ...tempQuestions, communication: tempPersonDetail });
  };

  useEffect(() => {
    setIsError(false);
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.Referral}
      >
        {questions?.communication &&
          questions?.communication?.map((ques: any, index: number) => (
            <>
              {
                <CommonField
                  type={ques.type}
                  index={index}
                  question={ques.question.replaceAll("[forename]", patientName)}
                  answer={ques.answer}
                  options={ques.option}
                  key={"communication"}
                  required={ques.isRequired}
                  question_number={QuestionNumber}
                  questions={questions?.communication}
                  isError={isError}
                  handleAnswerChange={handleAnswerChange}
                  ques={ques}
                  setIsDisableContinue={setIsDisableContinue}
                  totalQuestion={questions?.communication?.length}
                />
              }
            </>
          ))}
      </Grid>
    </>
  );
};

export default Communication;
