import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Referral } = Dictionary

const referral = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + Referral.add(), data)
  },
  edit:  async (id:string, data: {}) => {
    return axios.put(BASE_URL + Referral.edit(id), data)
  },
  addReport: async (data: {}) => {
    return axios.post(BASE_URL + Referral.addReport(), data)
  },
  updateReport: async (id:string, data: {}) => {
    return axios.put(BASE_URL + Referral.updateReport(id), data)
  },
  addOpenReferral: async (data: {}) => {
    return axios.post(BASE_URL + Referral.addOpenReferral(), data)
  },
  view: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Referral.view(), { params: paramsObj })
  },
  viewReport: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Referral.viewReport(), { params: paramsObj })
  },
  viewAllDetail: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Referral.viewByID(), { params: paramsObj })
  },
  getReferralById: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Referral.userById(), { params: paramsObj })
  },
  getGPList: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Referral.gpDetaillist(), { params: paramsObj })
  },
  rejectReferral: async (id:string, data: {}) => {
    return axios.post(BASE_URL + Referral.rejectReferral(id), data)
  },
  allSchool: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Referral.allSchool(), { params: paramsObj })
  },

}
export default referral
