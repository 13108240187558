import React, {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  StepLabel,
  Step,
  Stepper,
  Grid,
  Box,
  StepConnector,
  Tab,
  Tabs,
  Card,
  Typography,
  CardContent,
  Chip,
  Divider,
  Paper,
  Tooltip,
} from "@mui/material";
import { TabPanelProps } from "./interface";
import classes from "./index.module.scss";
import {
  caseDocumentsById,
  changeTab,
  clearTask,
  clearUnmountData,
  getCaseloadById,
  getChatConversation,
  getParentReport,
  getSchoolReport,
  removeChat,
  setCaseloadCurentTab,
  setConversationId,
  setCurentTab,
  setRefClosedStatus,
  setSelectedUser,
  setUserListActive,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { User, UserData, CurrentUserData } from "./interface";
// import SchoolReport from "./SchoolReport";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import constant from "../../../constants/constant";
import atoms from "../../../components/atoms";
// import organism from '../../../components/organism'
import moment from "moment";
import { formatNHSNumber } from "../../../constants/utils";
// import ParentReport from "./ParentReport";
const TaskCase = React.lazy(() => import("./TaskCase"));
const CaseDocuments = React.lazy(() => import("./CaseDocuments"));
const Messages = React.lazy(() => import("./Messages"));
const MdtReview = React.lazy(() => import("./MdtReview"));
const SchoolReport = React.lazy(() => import("./SchoolReport"));
const ParentReport = React.lazy(() => import("./ParentReport"));
const OverviewCaseload = React.lazy(
  () => import("../../../components/organism/overviewCaseload")
);
// const { OverviewCaseload } = organism
const CaseloadReducer = (state: any) => state?.CaseloadReducer;
const layoutReducer = (state: any) => state?.LayoutReducer;
const CreateCaseload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params: any = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const caseloadId: any = queryParams.get("id");
  const [activeTab, setActiveTab] = useState(0);
  const [StepActivate, setStepActivate] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isDisabledMDTReview, setisDisabledMDTReview] = useState(true);
  const [schoolDetails, setSchoolDetails] = useState<any>("");
  const [digital_literacy, setdigital_literacy] = useState();
  const [parentDetails, setParentDetails] = useState<any>("");
  const [additional_parent_carers_details, setadditionalParentDetail] =
    useState<any>([]);
  const [overviewDetails, setOverviewDetails] = useState<any>();
  const [clinicialDetails, setClinicialDetails] = useState([]);
  const [taskCount, setTaskCount] = useState({
    completed: 0,
    total: 0,
  });
  const [columns, setColumns] = useState([
    {
      name: "#",
      cell: (item: any, ind: number) => <span>{ind + 1}</span>,
      width: "50px",
    },
    {
      name: "Clinicians Name",
      cell: (item: any, ind: number) => (
        <span>{item?.user?.first_name + " " + item?.user?.last_name}</span>
      ),
      sortable: false,
      minWidth: "35%",
    },
    {
      name: "Email Address",
      cell: (item: any, ind: number) => <span>{item?.user?.email}</span>,
      sortable: false,
      minWidth: "30%",
    },
    {
      name: "Job Role",
      cell: (item: any, ind: number) => <span>{item?.user?.jobRole}</span>,
      sortable: false,
      minWidth: "25%",
    },
  ]);
  const caseloadData = createSelector(CaseloadReducer, (state) => ({
    caseloadByIdData: state.caseloadByIdData,
  }));

  const { caseloadByIdData } = useSelector(caseloadData);

  const layoutURLData = createSelector(layoutReducer, (state) => ({
    currentTabCaseload: state.currentTabCaseload,
  }));
  const { currentTabCaseload } = useSelector(layoutURLData);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setActiveTab(newValue);
    dispatch(setCaseloadCurentTab(newValue));
  };
  const customSteps: any = [
    "Referral Received",
    "Education Setting Report Received",
    "Parent/Carer Report Received",
    `Task (${caseloadByIdData?.completed_task}/${
      caseloadByIdData?.completed_task + caseloadByIdData?.pending_task
    })`,
    "Ready For Clinical Review",
    "Outcome Agreed",
  ];
  const customStepsforParent: any = [
    "Referral Received",
    "Parent/Carer Report Received",
    "Education Setting Report Received",
    `Task (${caseloadByIdData?.completed_task}/${
      caseloadByIdData?.completed_task + caseloadByIdData?.pending_task
    })`,
    "Ready For Clinical Review",
    "Outcome Agreed",
  ];

  const additinalSteps: any = [
    "Referral Received",
    "Education Setting Report Received",
    "Parent/Carer Report Received",
    "Ready for MDT Review",
    "Outcome Agreed",
  ];

  const handlePathwayStatus = useCallback(() => {
    for (let i = 0; i < caseloadByIdData?.pathway_status?.length; i++) {
      const largestValue = Math.max(
        ...caseloadByIdData?.pathway_status.map(Number)
      );
      switch (largestValue) {
        case 1:
          setStepActivate(1);
          if (
            caseloadByIdData?.completed_task === 0 &&
            caseloadByIdData?.pending_task === 0
          ) {
            const updateStep: any = customSteps.filter(
              (_: any, index: number) => index !== 3
            );
            setSteps(updateStep);
            setisDisabledMDTReview(true);
          } else {
            setSteps(customSteps);
            setisDisabledMDTReview(false);
          }
          break;
        case 2:
          if (
            caseloadByIdData?.pathway_status.includes(
              constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED
            )
          ) {
            if (caseloadByIdData?.pending_task === 0) {
              setSteps(additinalSteps);
              setStepActivate(4);
              setisDisabledMDTReview(false);
            } else {
              setStepActivate(3);
              setSteps(customSteps);
            }
          } else {
            setStepActivate(2);
            if (caseloadByIdData?.completed_task === 0) {
              setSteps(additinalSteps);
            }
          }
          break;
        case 3:
          if (
            caseloadByIdData?.pathway_status.includes(
              constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED
            )
          ) {
            if (caseloadByIdData?.pending_task === 0) {
              setSteps(customSteps);
              setStepActivate(5);
              setisDisabledMDTReview(false);
            } else {
              setStepActivate(4);
              setSteps(customSteps);
            }
          } else {
            setStepActivate(2);
            if (caseloadByIdData?.completed_task === 0) {
              const updateStep: any = customStepsforParent.filter(
                (_: any, index: number) => index !== 3
              );
              setSteps(updateStep);
            } else {
              const updateStep: any = customStepsforParent.filter(
                (_: any, index: number) => index !== 6
              );
              setSteps(updateStep);
            }
          }

          break;
        case 4:
          if (
            caseloadByIdData?.pathway_status.includes(
              constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED
            ) &&
            caseloadByIdData?.pathway_status.includes(
              constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED
            )
          ) {
            setSteps(customSteps);
            setStepActivate(4);
          } else if (
            caseloadByIdData?.pathway_status.includes(
              constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED
            )
          ) {
            setSteps(customSteps);
            setStepActivate(2);
          } else if (
            caseloadByIdData?.pathway_status.includes(
              constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED
            )
          ) {
            setSteps(customSteps);
            setStepActivate(3);
          } else {
            setSteps(customSteps);
            setStepActivate(1);
            setisDisabledMDTReview(true);
          }
          break;
        case 5:
          setStepActivate(5);
          setSteps(customSteps);
          setisDisabledMDTReview(false);
          break;
        case 6:
          setStepActivate(6);
          setSteps(customSteps);
          if (caseloadByIdData?.pending_task === 0) {
            setSteps(additinalSteps);
            setisDisabledMDTReview(false);
          } else {
            setSteps(customSteps);
            setisDisabledMDTReview(false);
          }
          break;
        default:
          break;
      }
    }
  }, [caseloadByIdData?.pathway_status]);

  useLayoutEffect(() => {
    handlePathwayStatus();
  }, [handlePathwayStatus]);

  // Memoize the value based on some dependencies
  const memoizedIsDisabledMDTReview = useMemo(() => {
    // Perform some computation or logic to determine the memoized value
    return isDisabledMDTReview;
  }, [isDisabledMDTReview]);

  useLayoutEffect(() => {
    setTaskCount({
      completed: caseloadByIdData?.completed_task,
      total: caseloadByIdData?.completed_task + caseloadByIdData?.pending_task,
    });

    if (caseloadByIdData?.caseload_members) {
      const tempClinicial: any = [];
      let parentDetail: any;
      let additional_parent_carers_details: any = [];
      let schoolDetail: any;
      let tempDetail: any = {}; // Initialize schoolDetail as an object with a user property

      caseloadByIdData?.caseload_members.forEach((item: any) => {
        if (item?.user?.scope === constant.SCOPE.CLINICIAN) {
          tempClinicial.push({
            value: item?.user?.id,
            label: item?.user?.first_name + " " + item?.user?.last_name,
            email: item?.user?.email,
            jobRole: item?.user?.jobRole,
          });
        } else if (
          item?.user?.scope === constant.SCOPE.PARENT &&
          item?.role === constant.ROLE.ALL_ACCESS
        ) {
          parentDetail = item?.user;
        } else if (
          item?.user?.scope === constant.SCOPE.PARENT &&
          item?.role === constant.ROLE.RESTRICTED_ACCESS
        ) {
          additional_parent_carers_details.push(item);
        } else if (item?.user?.scope === constant.SCOPE.SCHOOL) {
          schoolDetail = item?.user;
        }
      });

      if (!schoolDetail) {
        tempDetail.school_name = caseloadByIdData?.school_name;
        tempDetail.address = caseloadByIdData?.school_address;
        schoolDetail = tempDetail;
      }

      setClinicialDetails(tempClinicial);

      if (parentDetail) {
        setParentDetails(parentDetail);
      }

      if (additional_parent_carers_details) {
        setadditionalParentDetail(additional_parent_carers_details);
      }

      if (schoolDetail) {
        setSchoolDetails(schoolDetail);
      }
      setOverviewDetails({
        isNewSchool: caseloadByIdData?.isNewSchool,
        isHomeSchooling: caseloadByIdData?.isHomeSchooling,
        digital_literacy: caseloadByIdData?.digital_literacy,
        additional_parent_carers_details: additional_parent_carers_details,
        parent_detail: parentDetail,
        school_detail: schoolDetail,
        patient_detail: {
          dob: caseloadByIdData?.patient_dob,
          gender: caseloadByIdData?.patient_gender,
          name: caseloadByIdData?.patient_name,
          nhs_number: caseloadByIdData?.nhs_number,
          email: caseloadByIdData?.email,
          address: caseloadByIdData?.address,
        },
        gp_details: {
          name: caseloadByIdData?.gp_details?.name,
          address: caseloadByIdData?.gp_details?.address,
        },
        clinicialDetails: tempClinicial,
      });
    }
  }, [caseloadByIdData]);

  useEffect(() => {
    dispatch(changeTab("Referral Overview"));
    if (caseloadId) {
      dispatch(getCaseloadById(caseloadId));
      dispatch(caseDocumentsById(caseloadId, "navigate"));
    }

    return () => {
      dispatch(clearUnmountData());
      dispatch(setCaseloadCurentTab(0));
      dispatch(clearTask());
    };
    // Fetch documents if casedocList is empty
  }, [caseloadId]);

  useLayoutEffect(() => {
    const CurrentPyStatus = caseloadByIdData?.pathway_status
      ?.slice(-1)
      .toString();
    if (CurrentPyStatus === constant?.PATHWAY_STATUS.CASELOAD_CLOSED) {
      dispatch(setRefClosedStatus(true));
    } else {
      dispatch(setRefClosedStatus(false));
    }
    setdigital_literacy(caseloadByIdData?.digital_literacy);
  }, [caseloadByIdData]);

  const userData: CurrentUserData | null = JSON.parse(
    localStorage.getItem("loginResponse") || "null"
  );

  return (
    <>
      <Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={6} md={6} className={classes.allTaskSection}>
            <Card
              variant="outlined"
              sx={{ borderRadius: "10px", padding: "1rem" }}
            >
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                    pt: "0!important",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>Patient Name :</strong>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#444C55",
                      display: "-webkit-box",
                      textTransform: "capitalize",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis", // Optional: Adds ellipsis at the end of the truncated text
                    }}
                  >
                    <Tooltip
                      title={caseloadByIdData?.patient_name}
                      className={classes.text_wrap}
                    >
                      <span>{caseloadByIdData?.patient_name}</span>
                    </Tooltip>
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                    pt: "0!important",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>DOB :</strong>
                  </Typography>
                  <Typography sx={{ color: "#444C55" }}>
                    {moment(caseloadByIdData?.patient_dob).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                    pt: "0!important",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>Gender :</strong>
                  </Typography>
                  <Typography sx={{ color: "#444C55" }}>
                    {caseloadByIdData?.patient_gender}
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                    pt: "0!important",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>NHS Number :</strong>
                  </Typography>
                  <Typography sx={{ color: "#444C55" }}>
                    {formatNHSNumber(caseloadByIdData?.nhs_number)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={6} md={6}>
            <Card
              variant="outlined"
              sx={{ borderRadius: "10px", padding: "1rem" }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sx={{
                  gap: 2,
                  display: "flex",
                  alignItems: "center",
                  pt: "0!important",
                }}
              >
                <Typography variant="subtitle1">
                  <strong style={{ whiteSpace: "nowrap" }}>GP Name :</strong>
                </Typography>
                <Typography
                  sx={{
                    color: "#444C55",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Tooltip
                    title={caseloadByIdData?.gp_name}
                    className={classes.text_wrap}
                  >
                    <span>{caseloadByIdData?.gp_name}</span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sx={{
                  gap: 2,
                  display: "flex",
                  alignItems: "center",
                  pt: "0!important",
                }}
              >
                <Typography variant="subtitle1">
                  <strong style={{ whiteSpace: "nowrap" }}>GP Address :</strong>
                </Typography>
                <Typography
                  sx={{
                    color: "#444C55",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis", // Optional: Adds ellipsis at the end of the truncated text
                  }}
                >
                  <Tooltip
                    title={caseloadByIdData?.gp_address}
                    className={classes.text_wrap}
                  >
                    <span>{caseloadByIdData?.gp_address}</span>
                  </Tooltip>
                </Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Grid sx={{ my: 3 }}>
          <Box className="stepperWrapper">
            <Stepper activeStep={StepActivate} alternativeLabel>
              {steps &&
                steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
            </Stepper>
          </Box>
        </Grid>

        <Card variant="outlined" sx={{ p: 0 }} style={{ borderRadius: "1rem" }}>
          <CardContent sx={{ px: 0, pb: "0!important" }}>
            <Grid
              columns={12}
              sx={{ mt: { md: "0" }, ml: { md: "0" }, width: "100%" }}
            >
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    borderBottom: "1px solid #CCCCCC",
                  }}
                >
                  <Tabs
                    value={currentTabCaseload}
                    className={classes.tabContainer}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab value={0} label="Overview" />
                    <Tab value={1} label="Education Report" />
                    <Tab value={2} label="Parent / Carer Report " />
                    <Tab value={3} label="Uploads" />
                    <Tab value={4} label="All Tasks " />
                    {constant?.SCOPE?.CLINICIAN !== userData?.scope && (
                      <Tab value={5} label="Messages " />
                    )}
                    <Tab
                      value={6}
                      label="Clinical Review "
                      disabled={memoizedIsDisabledMDTReview}
                      className={
                        caseloadByIdData?.pending_task !== 0
                          ? "tabdisabled"
                          : "tabEnabled"
                      }
                    />
                  </Tabs>
                </Box>
             

                <Grid
                  role="tabpanel"
                  className={classes.stepperContent}
                  p={2}
                  sx={{
                    "div.MuiGrid-root": {
                      margin: {
                        xs: "0rem",
                        md: "inherit",
                        lg: "inherit",
                        sm: "0rem",
                      },
                    },
                  }}
                >
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Loading...
                      </div>
                    }
                  >
                    {currentTabCaseload === 0 && (
                      <OverviewCaseload referralCaseload={overviewDetails} />
                    )}
                    {currentTabCaseload === 1 && (
                      <SchoolReport
                        isHomeSchooling={caseloadByIdData?.isHomeSchooling}
                      />
                    )}
                    {currentTabCaseload === 2 && (
                      <ParentReport
                        digital_literacy={caseloadByIdData?.digital_literacy}
                        patient={caseloadByIdData?.patient_name}
                      />
                    )}
                    {currentTabCaseload === 3 && (
                      <CaseDocuments
                        _caseLoadId={caseloadId}
                        patient={caseloadByIdData?.patient_name}
                      />
                    )}
                    {currentTabCaseload === 4 && (
                      <TaskCase caseload_id={caseloadId} />
                    )}
                    {currentTabCaseload === 5 && (
                      <Messages _caseLoadId={caseloadId} />
                    )}
                    {currentTabCaseload === 6 && (
                      <MdtReview
                        caseloadByIdData={caseloadByIdData}
                        _caseLoadId={caseloadId}
                      />
                    )}
                  </Suspense>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default memo(CreateCaseload);
