import React, { memo } from 'react'
import { Button } from '@mui/material';
import classes from "./index.module.scss"
const Index = ({ variant, fullWidth, children, className, type, sx, disabled, onClick, startIcon, endIcon }: any) => {
    return (

        <>
            <Button type={type} fullWidth={fullWidth} className={`${classes.text_transform}${className} ${classes.btnGlobal}`} variant={variant} sx={{ ...sx, textTransform: 'none' }} disabled={disabled} onClick={onClick} endIcon={endIcon} startIcon={startIcon} >
                {children}
            </Button>
        </>
    )
}

export default memo(Index)