const Chat = {
    add: () => 'conversations',
    sentmsg: () => 'messages',
    getmsg: (id: any) => `messages/conversation/${id}`,
    getmsgConversation: (id:string) => `conversations/${id}`,


    // view: () => 'task',
    // getById: (id: string) => `caseload/${id}/users`,
    // getTaskDocById: (id: string) => `task/${id}/documents`,
    // postById: (id: string) => `task/${id}/submit`,

}
export default Chat