import React, { memo, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { createSelector } from "reselect";
import { changeTab, getReferalCaseloadByID} from '../../../../redux/action';
import {  useParams } from 'react-router-dom';
import organism from '../../../../components/organism'
import { CurrentUserData } from '../../ManageCaseLoad/interface';
const { CaseloadView } = organism

const CreateCaseload = () => {
    const dispatch = useDispatch()
    const params: any = useParams();
    const Navigate = useNavigate()

    const referralCaseloadReducer = (state: any) => state?.referralReducer

    const referralCaseloadData = createSelector(
        referralCaseloadReducer,
        (state) => ({
            referralCaseload: state?.referralCaseload,
        })
    );


    const { referralCaseload } = useSelector(referralCaseloadData);


    const userData: CurrentUserData | null = JSON.parse(
        localStorage.getItem("loginResponse") || "null"
      );
    

      useEffect(() => {
        dispatch(getReferalCaseloadByID({ "ReferralId": params.id }));
        
        if (referralCaseload?.isRedFlag) {
            if (userData?.organization.autoCaseloadCreation) {
                Navigate('/manage-caseload/active');
            } else {
                dispatch(changeTab('Accept Referral'));
            }
        } else {
            dispatch(changeTab('Accept Referral'));
        }
    }, [referralCaseload?.isRedFlag, userData?.organization.autoCaseloadCreation, params.id, dispatch, Navigate]);
    





    return (
        <Grid>
            <CaseloadView referralCaseload={referralCaseload} />
        </Grid>
    )
}

export default memo(CreateCaseload)