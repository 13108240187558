
import { RESPONSE_CASELOAD_BY_ID, RESPONSE_PARENT_REPORT,RESPONSE_TASKDOC,RED_FLAG_LIST,
  RESPONSE_SCHOOL_REPORT, CASE_DOCUMENTS_BY_ID_FAILURE, CASE_DOCUMENTS_BY_ID_REQUEST, CASE_DOCUMENTS_BY_ID_SUCCESS, RESPONSE_CASELOAD, RESPONSE_CASHUSER, RESPONSE_TASK, UPLOAD_FILE, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS, CREATE_CONVERSATIONS_SUCCESS, CREATE_CONVERSATIONS, CREATE_CONVERSATIONS_FAILURE, RESPONSE_CHATMSG, RESPONSE_CHATCONVERTSATION, SET_LOADING, RESPONSE_FULL_CASELOAD, RESPONSE_CHATMSG_CLEAR, CANCEL_CASELOADLIST_REQ, CLEAR_REDFLAG_DATA, CLEAR_UNMOUNT_DATA, CLEAR_TASK
} from './actionType'

let initialValues = {
  caseloadData: [],
  caseloadFullData: [],
  caseloadByIdData: ''
}
let initialTaskList = {
  taskListdata: []
}

// let initialaddTask = {
//   initialaddTask: []
// }


let initialCaseLoadUserList = {
  caseLoadUserListdata: []
}


let initialMsgList = {
  initialMsgList: [],
      loading: false
}

let initialConversationList = {
  initialConversationList: []
}

let initialParentReport = {
  report: []
}
let initialSchoolReport = {
  report: []
}

const initialState = {
  loading: false,
  error: null,
};


const initialDocState = {
  data: null,
  loading: false,
  error: null,
};

const initialRedFlag ={
  data: null,
}

export const CaseloadReducer = (state = initialValues, action: any) => {

  switch (action.type) {

    case RESPONSE_CASELOAD:
      state = { ...state, caseloadData: action?.payload?.data };
      break;
      case CANCEL_CASELOADLIST_REQ:
        state = { ...state, caseloadData: []};
        break;  
      case RESPONSE_FULL_CASELOAD:
        state = { ...state, caseloadFullData: action?.payload?.data };
        break;  
    case RESPONSE_CASELOAD_BY_ID:
      state = { ...state, caseloadByIdData: action?.payload?.data?.data };
      break;
      case CLEAR_UNMOUNT_DATA:
        state = { ...state, caseloadByIdData: '' };
        break;  
    default:
      state = { ...state };
      break;
  }
  return state;
}


export const TaskListReducer = (state = initialTaskList, action: any) => {

  switch (action.type) {

    case RESPONSE_TASK:
      state = { ...state, taskListdata: action?.payload?.data };
      break;
      case CLEAR_TASK:
      state = { ...state, taskListdata: [] };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}



//  Case Load User Reducer
export const CaseLoadUserReducer = (state = initialCaseLoadUserList, action: any) => {

  switch (action.type) {

    case RESPONSE_CASHUSER:
      state = { ...state, caseLoadUserListdata: action?.payload };
      break;
      case CLEAR_TASK:
        state = { ...state, caseLoadUserListdata: [] };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export const taskDocReducer = (state = initialCaseLoadUserList, action: any) => {
 
  switch (action.type) {
     
      case RESPONSE_TASKDOC:
          state = { ...state, caseLoadUserListdata: action?.payload };
          break;
   
      default:
          state = { ...state };
          break;
  }
  return state;
}

export const chatMsgReducer = (state = initialMsgList, action: any) => {
   switch (action.type) {
    case RESPONSE_CHATMSG:
        return {
            ...state,
            initialMsgList: action.payload,
            loading: action.loading
        };
    case SET_LOADING:
        return {
            ...state,
            loading: action.loading
        };
    case RESPONSE_CHATMSG_CLEAR:
      return {
        ...state,
        initialMsgList: [],
        loading: action.loading
      }    
    default:
        return state;
}
}

export const chatConversationReducer = (state = initialConversationList, action: any) => {
 
  switch (action.type) {
     
      case RESPONSE_CHATCONVERTSATION:
          state = { ...state, initialConversationList: action?.payload };
          break;
          case RESPONSE_CHATMSG_CLEAR:
            return {
              ...state,
              initialConversationList: [],
            }  
      default:
          state = { ...state };
          break;
  }
  return state;
}




export const UploadDocuments = (state = initialState, action: any) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return { ...state, loading: true };
    case UPLOAD_FILE_SUCCESS:
      return { ...state, loading: false };
    case UPLOAD_FILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};



export const caseDocumentReducer = (state = initialDocState, action: any) => {
  switch (action.type) {
    case CASE_DOCUMENTS_BY_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case CASE_DOCUMENTS_BY_ID_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case CASE_DOCUMENTS_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };
      case CLEAR_UNMOUNT_DATA:
        return { ...state, data: [] };    
    default:
      return state;
  }
};




export const createConversationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_CONVERSATIONS:
      return { ...state, loading: true, error: null };
    case CREATE_CONVERSATIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case CREATE_CONVERSATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const parentReducer = (state = initialParentReport, action: any) => {
  switch (action.type) {
    case RESPONSE_PARENT_REPORT:
      return { ...state, report: action.payload && action.payload[0] };
      case CLEAR_UNMOUNT_DATA:
        return { ...state, report: [] };  
    default:
      return state;
  }
};
export const redFlag = (state = initialRedFlag, action: any) => {
  switch (action.type) {
    case RED_FLAG_LIST:
      return { ...state, data: action.payload  };
      case CLEAR_REDFLAG_DATA:
        return { ...state, data: null  };  
    default:
      return state;
  }
};

export const schoolReducer = (state = initialSchoolReport, action: any) => {
  switch (action.type) {
    case RESPONSE_SCHOOL_REPORT:
      return { ...state, report: action.payload && action.payload[0] };
      case CLEAR_UNMOUNT_DATA:
        return { ...state, report: [] };  
    default:
      return state;
  }
}

