import React, { Suspense, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import atoms from '../../../components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { changeTab, viewAuditTrail } from '../../../redux/action';
import moment from 'moment';
import { useParams } from 'react-router-dom';
const Export = React.lazy(() => import('../../../components/atoms/Export'));

const { Table, Search } = atoms

const Index = () => {
    const AuditReducer = (state: any) => state?.auditTrailReducer
    let dispatch = useDispatch()
    const { id }:any = useParams();
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const columns: any = [
        {
            name: '#',
            cell: (row: any, index: number) => (((page - 1) * limit) + index + 1).toString().padStart(2, '0'),
            width: '50px',
            tblName: ''

        },
        // {
        //     name: 'ID',
        //     cell: (item: any, ind: number) => <span>{item.id}</span>,
        //     sortable: false,
        //     width: "15%",
        //     tblName: 'id'
        // },
        {
            name: 'Action',
            cell: (item: any, ind: number) => <span>{item.message}</span>,
            sortable: false,
            tblName: 'message'
        },
        {
            name: 'Date/Time',
            cell: (item: any, ind: number) => <span>{moment(item.createdAt).format("DD/MM/YYYY, hh:mm a")}</span>,
            sortable: false,
            tblName: 'createdAt'
        }
    ]
    const AuditData = createSelector(
        AuditReducer,
        (state) => ({
            auditResponse: state?.auditResponse,
        })
    );

    const { auditResponse } = useSelector(AuditData);
    useEffect(() => {
        dispatch(changeTab('Audit Trail'))
        dispatch(viewAuditTrail(id, {}))
    }, [])

    return (
        <>
            <Box className="innerheader">
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} className="searchInputbx">

                    </Grid>
                    <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Export data={auditResponse} columns={columns}></Export>
                        </Suspense>
                    </Grid>
                </Grid>
            </Box>
            <Grid className='tableWrapper'>
                <Table
                
                    columns={columns}
                    data={auditResponse}
                    // paginationTotalRows={userList.totalPages}
                    setLimitPerPage={(e: any) => setLimit(e)}
                    limit={limit}
                    pagination={true}
                    setCurrentPage={(e: any) => setPage(e)}
                />
            </Grid>
        </>

    )
}

export default Index