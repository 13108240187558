import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import './Header.css';
import css from "./index.module.scss"
import { LogoutAdmin, setOpenReferralUrl } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import Modal from '../../atoms/Modal';
import { Button, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Get_Login_Response } from '../../../redux/auth/actionType';
import { useResponsive } from '../../atoms/useResponsive';
export interface CurrentUserData {
  id: string;
  first_name: string,
  last_name: string,
  email: string,
  organization?:any
}
interface State extends SnackbarOrigin {
  open: boolean;
}

const Header = ({ handleOpenDrawer }: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isMediumScreen} = useResponsive()

  const handleDrawerToggle = () => {
    handleOpenDrawer()
  };


  const layoutReducer = (state: any) => state?.LayoutReducer
  const loginReducer = (state: any) => state?.Login_Response
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [openReferralUrl, setOpenReferralUrl] = React.useState(false);
  const [state, setState] = React.useState<any>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { vertical, horizontal, open } = state;

  const layoutData = createSelector(
    layoutReducer,
    (state) => ({
      currentTab: state.currentTab,

    })
  );

  const layoutURLData = createSelector(
    layoutReducer,
    (state) => ({
      openRefurl: state.openurlposL,

    })
  );
  const { openRefurl } = useSelector(layoutURLData);


  const loginData = createSelector(
    loginReducer,
    (state) => ({
      userData: state.loginResponse,

    })
  );

  const loginDetail: CurrentUserData = JSON.parse(
    localStorage.getItem("loginResponse") || 'null'
  );
  const url:any = `https://${loginDetail?.organization?.short_name}.${process.env.REACT_APP_BASE_URL}referral-form`
  const { currentTab } = useSelector(layoutData);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (newState: SnackbarOrigin) => {
    
    navigator.clipboard.writeText(url)
    setState({ ...newState, open: true });

  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const { userData } = useSelector(loginData);
  const navigateToLink = () => {
    navigate('/profile')
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    dispatch({ type: Get_Login_Response, payload: loginDetail })
  }, [])

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={navigateToLink}>Profile</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      {/* <MenuItem onClick={() => setOpenReferralUrl(true)}>Open Referral Url</MenuItem> */}
      <MenuItem onClick={() => {
        dispatch(LogoutAdmin(navigate))
        handleMenuClose()
      }}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
     

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>

    </Menu>
  );
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({   open: false,
      vertical: 'top',
      horizontal: 'center',});
  };

  return (

    <Box className={css.constianer}>
      <Box className="s-layout__content header-bg justify-content-between" sx={{marginLeft : !isMediumScreen ? 0 : '240px',width:!isMediumScreen ? '100%' : 'calc(100% - 240px)'}}>
        {/* style={{width:'100%'}} */}
        <Box sx={{ flexGrow: 2 }} >
          <AppBar position="static" sx={{ width: '100%', display: { xs: 'block ', sm: 'block' }, background: 'transparent', color: '#637381', boxShadow: 'none', alignItems: 'center' }} >
            <Toolbar  >
              {/* sx={{ color: 'red',}} */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{  display: { xs: 'block', sm: 'block',lg:'none',md:'none' },}}
                className={css.buttonToggle}
              >
                <MenuIcon />
              </IconButton>
  
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  color: 'black',
                  fontFamily: 'poppins'
                  // display: {xs: 'none', sm: 'block' }
                }}
              >
                 {currentTab}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: 'flex', md: 'flex' } }} className='userAccountBadge' component="button" onClick={handleProfileMenuOpen}>
 
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"

                  color="inherit"
                  className='header_icon'
                  sx={{ mr: 1 }}
                >
                  <AccountCircle />
                </IconButton>
                <Box className="u_details">
                  <Typography variant="h6" textTransform={'capitalize'}>{userData?.first_name} {userData?.last_name}</Typography>
                  <Typography variant="body2">{userData?.email}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </Box>
      </Box>

      <>
        <Modal open={openRefurl} handleClose={() => {
          dispatch(setOpenReferralUrl(false))
        }} title={"Open Referral URL"}>
          <Box className={css.clinicModal}>
            <Grid sx={{ width: '100%', textAlign: 'center', py: 2, display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Grid sx={{ width: '29rem', height: '50px', border: "1px solid gray", borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{url}</Grid>
              <IconButton aria-label="copy" onClick={() => handleClick({ vertical: 'top', horizontal: 'right' })}>
                <ContentCopyIcon />
              </IconButton>

            </Grid>
            <Grid className={css.btnWrapper} sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItem: 'center', mt: '15px' }}>
              <Button sx={{ width: '30%' }} variant={"outlined"} onClick={() => dispatch(setOpenReferralUrl(false))} >Cancel</Button>
            </Grid>
          </Box>
          <Box sx={{ width: 500 }}>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              autoHideDuration={2000}
              transitionDuration={500}
              onClose={handleClose}
              message="Copied"
              key={vertical + horizontal}
            />
          </Box>
        </Modal>
      </>
    </Box>
  );
}

export default React.memo(Header)