import { useState } from 'react';

export const useClearSearch = () => {
  const [search, setSearch] = useState("")

  const clearSearch = () => setSearch('');

  return {
    setSearch,
    search,
    clearSearch
  };
};
