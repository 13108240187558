export const VIEW_REFERRAL = "VIEW_REFERRAL"
export const EDIT_REFERRAL = "EDIT_REFERRAL"
export const VIEW_FULL_REFERRAL = "VIEW_FULL_REFERRAL"
export const ADD_REFERRAL = "ADD_REFERRAL"
export const ADD_REPORT = "ADD_REPORT"
export const UPDATE_REPORT = "UPDATE_REPORT"
export const REFERRAL_RESPONSE = "REFERRAL_RESPONSE"
export const REFERRAL_FULL_RESPONSE = "REFERRAL_FULL_RESPONSE"
export const GET_REFERRAL_CASELOAD_DETAIL = "GET_REFERRAL_CASELOAD_DETAIL"
export const REFERRAL_CASELOAD_RESPONSE = "REFERRAL_CASELOAD_RESPONSE"
export const GET_REFERRAL_ALL_DETAIL = "GET_REFERRAL_ALL_DETAIL"
export const GET_REFERRAL_ALL_RESPONSE = "GET_REFERRAL_ALL_RESPONSE"
export const GET_GP_LIST = "GET_GP_LIST"
export const GP_LIST_RESPONSE = "GP_LIST_RESPONSE" 
export const VIEW_REPORT = 'VIEW_REPORT'
export const DASH_REPORT = "DASH_REPORT"
export const REJECT_REFERRAL_BY_ID = 'REJECT_REFERRAL_BY_ID'
export const CANCEL_REFERRAL_REQ = "CANCEL_REFERRAL_REQ"
export const DASHBOARD_RESPONSE = "DASHBOARD_RESPONSE"
export const SCHOOL_LISTING = "SCHOOL_LISTING"
export const SCHOOL_RESPONSE = "SCHOOL_RESPONSE"