import { useTheme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

// Custom hook to determine screen sizes
export const useResponsive = () => {
  const theme = useTheme();
  let loginDetailString = localStorage.getItem("loginResponse");
  let loginDetail = loginDetailString ? JSON.parse(loginDetailString) : {};
  // Define media query matches for each breakpoint
  const isExSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isExLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setDeviceHeight(window.innerHeight)

    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return {
    isExSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExLargeScreen,
    windowWidth,
    deviceHeight,
    loginDetail
  };
};

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px