import React, { Suspense, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "../../../components/atoms/Button";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { question } from "./question";
import { openURLQuestion } from "./openURLQuestion";
import { VerifyDomain, addReferral, changeTab, clearDomain, editReferral, getAllGPList, getOpenUserList, localDomain, setCurentTab, viewAllReferralDetail } from "../../../redux/action"
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import CONSTANT from '../../../constants/constant';
import { getUserList } from '../../../redux/action';
import classes from './index.module.scss'
import atoms from "../../../components/atoms";
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";


const { Modal } = atoms
const userReducer = (state: any) => state?.userManagementReducer
const referralReducer = (state: any) => state?.referralReducer
const authReducer = (state: any) => state?.Login_Response
const AdditionalParentDetail = React.lazy(() => import('./AdditionalParentDetail'));
const DigitalLiteracy = React.lazy(() => import('./DigitalLiteracy'));
const ReferrerDetail = React.lazy(() => import('./ReferrerDetail'));
const SupportSoFar = React.lazy(() => import('./SupportSoFar'));
const Safegauding = React.lazy(() => import('./Safegauding'));
const Reason = React.lazy(() => import('./Reason'));
const PersonDetail = React.lazy(() => import('./PersonDetail'));
const ParentCarersDetail = React.lazy(() => import('./ParentCarersDetail'));
const GPDeatil = React.lazy(() => import('./GPDeatil'));
const Consent = React.lazy(() => import('./Consent'));
const SideStepper = React.lazy(() => import('./sideStepper'));

interface AnswerItem {
  answer: string | null | undefined;
  isRequired: boolean;
  isCompulsory: boolean;
}

const Index = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const pathParts = location.pathname.split('/').filter(Boolean);
  const param = pathParts[1]
  const saveRefId = pathParts[2]
  const [activeStep, setActiveStep] = useState(0);
  const [enableStep, setEnableStep] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isNewSchool, setIsNewSchool] = useState(false)
  const [isSubmitReferral, setIsSubmitReferral] = useState(false)
  const [isSaveReferral, setIsSaveReferral] = useState(false)
  const [isCancelReferral, setIsCancelReferral] = useState(false)
  const [isWarningFlag, setIsWarningFlag] = useState(false)
  const [isRejectShow, setIsRejectShow] = useState(false)
  const [isRedFlag, setIsRedFlag] = useState(false)
  const [completeSteps, setCompleteSteps] = useState<any>();
  const [questionData, setQuestionData] = useState<any>({});
  const [isError, setIsError] = useState(false)
  const [isDisableContinue, setIsDisableContinue] = useState(false)
  const deepCopyQuestion = () => JSON.parse(JSON.stringify(pathName === "/referral-form" ? openURLQuestion : question));
  const [firstErrorIndex, setFirstErrorIndex] = useState<number | null>(null);

  const hostname = window?.location?.hostname
  const currentURL = window.location.href;;
  const parts = currentURL?.split('//')?.[1]?.split('.mycarebridge')?.[0];
  const typeStg = parts?.endsWith('.stg') ? parts?.split('.stg') : [parts];
  const shortName = typeStg?.[0]

  const pathName = location?.pathname
  const getDropdown = (response: any, name: string, last: string) => {
    let dropdown: any = []
    response && response?.map((value: any) => {
      dropdown.push({
        "value": value.id || value?.ref_id,
        "label": last ? value[name] + ' ' + value[last] : value[name],
        "email": value.email,
        "address": value.address,
        "phone_no": value.phone_no,
        "first_name": value?.first_name,
        "last_name": value?.last_name,
        "school_name":value?.school_name
      }
      )
    })
    return dropdown
  }
  const userData = createSelector(
    userReducer,
    (state) => ({
      userList: state.userData.user,
      openUrlData: state.openURLData.data
    })
  );
  const referalData = createSelector(
    referralReducer,
    (state) => ({
      gpList: state?.gpList?.gpSurgeons,
    })
  );

  const allschoolData = createSelector(
    referralReducer,
    (state) => ({
      schooList: state?.allschoolData?.user,
    })
  );

  const authData = createSelector(
    authReducer,
    (state) => ({
      domainResponse: state.domainResponse,
    })
  );

  const referralReducerData = createSelector(
    referralReducer,
    (state) => ({
      referralAllDetail: state?.referralAllDetail,
    })
  );

  const { domainResponse } = useSelector(authData);
  const { gpList } = useSelector(referalData);
  const { schooList } = useSelector(allschoolData);
  const { userList, openUrlData } = useSelector(userData);
  const { referralAllDetail } = useSelector(referralReducerData);



  useEffect(()=>{
    if(param === 'edit'){
      setActiveStep(referralAllDetail?.actual_count)
      setIsRedFlag(referralAllDetail?.isRedFlag)
      setIsNewSchool(referralAllDetail?.isNewSchool)
      setIsRejected(referralAllDetail?.isRejected)
    }else{
      setActiveStep(0)
    }
},[referralAllDetail])


const checkAnswers = useCallback((arr: AnswerItem[]) => {
  const index = arr.findIndex(item =>
    item.isRequired && item.isCompulsory && !item.answer  
  );
  setFirstErrorIndex(index);
  const hasNullAnswer = arr.some(item =>
    item.isRequired && item.isCompulsory && !item.answer
  );
  setIsError(hasNullAnswer);
  
  return hasNullAnswer;
}, [questionData]);


  const checkAndReturn = (param:string, data:any, referralAllDetail:any) => param === 'edit' ? referralAllDetail : data;

  const stepConfig = [
    { key: "referrer_detail", onFail: null },
    { key: "consent", onFail: null },
    { key: "reason", onFail: null },
    { key: "gp_detail", onFail: null },
    {
      key: "person_Detail",
      onFail: () => {
        if (isRejected) {
          setIsRejectShow(true);
          return true; // Stop further execution
        }
        return false;
      },
    },
    { key: "safe_guarding_detail", onFail: null },
    { key: "parent_carers_detail", onFail: null },
    { key: "additional_parent_carers_details", onFail: null },
    { key: "support_so_far", onFail: null },
    {
      key: "digital_literacy",
      onFail: () => {
        setIsSubmitReferral(true);
        return true; // Stop further execution
      },
    },
  ];
  
  // const handleNext = () => {
  //   switch (activeStep) {
  //     case 0:
  //         if (checkAnswers(checkAndReturn(param, questionData?.referrer_detail, referralAllDetail?.data?.referrer_detail))) {
  //         return;
  //       }
  //       break;
  //     case 1:
  //       if (checkAnswers(checkAndReturn(param, questionData?.consent, referralAllDetail?.data?.consent))) {
  //         return;
  //       }
  //       break;
  //     case 2:
  //       if (checkAnswers(checkAndReturn(param, questionData?.reason, referralAllDetail?.data?.reason))) {
  //         return;
  //       }
  //       break;
  //     case 3:
  //       if (checkAnswers(checkAndReturn(param, questionData?.gp_detail, referralAllDetail?.data?.gp_detail))) {
  //         return;
  //       }
  //       break;
  //     case 4:
  //       if (checkAnswers(checkAndReturn(param, questionData?.person_Detail, referralAllDetail?.data?.person_Detail))) {
  //         return;
  //       } else if (isRejected) {
  //         setIsRejectShow(true)
  //         return
  //       }
  //       break;

  //     case 5:
  //       if (checkAnswers(checkAndReturn(param, questionData?.safe_guarding_detail, referralAllDetail?.data?.safe_guarding_detail))) {
  //         return;
  //       }
  //       break;
  //     case 6:
  //       if (checkAnswers(checkAndReturn(param, questionData?.parent_carers_detail, referralAllDetail?.data?.parent_carers_detail))) {
  //         return;
  //       }
  //       break;
  //     case 7:
  //       if (checkAnswers(checkAndReturn(param, questionData?.additional_parent_carers_details, referralAllDetail?.data?.additional_parent_carers_details))) {
  //         return;
  //       }
  //       break;

  //     case 8:
  //       if (checkAnswers(checkAndReturn(param, questionData?.support_so_far, referralAllDetail?.data?.support_so_far))) {
  //         return;
  //       }
  //       break;
  //     case 9:
  //       if (checkAnswers(checkAndReturn(param, questionData?.digital_literacy, referralAllDetail?.data?.digital_literacy))) {
  //         return;
  //       } else {
  //         setIsSubmitReferral(true)
  //         return;
  //       }
  //       break;
  //     default:
  //       break;
  //   }

  //   setCompleteSteps(activeStep)

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setEnableStep((prevActiveStep) => prevActiveStep + 1);
  //   scrollToTop()
  // };

const handleNext = () => {
  const currentStep = stepConfig[activeStep];

  if (currentStep) {
    const isFail = checkAnswers(
      checkAndReturn(
        param,
        questionData?.[currentStep.key],
        referralAllDetail?.data?.[currentStep.key]
      )
    );

    if (isFail) return;

    // Handle custom fail conditions if defined
    if (currentStep.onFail && currentStep.onFail()) return;
  }

  setCompleteSteps(activeStep);

  setActiveStep((prevActiveStep) => prevActiveStep + 1);
  setEnableStep((prevActiveStep) => prevActiveStep + 1);
  scrollToTop();
};

useEffect(()=>{
  setFirstErrorIndex(null);
},[firstErrorIndex])


  useEffect(() => {
    if (pathName === "/referral-form") {
      if (hostname === 'localhost') {
        dispatch(getOpenUserList({}))
      } else {
        dispatch(getOpenUserList({ domain: shortName }))
      }
    } else {
      dispatch(getUserList({ scope: CONSTANT.SCOPE.SCHOOL }));
      // dispatch(getAllGPList({ limit: 10000000 }));
    }

    setQuestionData(saveRefId ? referralAllDetail.data : deepCopyQuestion());

  }, [question]);

  useEffect(() => {
    dispatch(changeTab('Referral Create'))
    if (pathName === "/referral-form" && openUrlData) {
      let schoolList = getDropdown(openUrlData?.school, 'school_name', '')
      let gpListData = getDropdown(openUrlData?.gpSurgeons, 'user_name', '')
      handleAddSchool(schoolList, gpListData)
    } else {
      if (schooList) {
        let schoolList = getDropdown(schooList, 'school_name', '')
        let gpListData = getDropdown(gpList, 'user_name', '')
        handleAddSchool(schoolList, gpListData)
      }
    }
  }, [schooList, gpList, openUrlData])




  const handleAddSchool = async (schoolList: Array<{ value: string,school_name:string, label: string, email: string, address: string, phone_no: string }>, gpListData: Array<any>) => {
    try {
      // Fetch the questions data
      const tempQuestions = (param === 'edit') ? referralAllDetail.data : questionData;
  
      if (tempQuestions) {
  
        let tempPersonDetail = [...tempQuestions.person_Detail];
  
        if (gpListData.length !== 0) {
          let tempGPDetail = [...tempQuestions.gp_detail];

          if (tempGPDetail[0] && tempGPDetail[0].question_id === 'ques30') {
            tempGPDetail[0].option = gpListData;
          } else {
            console.error("Expected a different structure for gp_detail[0]");
          }
  
          setQuestionData({ ...tempQuestions, gp_detail: tempGPDetail });
        }
  
        // Add new school option
        let tempSchool = [
          ...schoolList,
          // {
          //   value: 'new',
          //   label: 'Add Manually',
          //   email: '',
          //   address: '',
          //   phone_no: '',
          // }
        ];
  
        // Check if person_Detail has the expected structure
        if (tempPersonDetail[16] && tempPersonDetail[16].question_id === 'ques22') {
          tempPersonDetail[16].option = tempSchool;
        } else {
          console.error("Expected a different structure for person_Detail[16]");
        }
  
        setQuestionData({ ...tempQuestions, person_Detail: tempPersonDetail });
      }
    } catch (error) {
      console.error("Error handling school addition:", error);
    }
  };
  


  const handleSubmit = (type:string) => {
    // let tempQuestions: any = { ...questionData };
    let tempQuestions = param === 'edit' ? referralAllDetail?.data : questionData;
    if (tempQuestions?.person_Detail[10]?.question_id === 'ques17') {
      tempQuestions.person_Detail[10].answer = tempQuestions?.person_Detail[10]?.answer?.replaceAll(' ', '')
    }
    if (pathName === "/referral-form") {
      let payload = {
        data: tempQuestions,
        isNewSchool: isNewSchool,
        isRedFlag: isRedFlag,
        domain: shortName,
        isRejected: isRejected,
      }
      dispatch(addReferral(payload, navigate, true))
    } else {
      let payload = {
        data: tempQuestions,
        total_count : 9,
        actual_count : activeStep,  
        isNewSchool: isNewSchool,
        isRedFlag: isRedFlag,
        isRejected: isRejected,
        isFinalSubmit : type !== 'SAVE' ? true : false 
      }
      if(saveRefId){
        dispatch(editReferral(payload,saveRefId, navigate))
      }else{
        dispatch(addReferral(payload, navigate, false))
      }

      dispatch(setCurentTab(0))
    }
    setIsRejectShow(false)
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (scrollContainerRef.current) {
        setIsVisible(scrollContainerRef.current.scrollTop > 200);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', toggleVisibility);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', toggleVisibility);
      }
    };
  }, []);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useLayoutEffect(() => {
    if (hostname === 'localhost') {
      dispatch(localDomain())
    } else {
      dispatch(clearDomain())
      dispatch(VerifyDomain({ short_name: shortName }))
    }
  }, [])

  useEffect(() => {
    if (pathName !== "/referral-form" && param === "edit") {
    dispatch(viewAllReferralDetail({ ReferralId: saveRefId }))
    }
  }, [])



  const questionsToPass = param === "edit" ? referralAllDetail.data : questionData;

  console.log(questionsToPass,'questionsToPass')
  return (
    <>{domainResponse?.validate_domain === '' ?
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10rem' }}>
        <CircularProgress />
      </Box> : domainResponse?.validate_domain === true ?
        <Grid container spacing={4} className={classes.refContainer} marginTop={'10px'}>
          <Grid item lg={4} md={4} sm={4} xs={12} className={classes.pt0} 
        sx={{
          maxHeight: pathName === "/referral-form" ? '100vh!important' : 'calc(100vh - 120px)'
        }}
        >
        
          <Suspense fallback={<div>Loading...</div>}>
            <SideStepper questions={questionsToPass} activeStep={activeStep} setActiveStep={setActiveStep} completeSteps={completeSteps} enableStep={enableStep} />
         </Suspense>
          </Grid>
          <Grid ref={scrollContainerRef} item lg={8} md={8} sm={8} xs={12}
          sx={{
            maxHeight: pathName === "/referral-form" ? '100vh!important' : 'calc(100vh - 120px)',
            paddingBottom : pathName === "/referral-form" ? '20px' : '0',
          }}
          >
            <Grid >
            <Suspense fallback={<div>Loading...</div>}>
              {activeStep === 0 && <ReferrerDetail  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsRejected={setIsRejected} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 1 && <Consent  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setIsRejected={setIsRejected} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 2 && <Reason  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setIsRejected={setIsRejected} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 3 && <GPDeatil  gpList={pathName === "/referral-form" ? openUrlData?.gpSurgeons : gpList} firstErrorIndex={firstErrorIndex} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} setIsRejected={setIsRejected} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 4 && <PersonDetail  firstErrorIndex={firstErrorIndex} isRedflag={setIsRedFlag} questions={questionsToPass} setIsRejected={setIsRejected} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} setIsNewSchool={setIsNewSchool} />}
              {activeStep === 5 && <Safegauding  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} setIsRejected={setIsRejected} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 6 && <ParentCarersDetail  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} setIsRejected={setIsRejected} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 7 && <AdditionalParentDetail  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} setIsRejected={setIsRejected} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 8 && <SupportSoFar  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsRejected={setIsRejected} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
              {activeStep === 9 && <DigitalLiteracy  firstErrorIndex={firstErrorIndex} questions={questionsToPass} setIsWarningFlag={setIsWarningFlag} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsRejected={setIsRejected} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />}
           </Suspense>
            </Grid>
            <Grid container>
              <Grid item lg={2} md={2} sm={2} xs={3}>
                <Grid sx={{ mt: '15px' }} >
                  <Button
                    variant="outlined"
                    onClick={() => setIsCancelReferral(true)}
                    fullWidth={true}
                  // disabled={activeStep === 0}
                  >
                    Exit
                  </Button>
                </Grid>
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={9}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mt: '15px' }} >
                {pathName !== "/referral-form" && <Grid >
                    <Button
                      variant="outlined"
                      onClick={() => {setIsSaveReferral(true)
                      // setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    }}
                      fullWidth={true}
                    >
                      Save as Draft 
                    </Button>
                  </Grid>}

                
                  <Grid >
                    <Button
                      variant="outlined"
                      onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                      fullWidth={true}
                      disabled={activeStep === 0}
                    >
                      Previous
                    </Button>
                  </Grid>

                  <Grid >
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      fullWidth={true}
                      disabled={isDisableContinue}
                    >
                      {activeStep === 9 ? "Submit" : "Continue"}
                    </Button>
                  </Grid>
                  {isVisible && (
                    <Grid className="posFixed">
                      <Button variant="outlined" onClick={scrollToTop}><ArrowUpwardIcon /></Button>
                    </Grid>
                  )}
                </Grid>
              </Grid >
            </Grid>
          </Grid>

          <>
         
    


            <ConfirmationModal
        open={isSubmitReferral}
        handleClose={()=> setIsSubmitReferral(false)}
        title="Confirmation"
        message={`Are you sure you want to submit Referral ?`}
        onConfirm={() =>handleSubmit('SUBMIT')}
        onCancel={()=> setIsSubmitReferral(false)}
      />



            <ConfirmationModal
        open={isSaveReferral}
        handleClose={()=> setIsSaveReferral(false)}
        title="Confirmation"
        message={`Are you sure you want to save as draft Referral ?`}
        onConfirm={() =>handleSubmit('SAVE')}
        onCancel={()=> setIsSaveReferral(false)}
      />
          </>

          <>
      

            <ConfirmationModal
        open={isCancelReferral}
        handleClose={()=> setIsCancelReferral(false)}
        title="Confirmation"
        message={`Are you sure you want to leave this Referral?`}
        onConfirm={() => navigate(-1)}
        onCancel={()=> setIsCancelReferral(false)}
      />

            
      
          </>

     

          <>
            <Modal open={isWarningFlag}
              title={"Confirmation"}>
              <Box className={classes.clinicModal}>
                <Grid sx={{ width: '100%', textAlign: 'center', py: 2 }}>
                  <Typography variant="body1" fontFamily={'poppins'}>Please note that selecting no means the parent/carer will not be given access to the parent/carer portal and will need to complete paper forms.</Typography>
                </Grid>
                <Grid className={classes.btnWrapper} sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItem: 'center', mt: '15px' }}>
                  <Button sx={{ width: '30%' }} variant={"contained"} onClick={() => setIsWarningFlag(false)} >Okay</Button>
                </Grid>

              </Box>
            </Modal>
          </>

          <>
            <Modal open={isRejectShow}
              title={"Confirmation"}>
              <Box className={classes.clinicModal}>
                <Grid sx={{ width: '100%', textAlign: 'center', py: 2 }}>
                  <Typography variant="body1" fontFamily={'poppins'}>Thank you for filling the referral form. Unfortunately, it does not meet our current criteria.</Typography>
                </Grid>
                <Grid className={classes.btnWrapper} sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItem: 'center', mt: '15px' }}>
                  <Button sx={{ width: '30%' }} variant={"contained"} onClick={() => handleSubmit('SUBMIT')} >Yes</Button>
                </Grid>

              </Box>
            </Modal>
          </>

        </Grid > : <Grid className='nodocumentImg'>
          <Typography variant='h5' >Invalid Domain</Typography>
        </Grid>

    }
    </>
  );

}

export default memo(Index)