import React, { Suspense, memo, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import SideStepper from "../sideStepper";
import classes from '../index.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';
import { changeTab, viewAllReferralDetail } from '../../../../redux/action';
import organism from '../../../../components/organism';
import atoms from '../../../../components/atoms';
import constant from '../../../../constants/constant';
import PdfRefeeral from '../../../../components/atoms/PdfRefeeral';
const Pdf = React.lazy(() => import('../../../../components/atoms/Pdf'));
const { Button } = atoms
const { ReferralView } = organism
const Index = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [activeStep, setActiveStep] = useState(0);
  const [enableStep, setEnableStep] = useState(10);
  const [completeSteps, setCompleteSteps] = useState<any>(10);
  const referralReducer = (state: any) => state?.referralReducer

  const referralReducerData = createSelector(
    referralReducer,
    (state) => ({
      referralAllDetail: state?.referralAllDetail.data,
      referralurl: state?.referralAllDetail,
    })
  );
  const { referralAllDetail } = useSelector(referralReducerData);
  const { referralurl } = useSelector(referralReducerData);


  useEffect(() => { 
    dispatch(changeTab('Referral View'))
    dispatch(viewAllReferralDetail({ ReferralId: params.id }))
  }, [])

  const countRequiredConsent = referralAllDetail?.consent?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredReason = referralAllDetail?.reason?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredPersonDetail = referralAllDetail?.person_Detail?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredGPDetail = referralAllDetail?.gp_detail?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredSafeGuardDetail = referralAllDetail?.safe_guarding_detail?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredParentCareer = referralAllDetail?.parent_carers_detail?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredAdditionalParentCareer = referralAllDetail?.additional_parent_carers_details?.filter((obj: any) => obj.isRequired)?.length;
  const countRequiredReferrerDetail = referralAllDetail?.referrer_detail?.filter((obj: any) => obj?.isRequired)?.length;
  const countRequiredSupportSoFar = referralAllDetail?.support_so_far?.filter((obj: any) => obj.isRequired)?.length;

  return (
    <Grid container spacing={4} className={classes.refContainer}>
      <Grid item md={3} sm={3} xs={12} className={classes.pt0}>
        <SideStepper questions={referralAllDetail} activeStep={activeStep} setActiveStep={setActiveStep} completeSteps={completeSteps} enableStep={enableStep} />
      </Grid>
      <Grid item md={9} sm={9} xs={12} sx={{
        overflowY: 'auto',
        maxHeight: { xs: '', sm: '', md: '', lg: '', xl: '54rem' },
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none', // Firefox
        msOverflowStyle: 'none', // IE and Edge

      }} className={classes.pt0}>
         
        <Grid >
        <Suspense fallback={<div>Loading...</div>}>
        <PdfRefeeral data={referralAllDetail} dataUrl={referralurl} title={constant.PATHWAY_STATUS.CASELOAD_CREATED} fname={referralAllDetail?.consent}/>
        </Suspense>
          {activeStep === 0 && <ReferralView question_number={0} question={referralAllDetail?.referrer_detail} type={"Referrer Detail"} tab={activeStep} />}
          {activeStep === 1 && <ReferralView question_number={countRequiredReferrerDetail} question={referralAllDetail?.consent} type={"Consent"} tab={activeStep} />}
          {activeStep === 2 && <ReferralView question_number={countRequiredConsent + countRequiredReferrerDetail} question={referralAllDetail?.reason} type={"Reason"} tab={activeStep} />}
          {activeStep === 3 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredReferrerDetail} question={referralAllDetail?.gp_detail} type={"GP Detail"} tab={activeStep} />}
          {activeStep === 4 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredGPDetail + countRequiredReferrerDetail} question={referralAllDetail?.person_Detail} type={"Person Detail"} tab={activeStep} />}
          {activeStep === 5 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredReferrerDetail} question={referralAllDetail?.safe_guarding_detail} type={"Safe Guarding Detail"} tab={activeStep} />}
          {activeStep === 6 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredReferrerDetail} question={referralAllDetail?.parent_carers_detail} type={"Parent Carers Detail"} tab={activeStep} />}
          {activeStep === 7 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredReferrerDetail}
            question={referralAllDetail?.additional_parent_carers_details} type={"Additional parent carers detail"}
            tab={activeStep} />}

          {activeStep === 8 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer + countRequiredReferrerDetail}
            question={referralAllDetail?.support_so_far} type={"Support So Far"} tab={activeStep} />}
          {activeStep === 9 && <ReferralView question_number={countRequiredConsent + countRequiredReason + countRequiredPersonDetail + countRequiredGPDetail + countRequiredSafeGuardDetail + countRequiredParentCareer + countRequiredAdditionalParentCareer + countRequiredReferrerDetail + countRequiredSupportSoFar}
            question={referralAllDetail?.digital_literacy} type={"Digital Literacy"} tab={activeStep} />}

        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'center', mt: '1rem', gap: '1rem' }}>
          <Button variant={"outlined"} disabled={activeStep === 0} onClick={() => setActiveStep(prev => prev - 1)}>
            Previous
          </Button>
          <Button variant={"contained"} disabled={activeStep === 9} onClick={() => setActiveStep(prev => prev + 1)}>
            Next
          </Button>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(Index)