import { put, takeLatest } from "redux-saga/effects";
import {
  Login_Admin,
  SEND_OTP,
  Get_Login_Response,
  UPDATE_USER_ACCOUNT_DETAIL,
  VERIFY_DOMAIN,
  VERIFY_DOMAIN_RESPONSE,
  REGISTER_PASSWORD,
  Logout_Admin,
  FORGOT_PASSWORD,
  VERIFY_LINK,
  VERIFY_LINK_RESPONSE,
  CHANGE_PASSWORD,
} from "./actionType";
import { AxiosResponse } from "axios";
import service from "../../api/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HEADER_TOKEN } from "../../constants/defaultValues";
import constants from "../../constants/constant";
import constant from "../../constants/constant";
// import { saveImageToLocalStorage } from "../../constants/utils";
function* getLogin({
  data,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.login(data, HEADER_TOKEN);
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusCode === 200 ||
      response.statusCode === 201
    ) {
      toast.success(response.data.message);
      yield put({ type: Get_Login_Response, payload: response.data.data });
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      localStorage.setItem(
        "last_name",
        JSON.stringify(response.data.data.last_name)
      );
      localStorage.setItem(
        "first_name",
        JSON.stringify(response?.data?.data?.first_name)
      );
      // saveImageToLocalStorage();
      if (response?.data?.data?.mfa) {
        navigate("/otp");
      } else {
        localStorage.setItem(
          "loginResponse",
          JSON.stringify(response.data.data)
        );
        localStorage.setItem("accessToken", response.data.data.accessToken);
        navigate("/dashboard");
      }
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      toast.error("Email or password is incorrect");
    } else {
      toast.error(error?.response?.data?.message);
    }
    return error;
  }
}
function* forgotPassword({
  payload,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.forgotPasssword(
      payload,
      HEADER_TOKEN
    );
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusCode === 200 ||
      response.statusCode === 201
    ) {
      toast.success(response.data.message);
      navigate("/login");
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);

    return error;
  }
}

function* setPassword({
  payload,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.setPassword(payload, HEADER_TOKEN);
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusCode === 200 ||
      response.statusCode === 201
    ) {
      toast.success(response.data.message);
      if (response.data?.data?.scope === constant.SCOPE.CLINICIAN) {
        navigate("/clinician/login");
      } else {
        navigate("/login");
      }
    } else {
      toast.error(response?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);

    return error;
  }
}
function* sendOTP({
  payload,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.otp(payload, HEADER_TOKEN);

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusCode === 200 ||
      response.statusCode === 201
    ) {
      yield put({ type: Get_Login_Response, payload: response.data.data });
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      localStorage.setItem("loginResponse", JSON.stringify(response.data.data));
      localStorage.setItem("accessToken", response.data.data.accessToken);
      toast.success(response.data.message);
      navigate("/dashboard");
    } else {
      toast.error(response?.data.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);

    return error;
  }
}

function* verifyLink({
  payload,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.verifyLink(payload, HEADER_TOKEN);

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusCode === 200 ||
      response.statusCode === 201
    ) {
      yield put({
        type: VERIFY_LINK_RESPONSE,
        payload: { validate_otp: true },
      });
    } else {
      yield put({
        type: VERIFY_LINK_RESPONSE,
        payload: { validate_otp: false },
      });
      toast.error(response?.data.message);
    }
    return response;
  } catch (error: any) {
    yield put({ type: VERIFY_LINK_RESPONSE, payload: { validate_otp: false } });
    toast.error(error?.response?.data?.message);

    return error;
  }
}

function* verifyDomain({
  payload,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.verifyDomain(
      payload,
      HEADER_TOKEN
    );

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusCode === 200 ||
      response.statusCode === 201
    ) {
      yield put({
        type: VERIFY_DOMAIN_RESPONSE,
        payload: { validate_domain: true },
      });
    } else {
      yield put({
        type: VERIFY_DOMAIN_RESPONSE,
        payload: { validate_domain: false },
      });
      toast.error(response?.data.message);
    }
    return response;
  } catch (error: any) {
    yield put({
      type: VERIFY_DOMAIN_RESPONSE,
      payload: { validate_domain: false },
    });
    toast.error(error?.response?.data?.message);

    return error;
  }
}

function* getLogout({
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.logout();
    if (response.status === 200 || response.statusCode === 200) {
      let loginDetailString = localStorage.getItem("loginResponse");
      let loginDetail = loginDetailString ? JSON.parse(loginDetailString) : {};
      if (loginDetail.scope === constants.SCOPE.CLINICIAN) {
        navigate("/clinician/login");
      } else {
        navigate("/login");
      }
      localStorage.clear();
      toast.success(response.data.message);
      localStorage.removeItem("accessToken");
    } else {
      toast.error(response.data.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.response.data.message);
    return error;
  }
}

function* updateAccountDetail({
  id,
  data,
  navigate,
  logoutBoolean,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.User_Management.update(
      id,
      data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(response?.data?.message);
      const loginResponse = localStorage.getItem("loginResponse");
      let userData = loginResponse ? JSON.parse(loginResponse) : {};
      userData.first_name = data.first_name;
      userData.last_name = data.last_name;
      yield put({ type: Get_Login_Response, payload: userData });
      localStorage.setItem("loginResponse", JSON.stringify(userData));
      localStorage.setItem("userData", JSON.stringify(userData));
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* changePassword({
  payload,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.auth.changePassword(payload);
    if (response.status === 201 || response.status === 200) {
      toast.success(response.data.message);
      localStorage.removeItem("access_token");
      navigate("/login");
    } else {
      toast.error(response.data.message);
    }
    return response;
  } catch (error: any) {
    console.error(error);

    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* authSaga() {
  yield takeLatest(Login_Admin, getLogin);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(SEND_OTP, sendOTP);
  yield takeLatest(Logout_Admin, getLogout);
  yield takeLatest(REGISTER_PASSWORD, setPassword);
  yield takeLatest(VERIFY_LINK, verifyLink);
  yield takeLatest(VERIFY_DOMAIN, verifyDomain);
  yield takeLatest(UPDATE_USER_ACCOUNT_DETAIL, updateAccountDetail);
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}

export default authSaga;
