import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Chat } = Dictionary

const Chat_Management = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + Chat.add(), data)
  },
  sentmsg: async (data: {}) => {
    return axios.post(BASE_URL + Chat.sentmsg(), data)
  },
  getmsg: async (id: string) => {
    return axios.get(BASE_URL + Chat.getmsg(id))
  },
  getmsgConversation: async (id: string) => {
    return axios.get(BASE_URL + Chat.getmsgConversation(id))
  },
}
export default Chat_Management
