import { Grid, Pagination, Stack } from '@mui/material'
import { limitOptions } from '../../../constants/defaultValues'
import classes from './style.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { memo, useEffect, useMemo, useState } from 'react';
import { ResetPagination } from '../../../redux/action';

const Index = ({ totalPages, onCurrentPage,limit,setLimit}: any) => {
  const dispatch = useDispatch();
  const layoutReducer = (state: any) => state?.LayoutReducer;
  const [currentPage, setCurrentPage] = useState(1);
  const layoutURLData = createSelector(layoutReducer, (state) => ({
    resetPagination: state.resetPagination,
  }));
  const { resetPagination } = useSelector(layoutURLData);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
      onCurrentPage(page); 
    };
  
    useEffect(() => {
      if (resetPagination) {
        setCurrentPage(1);
        onCurrentPage(1);
      }
      return () => {
        dispatch(ResetPagination(false));
      }
    }, [resetPagination]);

    const limitOptionsList = useMemo(
      () => limitOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      )),
      []
    );

  return (
    <div className='paginationStyle'>
      <Grid container justifyContent="end" marginTop='2rem' marginBottom='2rem'>
        <Grid>
        <select
            className={classes.countStyle}
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
          >
            {limitOptionsList}
          </select>
        </Grid>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            showFirstButton
            page={currentPage} 
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
            showLastButton
            sx={{
              '& .MuiPaginationItem-rounded': {
                backgroundColor: '#ffffff',
                color: '#333333',
              },
              '& .Mui-selected': {
                backgroundColor: '#6A2382',
                color: 'white',
              },
            }}
          />
        </Stack>
      </Grid>
    </div>
  )
}

export default memo(Index)