/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loaderGif from '../assets/Images/loader.svg';
import { isLoaderLoading } from "../context";
import { HEADER_TOKEN } from "../constants/defaultValues";

const interceptor = () => {
    const { isLoading, setIsLoading } = useContext<any>(isLoaderLoading);
    const navigate = useNavigate();

    axios.interceptors.request.use((config) => {
        setIsLoading(true);
        const AUTH_TOKEN = localStorage.getItem("accessToken")
        let isLogin = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'login')
        let isOtp = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'validate-otp')
        let isSetPassword = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'set-password')
        let isForgotPassword = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'forgot-password')
        let isOpenReferral = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'referral-form')
        let isOpenReferralData = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'data')
        let isOpenVerifyDomain = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'verify-domain')
        let isOpenSubmit = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'submit-open')
        let isVerifyLink = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'verify-link')

        
        if (config && config.headers && !(isLogin || isOtp || isSetPassword || isForgotPassword || isOpenReferral)) {
            config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        }
        if(isOpenReferralData || isOpenVerifyDomain || isOpenSubmit || isVerifyLink){
            config.headers.Authorization = `${HEADER_TOKEN.Authorization}`;
        }
        if (!AUTH_TOKEN && !(isLogin) && window.location.pathname.split('/')[1] !== 'otp' && window.location.pathname.split('/')[1] !== 'register' && window.location.pathname.split('/')[1] !== 'forgot-password' && window.location.pathname.split('/')[1] !== 'referral-form') {
            navigate("/login");
            localStorage.clear()
        }
        // isLoading = true
        return config;
    }, (error) => {
        setIsLoading(false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(
        response => {
            setIsLoading(false);
            return response
        }, (error) => {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate("/login");
                        localStorage.clear()
                    } else if (error.response.status === 503) {

                        navigate("/login");
                        localStorage.clear()
                        window.location.reload()
                    }
                }
            }
            return Promise.reject(error);
        }
    )


    return (
        <>
            {isLoading &&
                <div className='loadergif'><img src={loaderGif} alt='loader' /> </div>
            }
        </ >
    )
}
export default interceptor;