// import { useState, useEffect, useRef } from "react";

// export const useVersionCheck = (interval = 30000) => {
//   const [newVersionAvailable, setNewVersionAvailable] = useState(false);
//   const versionRef = useRef(""); 

//   const fetchVersion = async () => {
//     try {
//       const timestamp = new Date().getTime();
//       const response = await fetch(`/version.json?ts=${timestamp}`);
//       const data = await response.json();
//       return data.version;
//     } catch (error) {
//       console.error("Error fetching version:", error);
//       return null;
//     }
//   };

//   useEffect(() => {
//     const checkVersion = async () => {
//       const latestVersion = await fetchVersion();
//       if (latestVersion && versionRef.current && latestVersion !== versionRef.current) {
//         setNewVersionAvailable(true);
//       } else if (!versionRef.current) {
//         versionRef.current = latestVersion;
//       }
//     };

//     checkVersion();
//     const intervalId = setInterval(checkVersion, interval);
//     return () => clearInterval(intervalId); 
//   }, [interval]);

//   return { newVersionAvailable, refresh: () => window.location.reload() };
// };

import { useState, useEffect, useRef } from "react";

export const useVersionCheck = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const versionRef = useRef(""); 

  const fetchVersion = async () => {
    try {
      const timestamp = new Date().getTime();
      const response = await fetch(`/version.json?ts=${timestamp}`);
      const data = await response.json();
      return data.version;
    } catch (error) {
      console.error("Error fetching version:", error);
      return null;
    }
  };

  useEffect(() => {
    const checkVersion = async () => {
      const latestVersion = await fetchVersion();
      if (latestVersion && versionRef.current && latestVersion !== versionRef.current) {
        setNewVersionAvailable(true);
      } else if (!versionRef.current) {
        versionRef.current = latestVersion;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkVersion();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return { newVersionAvailable, refresh: () => window.location.reload() };
};
