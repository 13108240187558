import React, { useEffect, useRef } from 'react'
import {
    Box,
    InputLabel,
    TextField, Typography
} from '@mui/material';
import { style } from './style'
const Index = ({ fullWidth, value, disabled, type, onChange, placeholder,numberLength, variant, multiline, rows, inputProps, handle, autoFocus,ref, error, label, prop }: any) => {
    const handleKeyPress = (event: any) => {
        // Regular expression to match arithmetic characters
        const arithmeticOps = /[*/]/;
        if (arithmeticOps.test(event.key) && type === 'number') {
            event.preventDefault();
        }
    };

    const handleKeyDown = (event: any) => {
        // Check if the key is ArrowUp or ArrowDown
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    };

    const handleChange = (event: any) => {
        const newValue = event.target.value;
        let numLength = numberLength ? numberLength : 500
        // Allow only numeric input, check length, and then call the parent onChange
        if (type === 'number') {
            if (newValue === '' || (/^[-+]?\d*\.?\d*$/.test(newValue) && newValue.length <= numLength)) {
                // Here you can add additional logic before passing the event to the onChange prop
                onChange && onChange(event);
            }
        } else {
            onChange && onChange(event);
        }
    };


    // const handleChange = (event: any) => {
    //     // Here you can add additional logic before passing the event to the onChange prop
    //     onChange && onChange(event);
    // };

    const handleWheel = (event: any) => {
        if (type === 'number' && event.type === 'wheel') {
            event.preventDefault();
        }
    };

    return (
        <Box>
            {label && <InputLabel sx={style.label_color} >{label}</InputLabel>}
            <TextField
                {...prop}
                fullWidth={fullWidth}
                value={value}
                disabled={disabled}
                type={type === 'number' ? 'text' : type}
                ref={ref}
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyDown}
                // onWheel={handleWheel}
                onFocus={(e) => type === 'number' && e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                onChange={handleChange} // Use the custom handleChange
                placeholder={placeholder}
                variant={variant}
                multiline={multiline}
                rows={rows}
                inputProps={inputProps}
                autoFocus={autoFocus}
            />
            {error && <Typography sx={style.error_text}>{error}</Typography>}
        </Box>
    )
}

export default Index