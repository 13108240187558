import { ADD_TASK, ADD_TASK_FAILURE, SUBMIT_TASK, ADD_TASK_SUCCESS, GET_SCHOOL_REPORT, GET_PARENT_REPORT, CASEUSER_LISTING, GET_CASELOAD_BY_ID, CASELOAD_LISTING, CASE_DOCUMENTS_BY_ID_FAILURE, CASE_DOCUMENTS_BY_ID_REQUEST, CASE_DOCUMENTS_BY_ID_SUCCESS, CREATE_CASELOAD, TASK_LISTING, UPLOAD_FILE, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS, CREATE_CONVERSATIONS, CREATE_CONVERSATIONS_SUCCESS, CREATE_CONVERSATIONS_FAILURE, SENT_MESSAGE, CHATMSG_LISTING, CHATCONVERTSATION_LISTING, SUBMIT_TASK_SUCCESS, SUBMIT_TASK_FAILURE, SET_LOADING, UPDATE_TASK, UPDATE_DETAILS_BY_CASELOAD, CLINICAL_REVIEW, CASELOAD_FULL_LISTING, GET_RED_FLAG_LIST, DELETE_ADDITIONAL_PARENT, DELETE_UPLOAD_DOC, RESPONSE_CHATMSG_CLEAR, CANCEL_CASELOADLIST_REQ, CLEAR_REDFLAG_DATA, CLEAR_UNMOUNT_DATA, CLEAR_TASK, DELETE_TASK, ADD_SYSTEM, ADD_REDFLAG_SYSTEM } from './actionType'
export const addCaseload = (data: any, navigate: any) => {
  return {
    type: CREATE_CASELOAD,
    data,
    navigate
  }
}


export const submitTask = (id: string, data: any, caseload_id: string) => {
  return {
    type: SUBMIT_TASK,
    id,
    data,
    caseload_id
  }
}

export const clinicalReview = (id: string, data: any,navigate:any) => {
  return {
    type: CLINICAL_REVIEW,
    id,
    data,
    navigate
  }
}


export const addSystemDate = (id: string, data: any) => {
  return {
    type: ADD_SYSTEM,
    id,
    data
  }
}

export const addRedflagSystemDate = (id: string, data: any) => {
  return {
    type: ADD_REDFLAG_SYSTEM,
    id,
    data
  }
}

export const getCaseloadList = (headers: any) => {
  const type = headers?.limit === '' && headers?.page === '' ? CASELOAD_FULL_LISTING : CASELOAD_LISTING;
  return {
    type,
    headers
  }
}

export const ClearCaseloadList = () => {
  return {
    type: CANCEL_CASELOADLIST_REQ,
  }
}

export const getTaskList = (params: any) => {
  return {
    type: TASK_LISTING,
    params
  }
}

export const clearTask = ()=>{
  return {
    type :  CLEAR_TASK
  }
}

// export const getTaskDocuments = (id: string) => {
//   return {
//     type: TASK_DOC,
//     id
//   }
// }

export const getChatmessages = (id: string, loading: boolean) => {
  return {
    type: CHATMSG_LISTING,
    id,
    loading
  }
}
export const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  loading
});

export const getChatConversation = (id: string) => {
  return {
    type: CHATCONVERTSATION_LISTING,
    id
  }
}


export const deleteUploadDoc = (id: string, acceptRefID: string, uploadType: string) => {
  return {
    type: DELETE_UPLOAD_DOC,
    id,
    acceptRefID,
    uploadType
  }
}




export const getParentReport = (params: any) => {
  return {
    type: GET_PARENT_REPORT,
    params
  }

}
export const getSchoolReport = (params: any) => {
  return {
    type: GET_SCHOOL_REPORT,
    params
  }
}


export const getCashUserList = (id: string) => {
  return {
    type: CASEUSER_LISTING,
    id
  }
}
export const getCaseloadById = (id: string) => {
  return {
    type: GET_CASELOAD_BY_ID,
    id
  }
}

export const clearUnmountData = () => {
  return {
    type : CLEAR_UNMOUNT_DATA
  }
}
// export const getUserByID = (id: string) => {

//     return {
//         type: GET_USER_DETAIL,
//         id
//     }
// }
// export const getClinicianList = (headers: any) => {
//     return {
//         type: CLINICIAN_LISTING,
//         headers
//     }
// }
// export const getModules = (headers: any) => {
//     return {
//         type: VIEW_MODULES,
//         headers
//     }
// }
// export const clinicianResponse = (headers: any) => {
//     return {
//         type: CLINICIAN_RESPONSE,
//         headers
//     }
// }
// export const userResponse = (Response: any) => {
//     return {
//         type: USER_RESPONSE,
//         payload: Response,
//     }
// }

// export const modulesResponse = (Response: any) => {
//     return {
//         type: MODULES_RESPONSE,
//         payload: Response,
//     }
// }
// export const getUserDetail = (Response: any) => {
//     return {
//         type: USER_DETAIL_RESPONSE,
//         payload: Response,
//     }
// }



// for uploads documents

export const uploadFile = (data: FormData, navigate: any) => ({
  type: UPLOAD_FILE,
  data,
  navigate
});

export const uploadFileSuccess = () => ({
  type: UPLOAD_FILE_SUCCESS,
});

export const uploadFileFailure = (error: any) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: error,
});



export const caseDocumentsById = (id: any, navigate: any) => ({
  type: CASE_DOCUMENTS_BY_ID_REQUEST,
  id,
  navigate
});

export const caseDocumentsByIdSuccess = (data: any) => ({
  type: CASE_DOCUMENTS_BY_ID_SUCCESS,
  payload: data,
});

export const caseDocumentsByIdFailure = (error: any) => ({
  type: CASE_DOCUMENTS_BY_ID_FAILURE,
  payload: error,
});


export const addTask = (id: string, data: any) => ({
  type: ADD_TASK,
  data,
  id
});

export const updateTask = (id: string, data: any) => ({
  type: UPDATE_TASK,
  data,
  id
});

export const deleteTask = (id: string,caseloadId:any) => ({
  type: DELETE_TASK,
  id,
  caseloadId
});

export const updateDetailsByCaseload = (id: string, data: any) => ({

  type: UPDATE_DETAILS_BY_CASELOAD,
  data,
  id
});

export const addTaskSuccess = () => ({
  type: ADD_TASK_SUCCESS,
});

export const addTaskFailure = (error: any) => ({
  type: ADD_TASK_FAILURE,
  payload: error,
})


export const SubmitTask = (data: any) => ({
  type: SUBMIT_TASK,
  data
});

export const SubmitTaskSuccess = () => ({
  type: SUBMIT_TASK_SUCCESS,
});

export const SubmitTaskFailure = (error: any) => ({
  type: SUBMIT_TASK_FAILURE,
  payload: error,
})


export const createConversations = (id: string, data: any) => ({
  type: CREATE_CONVERSATIONS,
  id,
  data
});
export const sendMessage = (data: any) => ({
  type: SENT_MESSAGE,
  data
});

export const createConversationsSuccess = () => ({
  type: CREATE_CONVERSATIONS_SUCCESS,
});

export const createConversationsFailure = (error: any) => ({
  type: CREATE_CONVERSATIONS_FAILURE,
  payload: error,
})
export const getRedFlag = (params: any) => ({
  type: GET_RED_FLAG_LIST,
  params: params,
})

export const clearRedflagData = () => {
 return {
  type : CLEAR_REDFLAG_DATA
 }
}

export const deleteAdditonalParent = (id: string, userId: string) => ({
  type: DELETE_ADDITIONAL_PARENT,
  id,
  userId
});
export const removeChat = () => ({
  type: RESPONSE_CHATMSG_CLEAR,
  payload: ''
})

