export const Login_Admin = "Login_Admin"
export const Set_Login_Value = "Set_Login_Value"
export const Get_Login_Response = "Get_Login_Response"
export const Logout_Admin = "Logout_Admin"
export const Get_Logout_Response = "Get_Logout_Response"
export const Change_Password = "Change_Password"
export const Get_Profile = "Get_Profile"
export const Get_Profile_Response = " Get_Profile_Response"
export const Get_logoType = " Get_logoType"
export const Reset_Password = "Reset_Password"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const SEND_OTP = "SEND_OTP"
export const REGISTER_PASSWORD = "REGISTER_PASSWORD"

export const VERIFY_LINK = "VERIFY_LINK"
export const VERIFY_LINK_RESPONSE = "VERIFY_LINK_RESPONSE"

export const UPDATE_USER_ACCOUNT_DETAIL = "UPDATE_USER_ACCOUNT_DETAIL"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"

export const VERIFY_DOMAIN_RESPONSE = "VERIFY_DOMAIN_RESPONSE"
export const VERIFY_DOMAIN = "VERIFY_DOMAIN"